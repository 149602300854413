import HTTP from '@/api/http'

export default {
  async getAll () {
    const response = await HTTP.get(`/groups`)
    return response
  },
  async get (id) {
    const response = await HTTP.get(`/groups/${id}`)
    return response
  },
  async create (data) {
    const response = await HTTP.post(`/groups`, { group: data })
    return response
  },
  async update (id, data) {
    const response = await HTTP.put(`/groups/${id}`, { group: data })
    return response
  },
  async delete (id) {
    const response = await HTTP.delete(`/groups/${id}`)
    return response
  },
  async addMember (id, userId) {
    const response = await HTTP.put(`/groups/${id}/add_member`, {
      user_id: userId
    })
    return response
  },
  async removeMember (id, userId) {
    const response = await HTTP.put(`/groups/${id}/remove_member`, {
      user_id: userId
    })
    return response
  },
  async addPermission (id, resourceId, action) {
    console.log("{resource_id: resourceId, action: action}", {resource_id: resourceId, action: action})
    const response = await HTTP.put(`/groups/${id}/add_permission`, {resource_id: resourceId, permission_action: action})
    return response
  },
  async removePermission (id, permissionId) {
    const response = await HTTP.put(`/groups/${id}/remove_permission`, {
      permission_id: permissionId
    })
    return response
  }
}
