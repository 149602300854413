<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <card :title="account.name">
        <div class="form-group">
          <label>Reference</label>
          <div class="value">{{account.reference_prefix ? account.reference_prefix : "Not Set"}}</div>
        </div>
        <div class="form-group">
          <label>Consent Required Interval</label>
          <div class="value">{{consentRequiredInterval ? consentRequiredInterval : "Not Set"}}</div>
        </div>
        <div class="form-group">
          <label>Consent Timelimit</label>
          <div class="value">{{consentTimeLimit ? consentTimeLimit : "Not Set"}}</div>
        </div>
        <div class="form-group">
          <label>Primary Colour</label>
          <div class="value">
            <color-swatch :color="account.primary_color" />
          </div>
        </div>
        <div class="form-group">
          <label>Secondary Colour</label>
          <div class="value">
            <color-swatch :color="account.secondary_color" />
          </div>
        </div>
        <div class="form-group">
          <label>Small Logo</label>
          <div class="value logo small">
            <img v-if="account.small_logo_url" :src="account.small_logo_url" alt="No Logo Selected" class="logo-preview small" />
            <span v-else>No Image Selected</span>
          </div>
        </div>
        <div class="form-group">
          <label>Large Logo</label>
          <div class="value logo large">
            <img v-if="account.large_logo_url" :src="account.large_logo_url" alt="No Logo Selected" class="logo-preview large" />
            <span v-else>No Image Selected</span>
          </div>
        </div>
      </card>
      <div>
        <authorized-link class="btn btn-primary" :to="{name: 'edit-account', params: {id: account.id}}">Edit</authorized-link>
        <delete-button v-can:delete="'account'" :confirmation="account.name" @delete="deleteRecord">Delete</delete-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import authorization from "@/lib/authorization";
import colorSwatch from "@/components/color-swatch";
export default {
  name: "account-show",
  components: {
    colorSwatch,
  },
  computed: {
    ...mapState({
      account: (state) => state.account.current,
      accountId: (state) => state.session.account.id,
      loading: (state) => state.account.loading.current,
    }),
    consentTimeLimit() {
      return this.account.consent_timelimit / 60 / 60 / 24;
    },
    consentRequiredInterval() {
      return this.account.consent_required_interval / 60 / 60 / 24;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("account/get", this.$route.params.id);
    } else {
      this.$store.dispatch("account/get", this.accountId);
    }
  },
  methods: {
    deleteRecord() {
      if (this.$route.params.id) {
        this.$store.dispatch("account/delete", this.$route.params.id).then(() => {
          this.$route.push({name: "accounts"})
        });
      } else {
        this.$store.dispatch("account/delete", this.accountId).then(() => {
          this.$route.push({name: "accounts"})
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.value {
  margin-left: 20px;

  &.logo {
    display: table;
    padding: 20px;

    &.small {
      background: #324148;
      img {
        max-width: 250px;
        max-height: 40px;
      }
    }
    &.large {
      background: #000000;
      img {
    max-width: 300px;
    max-height: 60px;
      }
    }
  }
}


</style>