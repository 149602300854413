<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <th>Format</th>
          <td>{{asset.format}}</td>
        </tr>
        <tr>
          <th>Payload</th>
          <td>{{asset.payload}}</td>
        </tr>
        <tr>
          <th>Procedure</th>
          <td>{{asset.procedure_id}}</td>
        </tr>
        <tr>
          <th>Created At</th>
          <td>{{asset.created_at}}</td>
        </tr>
        <tr>
          <th>Updated At</th>
          <td>{{asset.updated_at}}</td>
        </tr>
      </table>
      <div>
        <authorized-link class="btn btn-primary" :to="{name: 'edit-asset', params: {id: asset.id}}">Edit</authorized-link>
        <delete-button @delete="deleteRecord">Delete</delete-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "asset-show",
  computed: {
    ...mapState({
      asset: state => state.asset.current,
      loading: state => state.asset.loading.current
    })
  },
  created() {
    this.$store.dispatch("asset/get", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch("asset/delete", this.asset.id);
    }
  }
};
</script>

<style lang='scss' scoped>
</style>