<template>
  <router-link v-if="isValid" :to="to" event @click.native.prevent="navigate">
    <slot />
  </router-link>
</template>

<script>
import { mapState } from "vuex";
import router from "@/router";
import authorization from "@/lib/authorization";
export default {
  name: "authorized-link",
  props: {
    to: {
      type: Object
    },
    showText: {
      type: Boolean,
      default: false
    },
    confirmDelete: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.session.user || {},
      permissions: state => state.session.permissions || []
    }),
    route() {
      return router.matcher.match(this.to);
    },
    isValid() {
      if (this.route.meta.auth) {
        if (this.route.meta.auth.public) {
          return true;
        } else {
          if (this.currentUser) {
            return authorization.isValid(
              this.route.meta.auth.resource,
              this.route.meta.auth.action
            );
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    navigate() {
      if (this.route.meta.auth.action == "delete" && this.confirmDelete) {
        const message = {
          title: "Delete Confirmation",
          body: `Are you sure you wish to delete this ${this.route.meta.auth.resource}?`
        }
        const options = {
          okText: "Delete",
          cancelText: "Cancel",
          customClass: "delete-confirmation",
          title: "Delete Confirmation"
        }
        this.$dialog
          .confirm(message, options)
          .then(function() {
            console.log("navigating to", this.to)
            router.push(this.to, () => {})
          }.bind(this))
          .catch(function(e) {
            console.error(e)
          });
      } else {
        router.push(this.to)
      }
    }
  }
};
</script>

<style lang="scss">
.delete-confirmation {
  .dg-btn--cancel {
    background-color: transparent;
    color: black;
    border-color: #ccc;
  }

  .dg-btn--ok  {
    background-color: #a50707;
    color: white;
    border-color: transparent
  }
  
}
</style>