<template>
  <div class="card">
    <div class="media card-body flex m-0">
      <div class="mr-md-3 mb-2 mb-md-0 avatar">
        <v-gravatar :email="patient.email" />
      </div>

      <div class="media-body">
        <h3 class="media-title font-weight-semibold">{{patient.title}} {{patient.first_name}} {{patient.last_name}}</h3>
        <ul class="list-group list-group-flush border-top">
          <li class="list-group-item" v-if="patient.date_of_birth !== undefined">
            <span class="font-weight-semibold">Date of Birth:</span>
            <div class="ml-auto">{{patient.date_of_birth | formatDate}}</div>
          </li>
          <li class="list-group-item" v-if="patient.mobile !== undefined">
            <span class="font-weight-semibold">Mobile:</span>
            <div class="ml-auto">{{patient.mobile}}</div>
          </li>
          <li class="list-group-item" v-if="patient.chi_number !== undefined">
            <span class="font-weight-semibold">Patient Number:</span>
            <div class="ml-auto">{{patient.chi_number}}</div>
          </li>
          <li class="list-group-item" v-if="patient.email !== undefined">
            <span class="font-weight-semibold">Email:</span>
            <div class="ml-auto">
              <a :href="`tel:${patient.email}`">{{patient.email}}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-footer">
      <ul class="list-inline list-inline-dotted mb-0">
        <li class="list-inline-item">
          <authorized-link :to="{name: 'patient', params: {id: patient.id}}">Patient profile</authorized-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "patient",
  props: {
    patient: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
  .avatar {
    min-width: 75px;
    img {
    border-radius: 40px;
    }
    i {
      font-size: 64px;
    }
  }

  .list-group-item {
    padding-left: 5px;
  }
}

@media screen and (max-width: 1110px) {
  .avatar {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .avatar {
    display: block;
  }
}
</style>