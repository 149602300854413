<template>
  <div class="navbar navbar-expand-md navbar-dark">
    <div class="navbar-brand">
      <authorized-link :to="{name: 'dashboard'}" class="d-inline-block">
        <img v-if="customLogoPresent" :src="account.small_logo_url" :alt="`Logo for ${account.name}`" class="logo" />
        <img v-else src="@/assets/images/logo.png" alt="Logo for Consentwise" class="logo" />
      </authorized-link>
    </div>

    <div class="d-md-none">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
        <i class="icon-tree5"></i>
      </button>
      <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
        <i class="icon-paragraph-justify3"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbar-mobile">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a href="#" class="navbar-nav-link d-none d-md-block" @click="toggleSidebar">
            <i class="icon-paragraph-justify3"></i>
          </a>
        </li>

        <!-- <git-updates-dropdown /> -->
      </ul>

      <!-- <badge title="Online" status="bg-success" /> -->
      <div class="ml-md-3 mr-md-auto"></div>

      <ul class="navbar-nav">
        <li class="nav-item">
          <authorized-link title="Create a new consent request" :to="{name: 'new-consent'}" class="navbar-nav-link" data-toggle="dropdown">
            <i class="icon-add"></i>
          </authorized-link>
        </li>

        <messages-dropdown />
        <user-dropdown />
      </ul>
    </div>
  </div>
  <!-- /main navbar -->
</template>

<script>
import gitUpdatesDropdown from "@/components/shared/nav-bar/git-updates-dropdown";

import messagesDropdown from "@/components/shared/nav-bar/messages-dropdown";
import userDropdown from "@/components/shared/nav-bar/user-dropdown";

import { mapState } from "vuex";
export default {
  name: "navigation",
  components: {
    gitUpdatesDropdown,
    messagesDropdown,
    userDropdown
  },
  computed: {
    ...mapState({
      sessionValid: state => state.session.valid,
      sidebarOpen: state => state.sidebar.open,
      account: state => state.session.account
    }),
    customLogoPresent() {
      return this.account.small_logo_url !== "" && this.account.small_logo_url !== null && this.account.small_logo_url !== undefined;
    }
  },
  methods: {
    toggleSidebar() {
      if (this.sidebarOpen) {
        this.$store.dispatch("sidebar/close");
      } else {
        this.$store.dispatch("sidebar/open");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 60px;

  .navbar-brand {
    padding: 0;
    position: relative;
    a {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      .logo {
        height: 40px;
      }
    }
  }
}
</style>