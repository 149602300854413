<template>
  <card title="Audit Summary" class="audit">
    <spinner v-if="loading" />
    <table v-else class="table">
      <thead><th>Page</th><th>Duration</th></thead>
      <tbody>
        <tr v-for="page in pages" :key="page"><td>{{page | titleize}}</td><td>{{auditSummary[page] | secondsToHuman  }}</td></tr>
        <tr class="total-row"><td>Total Time  </td><td>{{total | secondsToHuman}}</td></tr>
      </tbody>
    </table>
  </card>
</template>

<script>
import {mapState} from 'vuex'
import auditCard from "@/components/audit-card";
export default {
  name: "audit-summary",
  components: {
    auditCard
  },
  props: {
    informationPack: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    ...mapState({
      auditSummary: (state) => state.informationpack.auditSummary,
      loading: (state) => state.informationpack.loading.auditSummary
    }),
    pages() {
      return Object.keys(this.auditSummary).filter(function(p) { return p !== "total" })
    },
    total() {
      return this.pages.map((page) => { return this.auditSummary[page] }).reduce((a, b) => a + b, 0)
    }
  },
  created() {
    this.$store.dispatch("informationpack/getAuditSummary", this.informationPack.id)
  }
};
</script>

<style lang="scss" scoped>
.table {
  .total-row {
    font-weight: bold;
  }
}
</style>