import HTTP from '@/api/http'

export default {
  async getAll() {
    const response = await HTTP.get(`/groupusers`)
    return response
  },
  async get(id) {
    const response = await HTTP.get(`/groupusers/${id}`)
    return response
  },
  async create(data) {
    const response = await HTTP.post(`/groupusers`, {groupuser: data})
    return response
  },
  async update(id, data) {
    const response = await HTTP.put(`/groupusers/${id}`, {groupuser: data})
    return response
  },
  async delete(id) {
    const response = await HTTP.delete(`/groupusers/${id}`)
    return response
  }
}