<template>
  <div>
    <h1>Account</h1>
    <spinner v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <th>Name</th>
          <th>Key</th>
          <th></th>
        </tr>
        <tr v-for="account in accounts" :key="account.id">
          <td>{{account.name}}</td>
          <td>{{account.key}}</td>
          <td>
          <authorized-link class="btn btn-primary" :to="{name: 'account', params: {id: account.id}}">Show</authorized-link>
          <authorized-link class="btn btn-secondary" :to="{name: 'edit-account', params: {id: account.id}}">Edit</authorized-link>
          </td>
        </tr>
      </table>
      <authorized-link class="btn btn-primary" :to="{name: 'new-account'}">Create a new Account</authorized-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "account-edit",
  computed: {
    ...mapState({
      accounts: state => state.account.all,
      loading: state => state.account.loading.all
    })
  },
  created() {
    this.$store.dispatch("account/getAll");
  }
};
</script>

<style lang='scss' scoped>
</style>