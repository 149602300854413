<template>
  <table class="table table-striped">
    <tr><th>Patient Number</th><th>Patient Name</th><th>Leaflets Included</th><th>Appointment Status</th></tr>
    <information-pack-row v-for="informationPack in informationPacks" :informationPack="informationPack" :key="informationPack.id" />
  </table>
</template>

<script>
import informationPackRow from "@/components/information-pack/row"
export default {
  name: "information-pack-table",
  props:{
    informationPacks: {
      type: Array,
      default: function() {return []}
    }
  },
  components: {
    informationPackRow
  }
}
</script>