import HTTP from '@/api/http'

export default {
  async create (email, password) {
    const response = await HTTP.post(`/session/create`, {
      session: { email: email, password: password }
    })
    return response
  },
  async refresh (token) {
    const response = await HTTP.post(`/session/refresh`)
    return response
  },
  async acceptInvitation (token, password) {
    const response = await HTTP.put(`/users/invitation`, {user: {invitation_token: token, password: password, password_confirmation: password}})
    return response
  },  
  async ping () {
    const response = await HTTP.get(`/session/ping`)
    return response
  }
}
