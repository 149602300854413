<template>
  <div>
    <h1>Group</h1>
    <spinner v-if="loading" />
    <div v-else>
      <div class="row">
        <div class="col-xl-4" v-for="group in groups" :key="group.id">
          <div class="card">
            <div class="card-header">
              <h4>
                <i :class="settings.group.icon" />
                {{group.name}}
              </h4>
            </div>
            <div class="card-body">
              <p v-if="group.members.length == 0">There are no members in this group</p>
              <p v-else>There are {{group.members.length}} members in this group</p>
            </div>
            <div class="card-footer bg-transparent d-flex justify-content-between border-top-0 pt-0">
              <div></div>
              <div>
                <authorized-link class="btn btn-primary" :to="{name: 'edit-group', params: {id: group.id}}">Edit</authorized-link>
                <delete-button @delete="deleteRecord(group.id)">Delete</delete-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <authorized-link :to="{name: 'new-group'}">Create a new Group</authorized-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "group-edit",
  computed: {
    ...mapState({
      settings: state => state.application.settings,
      groups: state => state.group.all,
      loading: state => state.group.loading.all
    })
  },
  methods: {
    deleteRecord(id) {
      this.$store.dispatch("group/delete", id).then(
        function() {
          this.$store.dispatch("group/getAll");
        }.bind(this)
      );
    }
  },
  created() {
    this.$store.dispatch("group/getAll");
  }
};
</script>

<style lang='scss' scoped>
h4 {
  i {
    margin-right: 10px;
  }
}
.users {
    padding: 20px;

    .user {
      margin: 10px;
    }
}
</style>