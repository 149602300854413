<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <consent :consent="consent" />
      <div>
        <!-- <a class="btn btn-primary" @click="sendToPatient">Send to Patient</a> -->
        <authorized-link class="btn btn-primary" :to="{name: 'send-consent-to-patient', params: {id: consent.id}}">Send to Patient</authorized-link>
        <authorized-link class="btn btn-secondary" :to="{name: 'edit-consent', params: {id: consent.id}}">Edit</authorized-link>
        <delete-button @delete="deleteRecord">Delete</delete-button>
        <authorized-link class="btn btn-secondary" :to="{name: 'print-consent', params: {id: consent.id}}">Print</authorized-link>
      </div>
    </div>
  </div>
</template>

<script>
import consent from '@/components/consent/consent'
import { mapState } from "vuex";
export default {
  name: "consent-show",
  components: {
    consent
  },
  computed: {
    ...mapState({
      consent: state => state.consent.current,
      loading: state => state.consent.loading.current
    })
  },
  created() {
    this.$store.dispatch("consent/get", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch("consent/delete", this.consent.id).then(function() {
        this.$notify({group: 'success', type: 'success', title: 'Consent Deleted', text: 'The consent has been successfully deleted'})
        this.$router.push({name: 'consents'})
      }.bind(this))
    }
  }
};
</script>

<style lang='scss' scoped>
.btn-primary {
  color: white;
}
</style>