<template>
  <div class="panel">
    <div class="title">{{title}}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "panel",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.panel {
  margin-bottom: 23px;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  min-height: 60px;
  position: relative;

  .title {
    position: absolute;
    left: 20px;
    top: -12px;
    background: #f5f5f5;
    padding: 0px 10px;
    text-transform: capitalize;
  }
}
</style>