import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  total: 0,
  loading: {
    current: false,
    all: false
  }
}

const actions = {
  async getPage({
    commit, rootState
  }, options) {
    commit('startLoading', 'all')
    const result = await api.informationleaflet.getPage(rootState.session.account.id, options)
    const responseData = result.data
    commit('storeInformationleaflets', responseData.items)
    commit('storeTotal', responseData.count)
    commit('endLoading', 'all')
    return responseData
  },  async getAllTitles({
    commit, rootState
  }) {
    commit('startLoading', 'all')
    const result = await api.informationleaflet.getAllTitles(rootState.session.account.id)
    const responseData = result.data
    commit('storeInformationleaflets', responseData.items)
    commit('storeTotal', responseData.count)
    commit('endLoading', 'all')
    return responseData
  },
  async get({
    commit, rootState
  }, id) {
    commit('startLoading', 'current')
    const result = await api.informationleaflet.get(rootState.session.account.id, id)
    const responseData = result.data
    commit('storeInformationleaflet', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async create({commit, rootState}, data) {
    const result = await api.informationleaflet.create(rootState.session.account.id, data)
    const responseData = result.data
    commit('storeInformationleaflet', responseData)
    return responseData
  },
  async update({commit, rootState}, {id, data}) {
    commit('startLoading', 'current')
    const result = await api.informationleaflet.update(rootState.session.account.id, id, data)
    const responseData = result.data
    commit('storeInformationleaflet', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete({commit, rootState}, id) {
    const result = await api.informationleaflet.delete(rootState.session.account.id, id)
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  clearCurrent({commit}) {
    commit("clearCurrent")
  },
  async duplicate({commit, rootState}, id) {
    commit('startLoading', 'current')
    const result = await api.informationleaflet.duplicate(rootState.session.account.id, id)
    const responseData = result.data
    commit('storeInformationleaflet', responseData)
    commit('endLoading', 'current')
    return responseData
  }
}
const mutations = {
  storeInformationleaflet(state, informationleaflet) {
    state.current = informationleaflet
  },
  storeInformationleaflets(state, data) {
    state.all = data
  },
  clearCurrent(state) {
    state.current = {}
  },
  startLoading(state, key) {
    state.loading[key] = true
  },
  endLoading(state, key) {
    state.loading[key] = false
  },
  storeTotal(state, count) {
    state.total = count
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
