<template>
  <div class="badge m-1" :class="{'bg-success text-white': selected}" @click="clicked">
    <slot />
  </div>
</template>

<script>
export default {
  name: "clickable-badge",
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    }
  }
};
</script>

<style lang="scss" scoped>
.badge {
  cursor: pointer;
}
</style>