import { render, staticRenderFns } from "./inbox-message.vue?vue&type=template&id=5301fb30&scoped=true&"
import script from "./inbox-message.vue?vue&type=script&lang=js&"
export * from "./inbox-message.vue?vue&type=script&lang=js&"
import style0 from "./inbox-message.vue?vue&type=style&index=0&id=5301fb30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5301fb30",
  null
  
)

export default component.exports