<template>
  <div class="content d-flex justify-content-center align-items-center">
    <!-- Login form -->
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <img class="logo" src="@/assets/images/logo.png" />
          <h5 class="mb-0">Welcome to your new ConsentWise account</h5>
          <span class="d-block text-muted">Create a new password below</span>
        </div>

        <div class="form-group form-group-feedback form-group-feedback-left">
          <input type="password" class="form-control" placeholder="Password" data-kwimpalastatus="alive" data-kwimpalaid="1582284876512-2" v-model="password" v-on:keypress="clearError" ref="password" v-on:keyup.enter="submit" />
          <div class="form-control-feedback">
            <i class="icon-lock2 text-muted"></i>
          </div>
        </div>
        <div class="form-group form-group-feedback form-group-feedback-left">
          <input type="password" class="form-control" placeholder="Confirmation" data-kwimpalastatus="alive" data-kwimpalaid="1582284876512-2" v-model="password_confirmation" v-on:keypress="clearError" ref="password_confirmation" v-on:keyup.enter="submit" />
          <div class="form-control-feedback">
            <i class="icon-lock2 text-muted"></i>
          </div>
        </div>

        <div class="form-group">
          <button :disabled="!isReady" @click="submit" class="btn btn-primary btn-block">
            Create User Account
            <i class="icon-circle-right2 ml-2"></i>
          </button>
        </div>
        <div class="error-container" v-if="error!==''">
          There has been a problem siging in:
          <div class="error">{{error}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "sign-in",
  data() {
    return {
      password: "",
      password_confirmation: "",
      error: ""
    };
  },
  computed: {
    isReady() {
      return (
        this.password.length > 3 && this.password == this.password_confirmation
      );
    }
  },
  methods: {
    submit() {
      const password = this.password;
      const password_confirmation = this.password_confirmation;
      const token = this.$route.params.token;
      this.$store
        .dispatch("session/acceptInvitation", { token, password })
        .then(
          function() {
            this.$router.push({ name: "dashboard" });
          }.bind(this)
        );
    },
    clearError() {
      if (this.error != "") {
        this.$store.dispatch("session/clearError");
      }
    },
    focusPassword() {
      this.$refs.password.focus();
    }
  },
  mounted() {
    this.$store.dispatch("session/clearError");
  }
};
</script>

<style lang="scss" scoped>

.card {
  width: 300px;
}

img.logo {
  margin: 30px 0px;
  max-width: 80%;
}

.error-container {
  text-align: center;
  margin: 10px 0;
  .error {
    color: red;
    text-transform: uppercase;
  }
}
</style>