import api from '@/api'
import router from '@/router'

const state = {
  open: false
}

const actions = {
  open ({ commit }) {
    commit('open')
  },
  close ({ commit }) {
    commit('close')
  }
}
const mutations = {
  open (state) {
    state.open = true
  },
  close (state) {
    state.open = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
