<template>
  <div class="permission" :class="{permitted: isPermitted, 'not-permitted': !isPermitted}" @click="togglePermission">
    <font-awesome-icon v-if="isPermitted" :icon="['fas', 'check-circle']"></font-awesome-icon>
    <font-awesome-icon v-else :icon="['fas', 'times-circle']"></font-awesome-icon>
  </div>
</template>

<script>
export default {
  name: "permission",
  props: {
    action: {
      type: String
    },
    group: {
      type: Object
    },
    resource: {
      type: Object
    }
  },
  computed: {
    isPermitted() {
      return this.permission !== undefined;
    },
    permission() {
      return this.group.permissions.find(
        function(perm) {
          return (
            perm.resource.id == this.resource.id && perm.action == this.action
          );
        }.bind(this)
      );
    }
  },
  methods: {
    togglePermission() {
      const id = this.group.id;
      if (this.isPermitted) {
        const permissionId = this.permission.id;
        this.$store.dispatch("group/removePermission", { id, permissionId });
      } else {
        const resourceId = this.resource.id;
        const action = this.action;
        this.$store.dispatch("group/addPermission", { id, resourceId, action });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.permission {
  width: 80px;
  border-radius: 3px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
  cursor: pointer;
  color: white;

  &.permitted {
    background-color: #025f0d;
  }

  &.not-permitted {
    background-color: #5f2c2c;
  }
}
</style>