import Vue from 'vue'
import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  loading: {
    current: false,
    all: false
  }
}

const actions = {
  async getAll ({ commit }, procedureId) {
    commit('startLoading', 'all')
    const result = await api.fragment.getAll(procedureId)
    const responseData = result.data
    commit('storeFragments', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get ({ commit }, { procedureId, id }) {
    commit('startLoading', 'current')
    const result = await api.fragment.get(procedureId, id)
    const responseData = result.data
    commit('storeFragment', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async create ({ commit }, { procedureId, data }) {
    const result = await api.fragment.create(procedureId, data)
    const responseData = result.data
    commit('storeFragment', responseData)
    return responseData
  },
  async update ({ commit }, { procedureId, id, data }) {
    commit('startLoading', 'current')
    const result = await api.fragment.update(procedureId, id, data)
    const responseData = result.data
    commit('storeFragment', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete ({ commit }, { procedureId, id }) {
    const result = await api.fragment.delete(procedureId, id)
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  },
  updateFragmentInAll ({ commit }, fragment) {
    commit('updateFragmentInAll', fragment)
  },
  setAsDefault ({ commit }) {
    commit('setAsDefault')
  },
  async revert ({ commit }, { procedureId, id }) {
    const result = await api.fragment.revert(procedureId, id)
    const responseData = result.data
    commit('updateFragmentInAll', responseData)
    return responseData
  }
}
const mutations = {
  storeFragment (state, fragment) {
    state.current = fragment
  },
  storeFragments (state, data) {
    Vue.set(state, 'all', data)
  },
  clearCurrent (state) {
    state.current = {}
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  },
  updateFragmentInAll (state, fragment) {
    Vue.set(
      state,
      'all',
      state.all.map(function (f) {
        if (f.id == fragment.id) {
          return fragment
        } else {
          return f
        }
      })
    )
  },
  setAsDefault (state) {
    state.all = [
      {document_name:"description",section:"",content:""},
      {document_name:"options",section:"",content:""},
      {document_name:"risks",section:"",content:""}
    ]
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
