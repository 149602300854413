<template>
  <div>
    <h1>Request for Appointments</h1>
    <spinner v-if="loading" />
    <div v-else>
      <search-term v-model="searchText" />
      <table class="table">
        <tr>
          <th>Patient</th>
          <th>Patient Number</th>
          <th>Medic</th>
          <th>Read And Understood</th>
          <th>Appointment Request Status</th>
          <th>Created</th>
        </tr>
        <information-pack-scheduling-row v-for="informationpack in informationpacks" :key="informationpack.id" :informationpack="informationpack" />
      </table>
    </div>
  </div>
</template> 

<script>
import { mapState } from "vuex";
import informationPackSchedulingRow from '@/components/information-pack/information-pack-scheduling-row'
export default {
  name: "informationpack-edit",
  data() {
    return {
      editingStatus: false,
      searchText: ""
    };
  },
  components: {
    informationPackSchedulingRow
  },
  computed: {
    ...mapState({
      informationpacks: (state) => state.informationpack.all,
      loading: (state) => state.informationpack.loading.all,
    }),
    filteredInformationPacks() {
      console.log("this.searchText", this.searchText)
      if (this.searchText.length > 0) {
        return this.informationpacks.filter(p => p.patient.first_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || p.patient.last_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 )
      } else {
        return this.informationpacks
      }
    }

  },
  created() {
    this.$store.dispatch("informationpack/getAll", {
      filter: "active_appointment_requests",
    });
  }
};
</script>

