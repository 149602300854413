import HTTP from '@/api/http'

export default {
  async getAll () {
    const response = await HTTP.get(`/procedures`)
    return response
  },
  async getPage (options) {
    const response = await HTTP.get(`/procedures`, { params: options })
    return response
  },
  async getTemplates () {
    const response = await HTTP.get(`/procedures`, {
      params: { filter: 'templates' }
    })
    return response
  },
  async get (id) {
    const response = await HTTP.get(`/procedures/${id}`)
    return response
  },
  async categories () {
    const response = await HTTP.get('/categories')
    return response
  },
  async titles () {
    const response = await HTTP.get('/procedure-titles')
    return response
  },
  async create (data) {
    const response = await HTTP.post(`/procedures`, { procedure: data })
    return response
  },
  async update (id, data) {
    const response = await HTTP.put(`/procedures/${id}`, { procedure: data })
    return response
  },
  async delete (id) {
    const response = await HTTP.delete(`/procedures/${id}`)
    return response
  }
}
