<template>
  <tr>
    <td>{{informationpack.patient.first_name}} {{informationpack.patient.last_name }}</td>
    <td>{{informationpack.patient.chi_number}}</td>
    <td>{{informationpack.user.first_name}} {{informationpack.user.last_name}}</td>
    <td>{{informationpack.read_and_understood ? "Yes" : ""}}</td>
    <td>
      <div class="clickable position-relative">
        <span @click="editingStatus=!editingStatus" class="badge clickable" :class="statusClass">{{informationpack.appointment_request_status | uppercase}}</span>
        <div class="edit-status-popup" v-if="editingStatus">
          <div class="status-selector" @click="updateStatus(informationpack, 'new')" v-if="informationpack.appointment_request_status !== 'new'">
            <span class="badge badge-danger">NEW</span>
          </div>
          <div class="status-selector" @click="updateStatus(informationpack, 'patient_contacted')" v-if="informationpack.appointment_request_status !== 'patient_contacted'">
            <span class="badge badge-warning">PATIENT CONTACTED</span>
          </div>
          <div class="status-selector" @click="updateStatus(informationpack, 'appointment_scheduled')" v-if="informationpack.appointment_request_status !== 'appointment_scheduled'">
            <span class="badge badge-success">APPOINTMENT SCHEDULED</span>
          </div>
          <div class="status-selector" @click="updateStatus(informationpack, 'closed')" v-if="informationpack.appointment_request_status !== 'closed'">
            <span class="badge badge-dark">CLOSED</span>
          </div>
        </div>
      </div>
    </td>
    <td>{{informationpack.created_at | formatDate}}</td>
  </tr>
</template>

<script>
export default {
  name: "information-pack-scheduling-row",
  props: {
    informationpack: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editingStatus: false,
    };
  },
  computed: {
    statusClass() {
      switch (this.informationpack.appointment_request_status) {
        case "new":
          return "badge-danger";
        case "patient_contacted":
          return "badge-warning";
        case "appointment_scheduled":
          return "badge-success";
        case "closed":
          return "badge-dark";
        default:
          return "";
      }
    },
  },
  methods: {
    updateStatus(informationpack, status) {
      informationpack.appointment_request_status = status;
      const id = informationpack.id;
      const data = informationpack;
      this.$store
        .dispatch("informationpack/update", { id, data })
        .then((updatedPack) => {
          this.editingStatus = false;
          // this.$store
          //   .dispatch("informationpack/getAll", {
          //     filter: "active_appointment_requests",
          //   })
          //   .then(() => {
          //   });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}

.edit-status-popup {
  position: absolute;
  // margin: 5px;
  // padding: 8px;
  background: #f5f5f5;
  // border: 1px solid #cccc;
  // border-radius: 4px;
  top: 21px;
  z-index: 10;

  .status-selector {
    cursor: pointer;
  }
}
</style>