<template>
  <div class="card">
    <div class="media card-body flex m-0">
      <div class="mr-3 mb-2 mb-md-0 avatar">
        <a href="#">
          <img :src="user.avatar_url" class="rounded-circle" width="64" height="64" alt />
        </a>
      </div>

      <div class="media-body">
        <h3 class="media-title font-weight-semibold border-bottom mb-2">{{user.title}} {{user.first_name}} {{user.last_name}}</h3>
        <v-clamp autoresize :max-lines="3" :expanded="bioExpanded">{{ user.bio }}</v-clamp>
        <a class="link" v-if="!bioExpanded" @click="bioExpanded=true">Expand</a>

        <img :src="user.signature_url" class="signature" />
      </div>
    </div>
    <div class="card-footer">
      <ul class="list-inline list-inline-dotted mb-0">
        <li class="list-inline-item" v-if="$route.name !== 'user'">
          <authorized-link :to="{name: 'user', params: {id: user.id}}">Medic profile</authorized-link>
        </li>
        <li class="list-inline-item" v-if="user.gmc_number && user.user_type == 'medic'">
          <a :href="gmcUrl" target="_BLANK">GMC Registration</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  name: "medic",
  props: {
    title: {
      type: String,
      default: "Medic",
    },
    user: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      bioExpanded: false,
    };
  },
  components: {
    VClamp,
  },
  computed: {
    gmcUrl() {
      return `https://www.gmc-uk.org/doctors/${this.user.gmc_number}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
}
.signature {
  display: block;
  max-width: 100%;
  width: 300px;
  margin-top: 30px;
}
.card {
  width: 100%;
  .avatar {
    i {
      font-size: 64px;
    }
  }

  .list-group-item {
    padding-left: 5px;
  }
}

@media screen and (max-width: 1110px) {
  .avatar {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .avatar {
    display: block;
  }
}
</style>