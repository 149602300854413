import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  loading: {
    current: false,
    all: false
  },
  error: '',
  passwordRequestSent: false,
  passwordResetSuccessful: undefined,
  passwordResetError: {}
}

const actions = {
  async getAll ({ commit }, filter) {
    commit('startLoading', 'all')
    const result = await api.user.getAll(filter)
    const responseData = result.data
    commit('storeUsers', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get ({ commit }, id) {
    commit('startLoading', 'current')
    const result = await api.user.get(id)
    const responseData = result.data
    commit('storeUser', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async create ({ commit }, data) {
    const result = await api.user.create(data)
    const responseData = result.data
    commit('storeUser', responseData)
    return responseData
  },
  async update ({ commit }, { id, data }) {
    commit('startLoading', 'current')
    delete data.password
    delete data.password_confirmation
    const result = await api.user.update(id, data)
    if (result.status == 200) {
      const responseData = result.data
      commit('storeUser', responseData)
      commit('setError', {})
      return responseData
    } else {
      if (result.response.status == 422) {
        console.log(result.response.data)
        commit('setError', result.response.data)
      }
      commit('endLoading', 'current')
    }
  },
  async updatePassword ({ commit }, { id, data }) {
    commit('startLoading', 'current')
    const result = await api.user.update(id, data)
    if (result.status == 200) {
      const responseData = result.data
      commit('storeUser', responseData)
      commit('setError', {})
      return responseData
    } else {
      if (result.response.status == 422) {
        console.log(result.response.data)
        commit('setError', result.response.data)
      }
      commit('endLoading', 'current')
    }
  },
  async delete ({ commit }, id) {
    const result = await api.user.delete(id)
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  storeUser ({ commit }, user) {
    commit('storeUser', user)
    return user
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  },
  async requestPasswordReset ({ commit }, email) {
    const result = await api.user.requestPasswordReset(email)
    const responseData = result.data
    commit('setPasswordRequestSent', true)
    return responseData
  },
  async resetPassword ({ commit }, { password, passwordConfirmation, token }) {
    const result = await api.user.resetPassword(
      password,
      passwordConfirmation,
      token
    )
    const responseData = result.data

    const responseCode = result.response
      ? result.response.status
      : result.status

    if (responseCode == 204) {
      commit('setPasswordResetSuccessful', true)
    } else {
      commit('setPasswordResetSuccessful', false)
      commit('setPasswordResetError', result.response.data)
    }

    return responseData
  }
}
const mutations = {
  storeUser (state, user) {
    state.current = user
  },
  storeUsers (state, data) {
    state.all = data
  },
  clearCurrent (state) {
    state.current = {}
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  },
  setPasswordRequestSent (state, val) {
    state.passwordRequestSent = val
  },
  setPasswordResetSuccessful (state, val) {
    state.passwordResetSuccessful = val
  },
  setPasswordResetError (state, data) {
    state.passwordResetError = data
  },
  setError (state, err) {
    state.error = err
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
