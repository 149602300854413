<template>
  <div class="content d-flex justify-content-center align-items-center">
    <!-- Login form -->
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-1">
          <img class="logo" src="@/assets/images/logo.png" />
        </div>

        <div v-if="passwordResetSuccessful" class="text-center">
          <h5 class="mb-2">Password Reset Complete!</h5>
          <p>Thanks, your password has been successfully changed. Please click below to to the sign in page.</p>
          <div class="text-center">
            <router-link :to="{name: 'sign-in'}">Sign In</router-link>
          </div>
        </div>
        <div v-else>
          <div class="text-center mb-3">
            <h5 class="mb-0">Reset Your Password</h5>
            <span class="d-block text-muted">Use the form below to reset your password</span>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input type="password" class="form-control password" placeholder="Password" data-kwimpalastatus="alive" data-kwimpalaid="1582284876512-2" v-model="password" v-on:keypress="clearError" ref="password" v-on:keyup.enter="submit" autocomplete="new-password" />
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted"></i>
            </div>
          </div>

          <div class="form-group form-group-feedback form-group-feedback-left">
            <input type="password" class="form-control password-confirmation" placeholder="Password Confirmation" data-kwimpalastatus="alive" data-kwimpalaid="1582284876512-2" v-model="passwordConfirmation" v-on:keypress="clearError" autocomplete="new-password" ref="password-confirmation" v-on:keyup.enter="submit" />
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted"></i>
            </div>
          </div>

          <div class="form-group">
            <a @click="submit" class="btn btn-primary btn-block sign-in-submit">
              Change Password
              <i class="icon-circle-right2 ml-2"></i>
            </a>
          </div>
          <div class="error-container" v-if="passwordResetSuccessful==false">
            There has been a problem resetting your password:
            <div class="error">{{errorText}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "reset-password",
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      token: ""
    };
  },
  computed: {
    ...mapState({
      error: state => state.session.error,
      valid: state => state.session.valid,
      passwordResetSuccessful: state => state.user.passwordResetSuccessful,
      passwordResetError: state => state.user.passwordResetError
    }),
    errorText() {
      if (this.passwordResetError.errors) {
        return Object.keys(this.passwordResetError.errors)
          .map(key =>
            this.passwordResetError.errors[key]
              .map(error => `${key} ${error}`)
              .join(", ")
          )
          .join(", ");
      } else {
        return "";
      }
    }
  },
  methods: {
    submit() {
      const password = this.password;
      const passwordConfirmation = this.passwordConfirmation;
      const token = this.token;
      this.$store.dispatch("user/resetPassword", {
        password,
        passwordConfirmation,
        token
      });
    },
    clearError() {
      if (this.error != "") {
        this.$store.dispatch("session/clearError");
      }
    },
    focusPassword() {
      this.$refs.password.focus();
    }
  },
  mounted() {
    this.token = this.$route.query.token;
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 300px;
}

img.logo {
  margin: 30px 0px;
  max-width: 80%;
}

.error-container {
  text-align: center;
  margin: 10px 0;
  .error {
    color: red;
    text-transform: uppercase;
  }
}
</style>