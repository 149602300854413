<template>
  <div class="card text-center">
    <div class="card-body">
      <i class="mi mi-2x border-3 rounded-round p-3 mb-3 mt-1" :class="statusIconStyle"></i>
      <h5 class="text-center">{{status}}</h5>
    </div>

    <div class="card-body border-top-0 pt-0">
      <div class="row justify-content-center">
        <div class="col-4">
          <div class="text-uppercase font-size-xs text-muted">Scheduled</div>
          <h5 class="font-weight-semibold line-height-1 mt-1 mb-0">{{consent.scheduled_at | formatDate}}</h5>
        </div>
        <div v-if="!consent.consented" class="col-4">
          <div class="text-uppercase font-size-xs text-muted">Required</div>
          <h5 class="font-weight-semibold line-height-1 mt-1 mb-0">{{consent.required_by | timeInWords}}</h5>
        </div>

        <div v-if="consent.consented" class="col-4">
          <div class="text-uppercase font-size-xs text-muted">Consented At</div>
          <h5 class="font-weight-semibold line-height-1 mt-1 mb-0">{{consent.consented_at | formatDate}}</h5>
        </div>
        <div v-if="consent.consented" class="col-4">
          <div class="text-uppercase font-size-xs text-muted">Expires</div>
          <h5 class="font-weight-semibold line-height-1 mt-1 mb-0">{{consent.invalid_after | timeInWords}}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "consent-status",
  props: {
    consent: {
      type: Object,
      required: true
    }
  },
  computed: {
    status() {
      if (this.consent.state == "not_sent") {
        return "Not Sent To Patient";
      } else if (this.consent.consented) {
        return "Consented";
      } else {
        return "Awaiting Consent";
      }
    },
    statusIconStyle() {
      if (this.consent.consented) {
        return ["mi-done", "text-success-400", "border-success-400"];
      } else {
        return ["mi-schedule", "text-warning-400", "border-warning-400"];
      }
    }
  }
};
</script>