<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <h3>Information Packs </h3>
    </div>
    <div class="card-body">
      <div v-if="informationPackPresent">There are no information packs requiring scheduling.</div>
      <information-pack-table v-else :informationPacks="information_packs" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import informationPackTable from '@/components/information-pack/table'
export default {
  name: "information-packs",
  components: {
    informationPackTable
  },  
  computed: {
    ...mapState({
      information_packs: state => state.informationpack.all
    }),
    informationPackPresent() {
      return this.information_packs && this.information_packs.length == 0
    }
  },
  mounted() {
    this.$store.dispatch("informationpack/getAll", {filter: "active_appointment_requests"})
  }
};
</script>