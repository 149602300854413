<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <th>Name</th>
          <td>{{group.name}}</td>
        </tr>
        <tr>
          <th>Created At</th>
          <td>{{group.created_at}}</td>
        </tr>
        <tr>
          <th>Updated At</th>
          <td>{{group.updated_at}}</td>
        </tr>
      </table>
      <div>
        <authorized-link class="btn btn-primary" :to="{name: 'edit-group', params: {id: group.id}}">Edit</authorized-link>
        <delete-button @delete="deleteRecord">Delete</delete-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "group-show",
  computed: {
    ...mapState({
      group: state => state.group.current,
      loading: state => state.group.loading.current
    })
  },
  created() {
    this.$store.dispatch("group/get", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch("group/delete", this.group.id);
    }
  }
};
</script>

<style lang='scss' scoped>
</style>