<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header header-elements-inline">
            <h6 class="card-title">Printable Consent</h6>
          </div>

          <div class="card-body">
            <pdf
              v-for="i in numPages"
              :key="i"
              ref="pdf"
              :src="pdfTask"
              :page="i"
            ></pdf>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header header-elements-inline">
            <h6 class="card-title">Actions</h6>
          </div>

          <div class="card-body">
            <a class="btn btn-primary" @click="print">Print</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import pdf from "vue-pdf";
import { mapState } from "vuex";

export default {
  name: "print-consent",
  components: {
    pdf
  },
  data() {
    return { loadedRatio: 0, page: 1, numPages: 0, rotate: 0, pdfTask: undefined };
  },
  computed: {
    ...mapState({
      token: state => state.session.token
    })
  },
  methods: {
    print() {
      this.$refs.pdf[0].print()
    },
    password: function(updatePassword, reason) {
      updatePassword(prompt('password is "test"'));
    },
    error: function(err) {
      console.log(err);
    }
  },
  mounted() {
    this.pdfTask = pdf.createLoadingTask({
      url: `${process.env.VUE_APP_API_ENDPOINT}/consents/${this.$route.params.id}.pdf`,
      method: "GET",
      responseType: "blob",
      httpHeaders: { Authorization: `Bearer ${this.token}` }
    })
    
    console.log(this.pdfTask)
    this.pdfTask.promise.then((pdf) => { this.numPages = pdf.numPages})

  }
};
</script>