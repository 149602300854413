<template>
  <div class="category-container" :class="{'root': isRoot, 'selected': selected}">
    <spinner v-if="loading" />
    <div v-else>
      <div class="category" :class="{'root': isRoot}">
        <div class="detail" @click="select" :class="{'child-control': childrenPresent}">
          <div class="title">
            <i :class="iconClass" />
            <strong>{{category.code}}</strong>
            - 
            {{category.name}}
          </div>
          <div class="actions" v-if="showActions">
            <authorized-link :to="{name: 'new-child-category', params: {parentId: category.id}}">
              <i class="fa fa-plus" />
            </authorized-link>
            <authorized-link :to="{name: 'edit-category', params: {id: category.id}}">
              <i class="fas fa-pencil-alt" />
            </authorized-link>
            <authorized-link :to="{name: 'confirm-delete-category', params: {id: category.id}}" :confirmDelete="false">
              <i class="far fa-trash-alt" />
            </authorized-link>
          </div>
        </div>
        <div class="expand-toggle-handle" @click="toggleChildren" v-if="childrenPresent">
          <i :class="{'icon-arrow-down8': !childrenVisible, 'icon-arrow-up8': childrenVisible}" />
        </div>
      </div>
      <div class="children" v-if="childrenVisible">
        <category v-for="child in category.children" :key="child.id" :id="child.id" :parentId="category.id" :selectable="selectable" :showActions="showActions" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from 'vuex'

export default {
  name: "category",
  props: {
    id: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number
    },
    icon: {
      type: String
    },
    showActions: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: true,
      category: {},
      childrenVisible: false
    };
  },
  computed: {
    ...mapState({
      selectedCategories: state => state.category.selected
    }),
    childrenPresent() {
      return (this.category.children || []).length > 0;
    },
    isRoot() {
      return this.parentId == undefined;
    },
    iconClass() {
      if (this.icon) {
        return this.icon;
      } else if (this.childrenPresent) {
        return this.childrenVisible ? "icon-folder-open" : "icon-folder";
      } else {
        return "icon-task"
      }
    },
    selected() {
      return this.selectedCategories.map((c) => c.id).indexOf(this.category.id) > -1
    }
  },
  methods: {
    toggleChildren() {
      this.childrenVisible = !this.childrenVisible;
    },
    select() {
      if (this.selectable) {
        if (this.selected) {
          this.$store.dispatch("category/deselect", this.category);
        } else {
          this.$store.dispatch("category/select", this.category);
        }
      }
    }
  },
  async created() {
    const response = await api.category.get(this.id);
    this.category = response.data;
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
/deep/ .container .spinner i {
  font-size: 20px;
}

.category-container {
  position: relative;

  &.selected {
    .category {
      .detail {
        background-color: #015211;
        color: white;
      }
    }
  }

  &.root {
    &:after {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    border-left: 2px solid black;
    top: 0;
    margin-top: -20px;
    left: -15px;
    width: 30px;
    height: 100%;
    z-index: -650;
  }
  .category {
    display: flex;

    &.root {
      &:before {
        display: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      border-bottom: 2px solid black;
      top: -26px;
      left: -15px;
      width: 20px;
      height: 50px;
      z-index: -650;
    }

    .detail {
      border: 2px solid black;

      background: white;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      margin: 5px;
      border-radius: 5px;
      min-width: 200px;

      i {
        font-size: 13px;
        margin-right: 3px;
      }

      cursor: pointer;
      &.child-control {
        margin: 5px 0 5px 5px;
        border-radius: 5px 0 0 5px;
        border-right: 0;
      }

      .actions {
        display: inline-block;
        width: 80px;
        text-align: right;
        a {
          color: #7b7b7b;
          margin: 2px;
        }
      }
    }

    .expand-toggle-handle {
      cursor: pointer;
      width: 30px;
      background: white;
      border: 2px solid black;
      margin: 5px 5px 5px 0;
      border-radius: 0 5px 5px 0;
      text-align: center;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .children {
    margin-left: 30px;
  }
}
</style>