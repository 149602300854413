import HTTP from '@/api/http'

export default {
  async getAll() {
    const response = await HTTP.get(`/permissions`)
    return response
  },
  async get(id) {
    const response = await HTTP.get(`/permissions/${id}`)
    return response
  },
  async create(data) {
    const response = await HTTP.post(`/permissions`, {permission: data})
    return response
  },
  async update(id, data) {
    const response = await HTTP.put(`/permissions/${id}`, {permission: data})
    return response
  },
  async delete(id) {
    const response = await HTTP.delete(`/permissions/${id}`)
    return response
  }
}