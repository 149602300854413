<template>
  <div class="color-swatch">
    <div v-if="colorSet" class="swatch" :style="colorSwatchClass" />
    <div v-else>Not Set</div>
  </div>
</template>

<script>
export default {
  name: "color-swatch",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    colorSet() {
      return this.color !== "" && this.color !== undefined && this.color !== null
    },
    colorSwatchClass() {
      return {
        "background-color": this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.color-swatch {
  .swatch {
    width: 50px;
    height: 30px;
    border: 1px solid black;
    margin: 5px;
  }
}
</style>