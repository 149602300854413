<template>
  <li class="nav-item dropdown dropdown-user">
    <a href="#" class="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
      <img :src="sessionUser.avatar_url" class="rounded-circle mr-2" height="34" alt />
      <span>{{sessionUser.first_name | titleize}}</span>
    </a>

    <div class="dropdown-menu dropdown-menu-right">
      <router-link :to="{name: 'edit-user', params: {id: sessionUser.id}}" class="dropdown-item">
        <i class="icon-user-plus"></i> My profile
      </router-link>
      <authorized-link :to="{name: 'inbox'}" class="dropdown-item">
        <i class="icon-comment-discussion"></i> Messages
        <span v-if="unreadMessages.length > 0" class="badge badge-pill bg-blue ml-auto">{{unreadMessages.length}}</span>
      </authorized-link>
      <div class="dropdown-divider"></div>
      <authorized-link :to="{name: 'edit-account', params: {id: sessionAccount.id}}" class="dropdown-item">
        <i class="icon-cog5"></i> Account settings
      </authorized-link>
      <authorized-link :to="{name: 'sign-out'}" class="dropdown-item">
        <i class="icon-switch2"></i> Logout
      </authorized-link>
    </div>
  </li>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'user-dropdown',
  computed: {
    ...mapState({
      unreadMessages: state => state.message.unread,
      sessionUser: state => state.session.user,
      sessionAccount: state => state.session.account
    })
  }
}
</script>