<template>
  <div :class="{'sidebar-xs': sidebarOpen}">
    <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md">
      <!-- Sidebar mobile toggler -->
      <div class="sidebar-mobile-toggler text-center">
        <a href="#" class="sidebar-mobile-main-toggle">
          <i class="icon-arrow-left8"></i>
        </a>
        Navigation
        <a href="#" class="sidebar-mobile-expand">
          <i class="icon-screen-full"></i>
          <i class="icon-screen-normal"></i>
        </a>
      </div>
      <!-- /sidebar mobile toggler -->

      <!-- Sidebar content -->
      <div class="sidebar-content">
        <!-- User menu -->
        <div class="sidebar-user">
          <div class="card-body">
            <div class="media">
              <div class="mr-3">
                <authorized-link :to="{name: 'dashboard'}">
                  <img
                    v-if="hasCustomAvatar"
                    :src="sessionUser.avatar_url"
                    width="38"
                    height="38"
                    class="rounded-circle"
                    alt
                  />
                  <span v-else>
                    <i class="icon-user rounded-circle avatar" />
                  </span>
                </authorized-link>
              </div>
              <div class="media-body">
                <div
                  class="media-title font-weight-semibold"
                >{{sessionUser.first_name}} {{sessionUser.last_name}}</div>
                <div class="font-size-xs opacity-50" v-if="sessionUser.city">
                  <i class="icon-pin font-size-sm"></i>
                  &nbsp;{{sessionUser.city}}
                </div>
              </div>

              <div class="ml-3 align-self-center">
                <authorized-link
                  :to="{name: 'edit-user', params: {id: sessionUser.id}}"
                  class="text-white"
                >
                  <i class="icon-cog3"></i>
                </authorized-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /user menu -->

        <!-- Main navigation -->
        <div class="card card-sidebar-mobile">
          <ul class="nav nav-sidebar" data-nav-type="accordion">
            <!-- Main -->
            <li class="nav-item-header">
              <div class="text-uppercase font-size-xs line-height-xs">Main</div>
              <i class="icon-menu" title="Main"></i>
            </li>

            <nav-item :to="{name: 'dashboard'}" icon="icon-home4">Dashboard</nav-item>
            <nav-item :to="{name: 'inbox'}" icon="icon-mail5">Inbox</nav-item>

            <nav-item-menu
              title="Consents"
              :icon="settings.consent.icon"
              resource="consent"
              v-can:read="'consent'"
            >
              <nav-item :to="{name: 'new-consent'}" icon="icon-plus3">Create</nav-item>
              <nav-item :to="{name: 'consents'}" icon="icon-cog2">Manage</nav-item>
              <nav-item
                :to="{name: 'consents', query:{filter: 'upcoming'}}"
                icon="icon-alarm-check"
              >Upcoming</nav-item>
              <nav-item
                :to="{name: 'consents', query:{filter: 'overdue'}}"
                icon="icon-alarm-cancel"
              >Overdue</nav-item>
            </nav-item-menu>
            <nav-item-menu
              title="Information Packs"
              :icon="settings.information_pack.icon"
              resource="information_pack"
              v-can:read="'information_pack'"
            >
              <nav-item :to="{name: 'new-informationpack'}" icon="icon-plus3">Create</nav-item>
              <nav-item :to="{name: 'informationpacks'}" icon="icon-cog2">Manage</nav-item>
              <nav-item :to="{name: 'informationpacks-scheduling'}" icon="icon-alarm">Appointments</nav-item>
            </nav-item-menu>



            <nav-item v-if="sessionUser.admin" :to="{name: 'accounts'}" :icon="settings.account.icon">Accounts</nav-item>
            <nav-item v-else :to="{name: 'account', params: {id: sessionUser.account_id}}" :icon="settings.account.icon">My Account</nav-item>
            <nav-item :to="{name: 'patients'}" :icon="settings.patient.icon">Patients</nav-item>
            <nav-item
              :to="{name: 'informationleaflets'}"
              :icon="settings.information_leaflet.icon"
            >Leaflets</nav-item>
            <nav-item :to="{name: 'procedures'}" :icon="settings.procedure.icon">Procedures</nav-item>
            <nav-item :to="{name: 'locations'}" :icon="settings.location.icon">Locations</nav-item>
            <nav-item :to="{name: 'users'}" :icon="settings.user.icon">Users</nav-item>
            <nav-item :to="{name: 'groups'}" :icon="settings.group.icon">Groups</nav-item>
            <nav-item :to="{name: 'categories'}" :icon="settings.category.icon">Categories</nav-item>
          </ul>
        </div>
        <!-- /main navigation -->
      </div>
      <!-- /sidebar content -->
    </div>
  </div>
</template>

<script>
import navItemMenu from "@/components/shared/sidebar/nav-item-menu";
import navItem from "@/components/shared/sidebar/nav-item";
import { mapState } from "vuex";
export default {
  name: "sub-navigation",
  components: {
    navItemMenu,
    navItem
  },
  computed: {
    ...mapState({
      settings: state => state.application.settings,
      sessionUser: state => state.session.user,
      sidebarOpen: state => state.sidebar.open
    }),
    hasCustomAvatar() {
      return (
        this.sessionUser.avatar_url !== "" &&
        this.sessionUser.avatar_url !== null &&
        this.sessionUser.avatar_url !== undefined
      );
    }
  }
};
</script>

<style lang="scss">
.sidebar {
  min-height: calc(100vh - 50px);
  height: 100%;

  i.avatar {
    width: 38px;
    height: 38px;
    font-size: 38px;
    color: white;
  }

  .nav-sidebar {
    .nav-link {
      i {
        width: 22px;
        text-align: center;
        margin-right: 6px;
      }
    }

    .nav-group-sub {
      .nav-link {
        padding: 0.625rem 1.25rem 0.625rem 2.5rem;
      }
    }
  }
}
</style>