<template>
  <div>
    <h1>Asset</h1>
    <spinner v-if="loading" />
    <div >
      <table v-if="assets.length > 0" class="table table-striped">
        <tr>
          <th>Format</th>
          <th>Payload</th>
          <th>Procedure</th>
          <th></th>
        </tr>
        <tr v-for="asset in assets" :key="asset.id">
          <td>{{asset.format}}</td>
          <td>{{asset.payload}}</td>
          <td>{{asset.procedure_id}}</td>
          <td>
            <authorized-link :to="{name: 'asset', params: {id: asset.id}}">Show</authorized-link> | 
            <authorized-link :to="{name: 'edit-asset', params: {id: asset.id}}">Edit</authorized-link>
          </td>
        </tr>
      </table>
      <authorized-link :to="{name: 'new-asset'}">Create a new Asset</authorized-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "asset-edit",
  computed: {
    ...mapState({
      assets: state => state.asset.all,
      loading: state => state.asset.loading.all
    })
  },
  created() {
    this.$store.dispatch("asset/getAll");
  }
};
</script>

<style lang='scss' scoped>
</style>