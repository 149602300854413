<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <h1 class="card-title">{{procedure.title}}</h1>
      <div class="header-elements">
        <div class="list-icons list-icons-extended">
          <a href="javascript:alert('link to PIN, not implemented yet')" class="list-icons-item" data-popup="tooltip" data-container="body" title data-original-title="GMC Register">
            <i class="icon-info22"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div v-for="document in keys" :key="document">
        <h3>{{document | titleize}}</h3>
        <html-content :html="documents[document]" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "procedure",
  props: {
    procedure: {
      type: Object,
      default: () => {}
    },
    documents: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      keys: ["description", "options", "risks"]
    };
  }
};
</script>
