<template>
  <card title="Change Password">
    <p>
      If you wish to change your password, enter your new password details below
      and click Change Password.  Note: Your password must contain at least 1 uppercase, 1 lowercase and 1 number. The password must have a minimum length of 6 characters.
    </p>

    <div v-if="formVisible">
      <div class="form-group">
        <label for="password" class="public">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
        />
      </div>
      <div class="form-group">
        <label for="password-confirmation" class="public">Confirmation</label>
        <input
          type="password"
          class="form-control"
          id="password-confirmation"
          v-model="confirmation"
        />
      </div>
      <ul class="error">
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
      <div class="form-group">
        <button
          @click="save"
          :action-in-progress="isSaving"  
          :disabled="!passwordValid" 
          class="btn btn-primary"
        >
          Update Password
        </button>
      </div>
    </div>
    <div v-else>
      <div class="form-group">
        <button @click="formVisible = true" class="btn btn-primary">
          Change Password
        </button>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "change-password",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formVisible: false,
      password: "",
      confirmation: "",
      current: "",
      isSaving: false,
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.user.error,
    }),
    passwordValid() {
      var hasLength = this.password.length >= 6;
      var hasUpperCase = /[A-Z]/.test(this.password);
      var hasLowerCase = /[a-z]/.test(this.password);
      var hasNumbers = /\d/.test(this.password);
      var hasNonalphas = /\W/.test(this.password);

      return hasUpperCase && hasLowerCase && hasNumbers && hasLength && this.password == this.confirmation

    },
    errors() {
      const errors = [];
      Object.keys(this.error).map((field) => {
        this.error[field].map((val) => {
          errors.push(`${this.titleize(field)} ${val}`);
        });
      });
      return errors;
    },
  },
  methods: {
    save() {
      this.isSaving = true;
      const id = this.user.id;
      const data = {
        password: this.password,
        password_confirmation: this.confirmation,
      };
      this.$store
        .dispatch("user/updatePassword", { id, data })
        .then((response) => {
          console.log("response", response);
          this.isSaving = false;
          if (Object.keys(this.error).length == 0) {
            this.password = "";
            this.confirmation = "";
            this.$notify({
              group: "success",
              type: "success",
              title: `Password Updated`,
              text: `Your password has been successfully updated, you will receive a confirmation email`,
            });
          }
        })
        .catch((err) => {
          console.error("Error updating password", err);
        });
    },
    titleize(sentence) {
      sentence = sentence.replace(/_/g, " ");
      if (!sentence.split) return sentence;
      var _titleizeWord = function (string) {
          return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        result = [];
      sentence.split(" ").forEach(function (w) {
        result.push(_titleizeWord(w));
      });
      return result.join(" ");
    },
  },
};
</script> 

<style lang="scss" scoped>
.error {
  margin: 10px;
  color: red;
}
</style>