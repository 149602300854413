<template>
  <div class="content d-flex justify-content-center align-items-center">
    <!-- Container -->
    <div class="flex-fill">
      <!-- Error title -->
      <div class="text-center mb-3">
        <h1 class="error-title offline-title">Maintenance</h1>

        <div class="message">Sorry, but we're performing some essential maintenance on the site, please check back later. This page will automatically check for you when the site is back, there is no need to refresh.</div>
        
        <div class="spinner-container">
          <spinner v-if="checking" />
        </div>
      </div>
      <!-- /error title -->

      <!-- Error content -->
      <div class="row">
        <div class="col-xl-4 offset-xl-4 col-md-8 offset-md-2">
          <!-- Buttons -->
          <div class="row">
            <div class="col-sm-6">
              <router-link :to="{name: 'dashboard'}" class="btn btn-primary btn-block">
                <i class="icon-home4 mr-2"></i> Dashboard
              </router-link>
            </div>

            <div class="col-sm-6">
              <a href="javascript:alert('Support to be implemented')" class="btn btn-light btn-block">
                <i class="icon-menu7 mr-2"></i> Support
              </a>
            </div>
          </div>
          <!-- /buttons -->
        </div>
      </div>
      <!-- /error wrapper -->
    </div>
    <!-- /container -->
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "maintenance",
  data() {
    return {
      checking: false,
      interval: undefined
    };
  },
  created() {
    this.interval = window.setInterval(
      function() {
        this.checking = true;
        window.setTimeout(function() {
          api.session
            .ping()
            .then(function(response) {
              console.log("i'm back", response.toString());
              if (response.toString() !== "Error: Network Error") {
                window.clearInterval(this.interval);
              } else {
                console.log("still down")
              }
              this.checking = false;
            })
            .catch(
              function() {
                this.checking = false;
              }.bind(this)
            );
        }.bind(this), 1000);
      }.bind(this),
      5000
    );
  },
  beforeDestroy() {
    window.clearInterval(this.interval)
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;

  .message {
    font-size: 1.3em;
    min-height: 50px;
  }
  .spinner-container {
    min-height: 50px;
  }
}
</style>