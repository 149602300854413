import HTTP from '@/api/http';

export default {
  async getPage(accountId, options) {
    console.log("options", options)
    const query = Object.keys(options).map((k) => {return [k, options[k]].join("=")}).join("&")
    console.log("query", query)
    const response = await HTTP.get(`/accounts/${accountId}/information_leaflets?${query}`)
    return response
  },
  async getAllTitles(accountId, pageNumber) {
    const response = await HTTP.get(`/accounts/${accountId}/information_leaflets?fields=id,title`)
    return response
  },
  async get(accountId, id) {
    const response = await HTTP.get(`/accounts/${accountId}/information_leaflets/${id}`)
    return response
  },
  async create(accountId, data) {
    const response = await HTTP.post(`/accounts/${accountId}/information_leaflets`, {information_leaflet: data})
    return response
  },
  async update(accountId, id, data) {
    const response = await HTTP.put(`/accounts/${accountId}/information_leaflets/${id}`, {information_leaflet: data})
    return response
  },
  async delete(accountId, id) {
    const response = await HTTP.delete(`/accounts/${accountId}/information_leaflets/${id}`)
    return response
  },
  async duplicate(accountId, id) {
    const response = await HTTP.post(`/accounts/${accountId}/information_leaflets/${id}/duplicate`)
    return response
  }
}