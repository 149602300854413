<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <h1>{{ procedure.title }}</h1>
      <ul class="nav nav-tabs nav-tabs-highlight">
        <li
          class="nav-item"
          v-for="tab in tabs"
          :key="tab.title"
          @click="selectTab(tab)"
        >
          <a
            href="#highlight-tab1"
            class="nav-link"
            :class="{ active: tab.active }"
            data-toggle="tab"
            >{{ tab.key | titleize }}</a
          >
        </li>
      </ul>

      <div class="tab-content">
        <html-content
          v-for="leaflet in visibleLeaflets"
          :key="`leaflet-id-${leaflet.id}`"
          :html="leaflet.body"
        />
        <fragment-document-editor
          :fragments="fragments"
          :document="activeTab.key"
          read-only
        />
      </div>
      <!-- <html-content :html="procedure.information_leaflets[0].body" /> -->

      <div>
        <authorized-link
          class="btn btn-primary"
          :to="{ name: 'edit-procedure', params: { id: procedure.id } }"
          >Edit</authorized-link
        >
        <delete-button @delete="deleteRecord">Delete</delete-button>
      </div>
    </div>
  </div>
</template>

<script>
import htmlContent from "@/components/html-content";
import fragmentDocumentEditor from "@/components/procedure/fragment-document-editor";
import { mapState } from "vuex";
export default {
  name: "procedure-show",
  components: {
    htmlContent,
    fragmentDocumentEditor,
  },
  data() {
    return {
      selectedTab: { key: "" },
    };
  },
  computed: {
    ...mapState({
      procedure: (state) => state.procedure.current,
      fragments: (state) => state.fragment.all,
      loading: (state) => state.procedure.loading.current,
      informationLeaflets: (state) => state.informationleaflet.all,
    }),
    visibleLeaflets() {
      return this.informationLeaflets.filter(
        (leaflet) => leaflet.title == this.selectedTab.key
      );
    },
    tabs() {
      return this.documentNames.map(
        function (name) {
          return { key: name, active: this.selectedTab.key == name };
        }.bind(this)
      );
    },
    tabData() {
      return {
        "Patient Information": this.procedure.patient_information,
        Description: this.procedure.description,
        Options: this.procedure.options,
        Risks: this.procedure.risks,
      };
    },
    activeTab() {
      return this.tabs.find((tab) => tab.active) || {};
    },
    documentNames() {
      const names = [
        ...new Set(this.informationLeaflets.map((item) => item.title)),
        ...new Set(this.fragments.map((item) => item.document_name)),
      ];
      return names;
    },
  },
  watch: {
    documentNames() {
      if (this.documentNames.length > 0) {
        this.selectedTab = { key: this.documentNames[0], active: true };
      }
    },
  },
  created() {
    this.$store
      .dispatch("procedure/get", this.$route.params.id)
      .then((procedure) => {
        this.$store.dispatch("informationleaflet/getPage", {
          ids: procedure.information_leaflet_ids,
        });
      });
    this.$store.dispatch("fragment/getAll", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch("procedure/delete", this.procedure.id);
    },
    selectTab(selectedTab) {
      this.selectedTab = selectedTab;
    },
  },
};
</script>

<style lang='scss' scoped>
</style>