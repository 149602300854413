import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/home.vue'
import SignIn from '@/views/sign-in'
import authorization from '@/lib/authorization'
import AcceptInvitation from '@/views/accept-invitation'

Vue.use(VueRouter)
const routes = [
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue'),
    meta: {
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
    meta: {
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/terms.vue'),
    meta: {
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/accept-invitation/:token',
    name: 'accept-invitation',
    component: AcceptInvitation,
    meta: {
      fullscreen: true,
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
    meta: {
      fullscreen: true,
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/forgotten-password',
    name: 'forgotten-password',
    component: () => import('@/views/forgotten-password.vue'),
    meta: {
      fullscreen: true,
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/reset-password.vue'),
    meta: {
      fullscreen: true,
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/sign-out',
    name: 'sign-out',
    beforeEnter: (to, from, next) => {
      store.dispatch('session/clear')
      next({ name: 'sign-in' })
    },
    meta: {
      auth: {
        resource: 'general',
        action: 'read'
      }
    }
  },
  {
    path: '/',
    name: 'home',
    meta: {
      auth: {
        public: false
      }
    },
    beforeEnter: (to, from, next) => {
      console.log('name', to.name, 'path', to.path, 'fullPath', to.fullPath)
      if (to.name == 'home') {
        console.log('redirecting to dashboad')
        next('/dashboard')
      } else {
        next()
      }
    },
    component: Home,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard.vue'),
        meta: {
          auth: {
            resource: 'general',
            action: 'read'
          }
        }
      },
      {
        path: '/inbox',
        name: 'inbox',
        component: () =>
          import(
            /* webpackChunkName: "patients" */ '@/views/messages/inbox.vue'
          ),
        meta: {
          auth: {
            resource: 'message',
            action: 'read'
          }
        }
      },
      {
        path: '/inbox/:id',
        name: 'message',
        component: () =>
          import(
            /* webpackChunkName: "patients" */ '@/views/messages/message.vue'
          ),
        meta: {
          auth: {
            resource: 'message',
            action: 'read'
          }
        }
      },
      {
        path: '/consent/:consentId/new-message/:in_reply_to',
        name: 'create-message',
        component: () =>
          import(/* webpackChunkName: "patients" */ '@/views/messages/new.vue'),
        meta: {
          auth: {
            resource: 'message',
            action: 'create'
          }
        }
      },

      {
        path: '/consent/:consentId/new-message',
        name: 'create-message',
        component: () =>
          import(/* webpackChunkName: "patients" */ '@/views/messages/new.vue'),
        meta: {
          auth: {
            resource: 'message',
            action: 'create'
          }
        }
      },
      {
        path: '/consent/:id/print',
        name: 'print-consent',
        component: () =>
          import(
            /* webpackChunkName: "print-consent" */ '@/views/consent/print.vue'
          ),
        meta: {
          auth: {
            resource: 'consent',
            action: 'read'
          }
        }
      },
      {
        path: '/consent/:id/print',
        name: 'send-consent-to-patient',
        beforeEnter: (to, from, next) => {
          store.dispatch('consent/send', to.params.id).then(() =>
            Vue.notify({
              group: 'success',
              type: 'success',
              title: 'Consent Sent',
              text: 'The notification email to the patient has been sent'
            })
          )

          next({ name: 'consent', params: { id: to.params.id } })
        },
        meta: {
          auth: {
            resource: 'consent',
            action: 'send_to_patient'
          }
        }
      },
      {
        path: '/information-packs',
        name: 'informationpacks',
        component: () =>
          import(
            /* webpackChunkName: "informationpacks" */ '@/views/informationpack/index.vue'
          ),
        meta: {
          header: {
            title: 'Information Packs',
            icon: 'icon-info-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'informationpacks', text: 'Information Packs' }
            ]
          },
          auth: {
            resource: 'information_pack',
            action: 'update'
          }
        }
      },
      {
        path: '/information-pack-scheduling',
        name: 'informationpacks-scheduling',
        component: () =>
          import(
            /* webpackChunkName: "informationpacks" */ '@/views/informationpack/scheduling.vue'
          ),
        meta: {
          auth: {
            resource: 'information_pack',
            action: 'update_status'
          }
        }
      },
      {
        path: '/information-pack/new',
        name: 'new-informationpack',
        component: () =>
          import(
            /* webpackChunkName: "informationpacks" */ '@/views/informationpack/edit.vue'
          ),
        meta: {
          header: {
            title: 'Information Pack',
            icon: 'icon-info-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'informationpacks', text: 'Information Packs' },
              { name: 'informationpack', text: 'Create' },
            ]
          },
          auth: {
            resource: 'information_pack',
            action: 'create'
          }
        }
      },
      {
        path: '/information-pack/:id',
        name: 'informationpack',
        component: () =>
          import(
            /* webpackChunkName: "informationpacks" */ '@/views/informationpack/show.vue'
          ),
        meta: {
          header: {
            title: 'Information Pack',
            icon: 'icon-info-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'informationpacks', text: 'Information Packs' },
              { name: 'informationpack', text: 'Information Pack' },
            ]
          },
          auth: {
            resource: 'information_pack',
            action: 'read'
          }
        }
      },
      {
        path: '/information-pack/:id/edit',
        name: 'edit-informationpack',
        component: () =>
          import(
            /* webpackChunkName: "informationpacks" */ '@/views/informationpack/edit.vue'
          ),
        meta: {
          header: {
            title: 'Information Pack',
            icon: 'icon-info-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'informationpacks', text: 'Information Packs' },
              { name: 'informationpack', text: 'Edit§' },
            ]
          },
          auth: {
            resource: 'information_pack',
            action: 'update'
          }
        }
      },
      {
        path: '/information-pack/:id/send-to-patient',
        name: 'send-information-pack-to-patient',
        beforeEnter: (to, from, next) => {
          store
            .dispatch('informationpack/send', to.params.id)
            .then(() =>
              Vue.notify({
                group: 'success',
                type: 'success',
                title: 'Information Pack Sent',
                text: 'The notification email to the patient has been sent'
              })
            )
            .then(() => {
              store.dispatch('informationpack/get', to.params.id)
            })

          next({ name: 'informationpack', params: { id: to.params.id } })
        },
        meta: {
          auth: {
            resource: 'information_pack',
            action: 'send_to_patient'
          }
        }
      },
      {
        path: '/patients',
        name: 'patients',
        component: () =>
          import(
            /* webpackChunkName: "patients" */ '@/views/patient/index.vue'
          ),
        meta: {
          auth: {
            resource: 'patient',
            action: 'read'
          }
        }
      },

      {
        path: '/patients',
        name: 'patients',
        component: () =>
          import(
            /* webpackChunkName: "patients" */ '@/views/patient/index.vue'
          ),
        meta: {
          auth: {
            resource: 'patient',
            action: 'read'
          }
        }
      },
      {
        path: '/patient/new',
        name: 'new-patient',
        component: () =>
          import(/* webpackChunkName: "patients" */ '@/views/patient/edit.vue'),
        meta: {
          auth: {
            resource: 'patient',
            action: 'create'
          }
        }
      },
      {
        path: '/patient/:id',
        name: 'patient',
        component: () =>
          import(/* webpackChunkName: "patients" */ '@/views/patient/show.vue'),
        meta: {
          auth: {
            resource: 'patient',
            action: 'read'
          }
        }
      },
      {
        path: '/patient/:id/edit',
        name: 'edit-patient',
        component: () =>
          import(/* webpackChunkName: "patients" */ '@/views/patient/edit.vue'),
        meta: {
          auth: {
            resource: 'patient',
            action: 'update'
          }
        }
      },
      {
        path: '/consents',
        name: 'consents',
        component: () =>
          import(
            /* webpackChunkName: "consents" */ '@/views/consent/index.vue'
          ),
        meta: {
          header: {
            title: 'Consents',
            icon: 'icon-checkmark-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'consents', text: 'Consents' }
            ]
          },
          auth: {
            resource: 'consent',
            action: 'read'
          }
        }
      },
      {
        path: '/consent/new',
        name: 'new-consent',
        component: () =>
          import(/* webpackChunkName: "consents" */ '@/views/consent/edit.vue'),
        meta: {
          header: {
            title: 'Create a new consent',
            icon: 'icon-checkmark-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'consents', text: 'Consents' },
              { name: 'new-consent', text: 'Create' }
            ]
          },
          auth: {
            resource: 'consent',
            action: 'create'
          }
        }
      },
      {
        path: '/consent/:id',
        name: 'consent',
        component: () =>
          import(/* webpackChunkName: "consents" */ '@/views/consent/show.vue'),
        meta: {
          header: {
            title: 'Consents',
            icon: 'icon-checkmark-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'consents', text: 'Consents' },
              { name: 'consent', text: 'Consent' }
            ]
          },
          auth: {
            resource: 'consent',
            action: 'read'
          }
        }
      },
      {
        path: '/consent/:id/edit',
        name: 'edit-consent',
        component: () =>
          import(/* webpackChunkName: "consents" */ '@/views/consent/edit.vue'),
        meta: {
          header: {
            title: 'Consents',
            icon: 'icon-checkmark-circle2',
            breadcrumbs: [
              { name: 'dashboard', text: 'Dashboard' },
              { name: 'consents', text: 'Consents' },
              { name: 'edit-consent', text: 'Edit'}
            ]
          },
          auth: {
            resource: 'consent',
            action: 'update'
          }
        }
      },
      {
        path: '/accounts',
        name: 'accounts',
        component: () =>
          import(
            /* webpackChunkName: "accounts" */ '@/views/account/index.vue'
          ),
        meta: {
          auth: {
            resource: 'account',
            action: 'read'
          }
        }
      },
      {
        path: '/account/new',
        name: 'new-account',
        component: () =>
          import(/* webpackChunkName: "accounts" */ '@/views/account/edit.vue'),
        meta: {
          auth: {
            resource: 'account',
            action: 'create'
          }
        }
      },
      {
        path: '/account/:id',
        name: 'account',
        component: () =>
          import(/* webpackChunkName: "accounts" */ '@/views/account/show.vue'),
        meta: {
          auth: {
            resource: 'account',
            action: 'read'
          }
        }
      },
      {
        path: '/account/:id/edit',
        name: 'edit-account',
        component: () =>
          import(/* webpackChunkName: "accounts" */ '@/views/account/edit.vue'),
        meta: {
          auth: {
            resource: 'account',
            action: 'update'
          }
        }
      },
      {
        path: '/procedures',
        name: 'procedures',
        component: () =>
          import(
            /* webpackChunkName: "procedures" */ '@/views/procedure/index.vue'
          ),
        meta: {
          auth: {
            resource: 'procedure',
            action: 'read'
          }
        }
      },
      {
        path: '/procedure/new',
        name: 'new-procedure',
        component: () =>
          import(
            /* webpackChunkName: "procedures" */ '@/views/procedure/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'procedure',
            action: 'create'
          }
        }
      },
      {
        path: '/procedure/:id',
        name: 'procedure',
        component: () =>
          import(
            /* webpackChunkName: "procedures" */ '@/views/procedure/show.vue'
          ),
        meta: {
          auth: {
            resource: 'procedure',
            action: 'read'
          }
        }
      },
      {
        path: '/procedure/:id/edit',
        name: 'edit-procedure',
        component: () =>
          import(
            /* webpackChunkName: "procedures" */ '@/views/procedure/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'procedure',
            action: 'update'
          }
        }
      },
      {
        path: '/assets',
        name: 'assets',
        component: () =>
          import(/* webpackChunkName: "assets" */ '@/views/asset/index.vue'),
        meta: {
          auth: {
            resource: 'asset',
            action: 'read'
          }
        }
      },
      {
        path: '/asset/new',
        name: 'new-asset',
        component: () =>
          import(/* webpackChunkName: "assets" */ '@/views/asset/edit.vue'),
        meta: {
          auth: {
            resource: 'asset',
            action: 'create'
          }
        }
      },
      {
        path: '/asset/:id',
        name: 'asset',
        component: () =>
          import(/* webpackChunkName: "assets" */ '@/views/asset/show.vue'),
        meta: {
          auth: {
            resource: 'asset',
            action: 'read'
          }
        }
      },
      {
        path: '/asset/:id/edit',
        name: 'edit-asset',
        component: () =>
          import(/* webpackChunkName: "assets" */ '@/views/asset/edit.vue'),
        meta: {
          auth: {
            resource: 'asset',
            action: 'update'
          }
        }
      },
      {
        path: '/users',
        name: 'users',
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/user/index.vue'),
        meta: {
          auth: {
            resource: 'user',
            action: 'read'
          }
        }
      },
      {
        path: '/user/new',
        name: 'new-user',
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/user/edit.vue'),
        meta: {
          auth: {
            resource: 'user',
            action: 'create'
          }
        }
      },
      {
        path: '/user/:id',
        name: 'user',
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/user/show.vue'),
        meta: {
          auth: {
            resource: 'user',
            action: 'read'
          }
        }
      },
      {
        path: '/user/:id/edit',
        name: 'edit-user',
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/user/edit.vue'),
        meta: {
          auth: {
            resource: 'user',
            action: 'update'
          }
        }
      },
      {
        path: '/locations',
        name: 'locations',
        component: () =>
          import(
            /* webpackChunkName: "locations" */ '@/views/location/index.vue'
          ),
        meta: {
          auth: {
            resource: 'location',
            action: 'read'
          }
        }
      },
      {
        path: '/location/new',
        name: 'new-location',
        component: () =>
          import(
            /* webpackChunkName: "locations" */ '@/views/location/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'location',
            action: 'create'
          }
        }
      },
      {
        path: '/location/:id',
        name: 'location',
        component: () =>
          import(
            /* webpackChunkName: "locations" */ '@/views/location/show.vue'
          ),
        meta: {
          auth: {
            resource: 'location',
            action: 'read'
          }
        }
      },
      {
        path: '/location/:id/edit',
        name: 'edit-location',
        component: () =>
          import(
            /* webpackChunkName: "locations" */ '@/views/location/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'location',
            action: 'update'
          }
        }
      },
      {
        path: '/groups',
        name: 'groups',
        component: () =>
          import(/* webpackChunkName: "groups" */ '@/views/group/index.vue'),
        meta: {
          auth: {
            resource: 'group',
            action: 'read'
          }
        }
      },
      {
        path: '/group/new',
        name: 'new-group',
        component: () =>
          import(/* webpackChunkName: "groups" */ '@/views/group/edit.vue'),
        meta: {
          auth: {
            resource: 'group',
            action: 'create'
          }
        }
      },
      {
        path: '/group/:id',
        name: 'group',
        component: () =>
          import(/* webpackChunkName: "groups" */ '@/views/group/show.vue'),
        meta: {
          auth: {
            resource: 'group',
            action: 'read'
          }
        }
      },
      {
        path: '/group/:id/edit',
        name: 'edit-group',
        component: () =>
          import(/* webpackChunkName: "groups" */ '@/views/group/edit.vue'),
        meta: {
          auth: {
            resource: 'group',
            action: 'update'
          }
        }
      },
      {
        path: '/page-not-found',
        name: 'page-not-found',
        component: () =>
          import(/* webpackChunkName: "groups" */ '@/views/page-not-found.vue'),
        meta: {
          fullscreen: true,
          auth: {
            resource: 'general',
            action: 'read'
          }
        }
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        component: () =>
          import(/* webpackChunkName: "groups" */ '@/views/maintenance.vue'),
        meta: {
          fullscreen: true,
          auth: {
            resource: 'general',
            action: 'read'
          }
        }
      },
      {
        path: '/categories',
        name: 'categories',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '@/views/category/index.vue'
          ),
        meta: {
          auth: {
            resource: 'category',
            action: 'read'
          }
        },
        children: [
          {
            path: '/category/:id/delete/confirm',
            name: 'confirm-delete-category',
            component: () =>
              import(
                /* webpackChunkName: "category-delete-confirm" */ '@/views/category/delete-confirm.vue'
              ),
            meta: {
              auth: {
                resource: 'category',
                action: 'delete'
              }
            }
          }
        ]
      },
      {
        path: '/category/new',
        name: 'new-category',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '@/views/category/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'category',
            action: 'create'
          }
        }
      },
      {
        path: '/category/:parentId/new-child',
        name: 'new-child-category',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '@/views/category/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'category',
            action: 'create'
          }
        }
      },
      {
        path: '/category/:id',
        name: 'category',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '@/views/category/show.vue'
          ),
        meta: {
          auth: {
            resource: 'category',
            action: 'read'
          }
        }
      },
      {
        path: '/category/:id/edit',
        name: 'edit-category',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '@/views/category/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'category',
            action: 'update'
          }
        }
      },
      {
        path: '/category/:id/delete',
        name: 'delete-category',
        beforeEnter: (to, from, next) => {
          store.dispatch('category/delete', to.params.id)
          next({ name: 'categories' })
        },
        meta: {
          auth: {
            resource: 'category',
            action: 'delete'
          }
        }
      },
      {
        path: '/procedure/:procedureId/fragments',
        name: 'fragments',
        component: () =>
          import(
            /* webpackChunkName: "fragments" */ '@/views/fragment/index.vue'
          ),
        meta: {
          auth: {
            resource: 'procedure',
            action: 'read'
          }
        }
      },
      {
        path: '/procedure/:procedureId/fragment/new',
        name: 'new-fragment',
        component: () =>
          import(
            /* webpackChunkName: "fragments" */ '@/views/fragment/edit.vue'
          )
      },
      {
        path: '/procedure/:procedureId/fragment/:id',
        name: 'fragment',
        component: () =>
          import(
            /* webpackChunkName: "fragments" */ '@/views/fragment/show.vue'
          )
      },
      {
        path: '/procedure/:procedureId/fragment/:id/edit',
        name: 'edit-fragment',
        component: () =>
          import(
            /* webpackChunkName: "fragments" */ '@/views/fragment/edit.vue'
          )
      },
      {
        path: '/informationleaflets',
        name: 'informationleaflets',
        component: () =>
          import(
            /* webpackChunkName: "informationleaflets" */ '@/views/informationleaflet/index.vue'
          ),
        meta: {
          auth: {
            resource: 'information_leaflet',
            action: 'read'
          }
        }
      },
      {
        path: '/informationleaflet/new',
        name: 'new-informationleaflet',
        component: () =>
          import(
            /* webpackChunkName: "informationleaflets" */ '@/views/informationleaflet/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'information_leaflet',
            action: 'create'
          }
        }
      },
      {
        path: '/informationleaflet/:id',
        name: 'informationleaflet',
        component: () =>
          import(
            /* webpackChunkName: "informationleaflets" */ '@/views/informationleaflet/show.vue'
          ),
        meta: {
          auth: {
            resource: 'information_leaflet',
            action: 'read'
          }
        }
      },
      {
        path: '/informationleaflet/:id/edit',
        name: 'edit-informationleaflet',
        component: () =>
          import(
            /* webpackChunkName: "informationleaflets" */ '@/views/informationleaflet/edit.vue'
          ),
        meta: {
          auth: {
            resource: 'information_leaflet',
            action: 'update'
          }
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

const nextIfValid = function (to, from, next) {
  if (to.name !== from.name) {
    next(to)
  }
}

router.onError(error => {
  console.error(error)
})

router.beforeEach((to, from, next) => {
  console.debug('to', to)
  console.debug('session-valid?', store.state.session.valid)
  console.debug('to.matched.length', to.matched.length)

  if (to.matched.length == 0) {
    console.debug("didn't match anything, page-not-found")
    nextIfValid({ to: 'page-not-found' }, from, next)
  } else if (!store.state.session.valid) {
    console.debug('session not valid... redirecting')
    nextIfValid({ to: 'sign-in' }, from, next)
  } else if (
    !authorization.isValid(to.meta.auth.resource, to.meta.auth.action)
  ) {
    console.debug('unauthorized resource... redirecting', to.meta)
    console.debug(
      'resource',
      to.meta.auth.resource,
      'action',
      to.meta.auth.action
    )
    nextIfValid({ to: 'sign-in' }, from, next)
  } else {
    console.debug(`all ok.. navigate to ${to.name}`)
    next()
  }
})
export default router
