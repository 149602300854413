<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <div class="form-group">
        <label for="title">Title</label>
        <input type="text" class="form-control" id="title" v-model="patient.title" />
      </div>
      <div class="form-group">
        <label for="first_name">First Name</label>
        <input type="text" class="form-control" id="first_name" v-model="patient.first_name" />
      </div>
      <div class="form-group">
        <label for="last_name">Last Name</label>
        <input type="text" class="form-control" id="last_name" v-model="patient.last_name" />
      </div>
      <div class="form-group">
        <label for="date_of_birth">Date Of Birth</label>
        <input type="date" class="form-control" id="date_of_birth" v-model="patient.date_of_birth" />
      </div>
      <div class="form-group">
        <label for="last_name">Gender</label>
        <input type="text" class="form-control" id="last_name" v-model="patient.gender" />
      </div>
      <div class="form-group">
        <label for="last_name">Patient Number</label>
        <input type="text" class="form-control" id="last_name" v-model="patient.chi_number" />
      </div>
      <div class="form-group">
        <label for="last_name">Mobile Number</label>
        <input type="text" class="form-control" id="last_name" v-model="patient.mobile" />
      </div>
      <div class="form-group">
        <label for="last_name">Email</label>
        <input type="text" class="form-control" id="last_name" v-model="patient.email" />
      </div>

      <a href="#" class="btn btn-primary" @click="save">Save Changes</a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "patient-edit",
  computed: {
    ...mapState({
      patient: state => state.patient.current,
      loading: state => state.patient.loading.current
    })
  },
  methods: {
    save() {
      const data = this.patient;
      if (this.patient.id !== undefined) {
        const id = this.patient.id;
        console.debug("updating ", this.patient);
        this.$store.dispatch("patient/update", { id, data });
      } else {
        console.debug("updating ", this.patient);
        this.$store.dispatch("patient/create", this.patient);
      }
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("patient/get", this.$route.params.id);
    } else {
      this.$store.dispatch("patient/clearCurrent", this.$route.params.id);
    }
  }
};
</script>

<style lang='scss' scoped>
</style>