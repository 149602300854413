import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  self_and_descendants: [],
  loading: {
    current: false,
    all: false,
    self_and_descendants: false
  },
  selected: [],
  updateKey: ''
}

const actions = {
  async getAll ({ commit }) {
    commit('startLoading', 'all')
    const result = await api.category.getAll()
    const responseData = result.data
    commit('storeCategories', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async getRoots ({ commit }) {
    commit('startLoading', 'all')
    const result = await api.category.getRoots()
    const responseData = result.data
    commit('storeCategories', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get ({ commit }, id) {
    commit('startLoading', 'current')
    const result = await api.category.get(id)
    const responseData = result.data
    commit('storeCategory', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async getSelfAndDescendants({commit}, id) {
    commit('startLoading', 'self_and_descendants')
    const result = await api.category.getSelfAndDescendants(id)
    const responseData = result.data
    commit('storeSelfAndDescendants', responseData)
    commit('endLoading', 'self_and_descendants')
    return responseData
  },
  async create ({ commit }, data) {
    const result = await api.category.create(data)
    const responseData = result.data
    commit('storeCategory', responseData)
    return responseData
  },
  async update ({ commit }, { id, data }) {
    commit('startLoading', 'current')
    const result = await api.category.update(id, data)
    const responseData = result.data
    commit('storeCategory', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete ({ commit }, id) {
    const result = await api.category.delete(id)
    const responseData = result.data
    commit('touchKey')
    commit('clearCurrent')
    return responseData
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  },
  select ({ commit }, category) {
    commit('addSelectedCategory', category)
  },
  deselect ({ commit }, category) {
    commit('removeSelectedCategory', category)
  },
  setSelected({commit}, categories) {
    commit('setSelected', categories)
  },
  clearSelected({commit}, categories) {
    commit('clearSelected')
  }
}
const mutations = {
  touchKey (state) {
    state.updateKey =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
  },
  storeCategory (state, category) {
    state.current = category
  },
  storeCategories (state, data) {
    state.all = data
  },
  clearCurrent (state) {
    state.current = {}
    state.selected = []
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  },
  addSelectedCategory (state, category) {
    if (state.selected.indexOf(category) == -1) {
      state.selected.push(category)
    }
  },
  removeSelectedCategory (state, category) {
    state.selected = state.selected.filter(c => c.id != category.id)
  },
  setSelected(state, categories) {
    state.selected = categories
  },
  clearSelected(state) {
    state.selected = []
  },
  storeSelfAndDescendants(state, categories) {
    state.self_and_descendants = categories
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
