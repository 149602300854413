
<template>
  <div class="row">
    <div class="col-xl-6">
      <div class="card">
        <div class="card-header header-elements-inline"><h3>Upcoming Consents</h3></div>
        <div class="card-body">
          <div v-if="upcoming.length == 0">There are no upcoming consents.</div>
          <consent-table v-else :consents="upcoming" />
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card">
        <div class="card-header header-elements-inline"><h3>Overdue Consents</h3></div>
        <div class="card-body">
          <div v-if="overdue.length == 0">There are no overdue consents.</div>
          <consent-table v-else :consents="overdue" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import consentTable from "@/components/consent/table";
import { mapState } from "vuex";
export default {
  name: "home",
  components: {
    consentTable
  },
  computed: {
    ...mapState({
      upcoming: state => state.consent.consents.upcoming || [],
      overdue: state => state.consent.consents.overdue || []
    })
  },
  created() {
    this.$store.dispatch("consent/getUpcoming");
    this.$store.dispatch("consent/getOverdue");
  }
};
</script>
