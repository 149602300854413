<template>
  <div class="map" ref="map"></div>
</template>

<script>
import api from "@/api";
export default {
  name: "google-map",
  props: {
    address: {
      type: String,
      default: ""
    },
    zoom: {
      type: Number,
      default: 14
    },
    apikey: {
      type: String,
      default: "AIzaSyCdcXhbzXDPVxYYs10d9k2i87FTDKr4WpQ"
    }
  },
  data() {
    return {
      map: undefined
    };
  },
  methods: {
    initMap() {
      api.google.geocode(this.address, this.apikey).then(function(latlng) {
        const loc = latlng.results[0].geometry.location;
        this.map = new google.maps.Map(this.$refs.map, {
          center: loc,
          zoom: this.zoom,
          disableDefaultUI: true,
          zoomControl: true,
          controlSize: 18
        });
      }.bind(this));
    }
  },
  mounted() {
    this.$script(
      `https://maps.googleapis.com/maps/api/js?key=${this.apikey}&libraries=geometry,places`,
      () => {
        this.initMap();
      }
    );
  }
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  font-size: 11px;
}
.gm-style {
  font-size: 8px;
}
</style>