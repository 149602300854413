<template>
  <div>
    <div>
      <div class="row">
        <div class="col-sm-8">
          <div class="card">
            <div class="card-header header-elements-inline">
              <h5 class="card-title">Profile information</h5>
              <div class="header-elements">
                <div class="list-icons"></div>
              </div>
            </div>

            <div class="card-body">
              <div v-if="user.user_type == undefined || user.user_type == null">
                <p>Please select the type of user</p>
                <div class="user-types">
                  <div class="user-type" @click="selectUserType('manager')">
                    <div class="icon">
                      <i class="flaticon-020-hospital-1" />
                    </div>
                    <div class="title">Manager</div>
                  </div>
                  <div class="user-type" @click="selectUserType('medic')">
                    <div class="icon">
                      <i class="icon flaticon-027-doctor" />
                    </div>
                    <div class="title">Medic</div>
                  </div>
                  <div class="user-type" @click="selectUserType('admin')">
                    <div class="icon">
                      <i class="icon flaticon-015-medical-record" />
                    </div>
                    <div class="title">Administration</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-2">
                      <label class="public">Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"
                        v-model="user.title"
                      />
                    </div>
                    <div class="col-md-5">
                      <label class="public">First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"
                        v-model="user.first_name"
                      />
                    </div>
                    <div class="col-md-5">
                      <label class="public">Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"
                        v-model="user.last_name"
                      />
                    </div>
                    <div class="col-md-5">
                      <label>User Type</label>
                      <v-select
                        :options="[
                          { label: 'Manager', id: 'manager' },
                          { label: 'Medic', id: 'medic' },
                          { label: 'Administration', id: 'admin' },
                        ]"
                        v-model="user.user_type"
                        :reduce="(x) => x.id"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    value="eugene@kopyov.com"
                    class="form-control"
                    v-model="user.email"
                  />
                </div>
                <div class="form-group">
                  <label>Phone #</label>
                  <input
                    type="text"
                    value="+99-99-9999-9999"
                    class="form-control"
                    v-model="user.mobile"
                  />
                </div>
                <div class="form-group" v-if="isMedic">
                  <label class="public">Contact Details</label>
                  <html-editor
                    class="form-control"
                    v-model="user.contact_details"
                  />
                </div>
                <div class="form-group" v-if="isMedic">
                  <label for="description">Description</label>
                  <input
                    type="text"
                    class="form-control"
                    id="bio"
                    v-model="user.description"
                  />
                </div>
                <div class="form-group" v-if="isMedic">
                  <label class="public" for="bio">Bio</label>
                  <textarea class="form-control" id="bio" v-model="user.bio" />
                </div>
                <div class="form-group" v-if="isMedic">
                  <label class="public" for="bio">GMC Number</label>
                  <input
                    type="text"
                    class="form-control"
                    id="bio"
                    v-model="user.gmc_number"
                  />
                </div>
                <div class="form-group" v-if="isMedic">
                  <label class="public" for="bio">Signature</label>
                  <VueSignaturePad
                    ref="signaturePad"
                    width="100%"
                    height="100%"
                  />
                  <a
                    class="govuk-button govuk-button--secondary"
                    @click="clearSignature"
                    >Clear</a
                  >
                </div>
                <input
                  type="checkbox"
                  class="form-control"
                  id="checkbox"
                  v-model="user.enabled"
                />
                <label for="checkbox">Enabled</label>
              </div>
            </div>
          </div>

          <card title="Social Links" v-if="false">
            <div class="form-group">
              <label for="twitter_url" class="public">Twitter</label>
              <input
                type="text"
                class="form-control"
                id="twitter_url"
                v-model="user.twitter_url"
              />
            </div>
            <div class="form-group">
              <label for="facebook_url" class="public">Facebook</label>
              <input
                type="text"
                class="form-control"
                id="facebook_url"
                v-model="user.facebook_url"
              />
            </div>
            <div class="form-group">
              <label for="linkedin_url" class="public">LinkedIn</label>
              <input
                type="text"
                class="form-control"
                id="linkedin_url"
                v-model="user.linkedin_url"
              />
            </div>
            <div class="form-group">
              <label for="website_url" class="public">Website</label>
              <input
                type="text"
                class="form-control"
                id="website_url"
                v-model="user.website_url"
              />
            </div>
          </card>

          <card title="Consent and DIL Visibility">
            <p>
              Select the users below who will be able to see your consents and
              information packs.
            </p>
            <v-select
              :options="otherUsers.map((u) => {u.fullName = `${u.first_name} ${u.last_name}`; return u})"
              label="fullName"
              :reduce="(object) => object.id"
              taggable
              multiple
              v-model="user.parent_ids"
              id="parent-users"
            />
          </card>

          <div class="card" v-if="isMedic">
            <div class="card-header header-elements-inline">
              <h5 class="card-title">Procedure Categories</h5>
              <div class="header-elements">
                <div class="list-icons"></div>
              </div>
            </div>

            <div class="card-body">
              <p>
                Select the categories of procedures that this medic can perform
              </p>

              <ul>
                <li v-for="category in selectedCategories" :key="category.id">
                  {{ category.name }}
                </li>
              </ul>

              <category-picker />

              <!-- <div id="modal_scrollable" class="modal fade show" v-if="showProcedureModal" tabindex="-1" style="display: block;" aria-modal="true">
                <div class="modal-dialog modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header pb-3">
                      <h5 class="modal-title">Select Categories</h5>
                      <button type="button" class="close" data-dismiss="modal" @click="showProcedureModal=false">×</button>
                    </div>

                    <div class="modal-body py-0">
                      <spinner v-if="loading" />

                      <div v-else class="categories">
                        <category v-for="category in categories" :key="category.id" :id="category.id" />
                      </div>
                    </div>

                    <div class="modal-footer pt-3">
                      <button type="button" class="btn btn-link" data-dismiss="modal" @click="showProcedureModal=false">Close</button>
                    </div>
                  </div>
                </div>
              </div>-->

              <!-- <a @click="showProcedureModal=true" class="text-white btn bg-primary">Select Categories</a> -->
            </div>
          </div>

          <div class="card" v-if="isMedic">
            <div class="card-header header-elements-inline">
              <h5 class="card-title">Locations</h5>
              <div class="header-elements">
                <div class="list-icons"></div>
              </div>
            </div>

            <div class="card-body">
              <p>Select the locations used by this user</p>
              <v-select
                :options="locations"
                label="name"
                :reduce="(object) => object.id"
                taggable
                multiple
                v-model="user.location_ids"
                id="location"
              />
            </div>
          </div>

          <div class="text-right">
            <single-click-button
              @click="save"
              :action-in-progress="isSaving"
              class="btn btn-primary"
              >Save changes</single-click-button
            >
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card">
            <div class="card-body text-center">
              <div class="card-img-actions d-inline-block mb-3">
                <upload-image
                  field="user[avatar]"
                  @crop-success="cropSuccess"
                  @crop-upload-success="cropUploadSuccess"
                  @crop-upload-fail="cropUploadFail"
                  :url="uploadUrl"
                  v-model="showAvatarField"
                  :width="300"
                  :height="300"
                  :headers="uploadHeaders"
                  :params="uploadParams"
                  method="put"
                  langType="en"
                  :value="imgDataUrl"
                  img-format="png"
                ></upload-image>

                <img
                  v-if="avatarAvailable"
                  class="img-fluid rounded-circle"
                  :src="user.avatar_url"
                  width="170"
                  height="170"
                  alt
                />
                <div v-else class="avatar img-fluid rounded-circle">
                  <i class="icon-user" />
                </div>
                <div class="card-img-actions-overlay card-img rounded-circle">
                  <a
                    @click="toggleShow"
                    class="btn btn-outline bg-white text-white border-white border-2 btn-icon rounded-round"
                  >
                    <i class="icon-plus3"></i>
                  </a>
                  <a
                    @click="clearAvatar"
                    class="btn btn-outline bg-white text-white border-white border-2 btn-icon rounded-round ml-2"
                  >
                    <i class="icon-trash"></i>
                  </a>
                </div>
              </div>

              <h6 class="font-weight-semibold mb-0">
                {{ user.title }} {{ user.first_name }} {{ user.last_name }}
              </h6>
              <span class="d-block text-muted">{{ user.description }}</span>

              <div v-if="false" class="list-icons list-icons-extended mt-3">
                <a
                  v-if="user.facebook_url"
                  :href="user.facebook_url"
                  target="_BLANK"
                  class="list-icons-item"
                  data-popup="tooltip"
                  title
                  data-container="body"
                  data-original-title="Facebook"
                >
                  <i class="icon-facebook"></i>
                </a>
                <a
                  v-if="user.twitter_url"
                  :href="user.twitter_url"
                  target="_BLANK"
                  class="list-icons-item"
                  data-popup="tooltip"
                  title
                  data-container="body"
                  data-original-title="Twitter"
                >
                  <i class="icon-twitter"></i>
                </a>
                <a
                  v-if="user.linkedin_url"
                  :href="user.linkedin_url"
                  target="_BLANK"
                  class="list-icons-item"
                  data-popup="tooltip"
                  title
                  data-container="body"
                  data-original-title="LinkedIn"
                >
                  <i class="icon-linkedin"></i>
                </a>
                <a
                  v-if="user.website_url"
                  :href="user.website_url"
                  target="_BLANK"
                  class="list-icons-item"
                  data-popup="tooltip"
                  title
                  data-container="body"
                  data-original-title="Website"
                >
                  <i class="icon-link"></i>
                </a>
              </div>
            </div>
          </div>

          <change-password :user="user" />

          <div class="card" v-if="false">
            <div class="card-header bg-transparent header-elements-inline">
              <span class="card-title font-weight-semibold">Navigation</span>
              <div class="header-elements">
                <div class="list-icons">
                  <a class="list-icons-item" data-action="collapse"></a>
                </div>
              </div>
            </div>

            <div class="card-body p-0">
              <ul class="nav nav-sidebar my-2">
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    <i class="icon-user"></i>
                    My profile
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    <i class="icon-cash3"></i>
                    Balance
                    <span
                      class="text-muted font-size-sm font-weight-normal ml-auto"
                      >$1,430</span
                    >
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    <i class="icon-tree7"></i>
                    Connections
                    <span class="badge bg-danger badge-pill ml-auto">29</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    <i class="icon-users"></i>
                    Friends
                  </a>
                </li>

                <li class="nav-item-divider"></li>

                <li class="nav-item">
                  <a href="#" class="nav-link">
                    <i class="icon-calendar3"></i>
                    Events
                    <span class="badge bg-teal-400 badge-pill ml-auto">48</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    <i class="icon-cog3"></i>
                    Account settings
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <authorized-link :to="{ name: 'users' }">Back</authorized-link>
      </div>
    </div>
  </div>
</template>

<script>
import category from "@/components/categories/category";
import Vue from "vue";
import { mapState } from "vuex";
import router from "@/router";
import changePassword from "@/components/user/change-password";
export default {
  name: "user-edit",
  components: {
    category,
    changePassword,
  },
  computed: {
    ...mapState({
      users: (state) => state.user.all,
      user: (state) => state.user.current,
      loading: (state) => state.user.loading.current,
      sessionUser: (state) => state.session.user,
      token: (state) => state.session.token,
      imgDataUrl: (state) => state.user.current.avatar_url,
      locations: (state) => state.location.all,
      procedures: (state) => state.procedure.titles,
      categories: (state) => state.category.all,
      loadingCategories: (state) => state.category.loading.all,
      selectedCategories: (state) => state.category.selected,
    }),
    otherUsers() {
      return this.users.filter(u => u.id !== this.user.id)
    },
    uploadHeaders() {
      return { Authorization: `Bearer ${this.token}` };
    },
    uploadUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/users/${this.user.id}`;
    },
    isMedic() {
      return this.user.user_type == "medic";
    },
    isManager() {
      return this.user.user_type == "manager";
    },
    isAdmin() {
      return this.user.user_type == "admin";
    },
    avatarAvailable() {
      return (
        this.imgDataUrl !== "" &&
        this.imgDataUrl !== null &&
        this.imgDataUrl !== undefined
      );
    },
  },
  data() {
    return {
      showAvatarField: false,
      showProcedureModal: false,
      uploadParams: {},
    };
  },
  methods: {
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    selectUserType(type) {
      console.log(type);
      Vue.set(this.user, "user_type", type);
    },
    cropSuccess(imgDataUrl, field) {
      console.log("cropsuccess");
    },
    cropUploadSuccess(jsonData, field) {
      console.log("upload-success", jsonData);
      this.$store.dispatch("user/storeUser", jsonData);

      console.log(this.sessionUser, jsonData);
      if (this.sessionUser.id == jsonData.id) {
        this.$store.dispatch("session/updateUser", jsonData);
      }
      this.showAvatarField = false;
    },
    cropUploadFail() {},
    toggleShow() {
      this.showAvatarField = !this.showAvatarField;
    },
    clearAvatar() {
      console.log("clearing avatar");
      const id = this.user.id;
      const data = { avatar: null };
      this.$store.dispatch("user/update", { id, data }).then(
        function (user) {
          console.log(this.sessionUser, user);
          if (this.sessionUser.id == user.id) {
            this.$store.dispatch("session/updateUser", user);
          }
        }.bind(this)
      );
    },
    save() {
      this.isSaving = true;
      const data = this.user;
      if (this.user.bio === undefined) {
        data.bio = "";
      }
      if (this.user.description === undefined) {
        data.description = "";
      }

      let signatureData;
      if (this.$refs.signaturePad) {
        signatureData = this.$refs.signaturePad.saveSignature();
        console.log(signatureData.isEmpty);
        console.log(signatureData.data);
      } else {
        signatureData = { isEmpty: true };
      }

      if (!signatureData.isEmpty) {
        data.medic_signature = signatureData.data;
      }

      data.category_ids = this.selectedCategories.map((cat) => cat.id);
      if (this.user.id !== undefined) {
        const id = this.user.id;

        this.$store.dispatch("user/update", { id, data }).then(
          function (newUser) {
            if (newUser.id == this.sessionUser.id) {
              this.$store.dispatch("session/updateUser", newUser);
            }
            this.$notify({
              group: "success",
              type: "success",
              title: `${this.user.first_name} ${this.user.last_name} Updated`,
              text: "The user has been updated successfully",
            });
            this.isSaving = false;
            router.push({ name: "user", params: { id: newUser.id } });
          }.bind(this)
        );
      } else {
        this.$store.dispatch("user/create", this.user).then(
          function (newUser) {
            this.$notify({
              group: "success",
              type: "success",
              title: `${this.user.first_name} ${this.user.last_name} Created`,
              text: "They have been sent an invitation email",
            });
            this.isSaving = false;
            router.push({ name: "user", params: { id: newUser.id } });
          }.bind(this)
        );
      }
    },
  },
  created() {
    this.$store.dispatch("category/clearSelected");
    this.$store.dispatch("category/getRoots");
    this.$store.dispatch("procedure/getTitles");
    this.$store.dispatch("user/getAll");
    this.$store.dispatch("location/getAll");
    if (this.$route.params.id !== undefined) {
      this.$store
        .dispatch("user/get", this.$route.params.id)
        .then((user) =>
          this.$store.dispatch("category/setSelected", user.categories)
        );
    } else {
      this.$store.dispatch("user/clearCurrent", this.$route.params.id);
    }
  },
};
</script>

<style lang='scss' scoped>
textarea {
  height: 100%;
}

.avatar i {
  font-size: 170px;
  border-radius: 50%;
  border: 1px solid black;
  overflow: hidden;
}

.user-types {
  display: flex;
  justify-content: space-evenly;
  margin: 50px 0;

  .user-type {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 100px;
    width: 100px;
    text-align: center;
    cursor: pointer;

    .title {
      font-weight: bold;
    }

    .icon {
      display: block;

      i {
        margin-left: -20px;
      }
    }
  }
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: 50px;
}

.public {
  position: relative;
}
.public:after {
  content: "\e9b3";
  margin-left: 5px;
  font-family: icomoon;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  min-width: 1em;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  vertical-align: middle;
  position: relative;
  top: -1px;
  -webkit-font-smoothing: antialiased;
}

.public:hover:after {
  content: "Publicly Visible";
  background-color: white;
  color: #f39c9c;
  font-size: 1em;
  font-family: inherit;
}
</style>