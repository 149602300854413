<template>
    <div class="navbar navbar-expand-lg navbar-light">
      <div class="text-center d-lg-none w-100">
        <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
          <i class="icon-unfold mr-2"></i>
          Footer
        </button>
      </div>

      <div class="navbar-collapse collapse" id="navbar-footer">
        <span class="navbar-text">
          
          &copy; 2020
          <a href="https://www.consentwise.com/" target="_BLANK">Digital Consent Limited</a>
        </span>

        <ul class="navbar-nav ml-lg-auto">
          <li class="nav-item">
            <a href="https://support.consentwise.com/" class="navbar-nav-link" target="_blank">
              <i class="icon-lifebuoy mr-2"></i> Support
            </a>
          </li>
          <li class="nav-item">
            <a href="https://knowledge.consentwise.com/" class="navbar-nav-link" target="_blank">
              <i class="icon-file-text2 mr-2"></i> Knowledge
            </a>
          </li>
        </ul>
      </div>
    </div>  
</template>

<script>
export default {
  name: "foot"
};
</script>