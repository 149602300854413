import HTTP from '@/api/http';

export default {
  async getAll(accountId, options) {
    const response = await HTTP.get(`/accounts/${accountId}/information_packs`, {params: options})
    return response
  },
  async get(accountId, id) {
    const response = await HTTP.get(`/accounts/${accountId}/information_packs/${id}`)
    return response
  },
  async getAuditSummary (accountId, id) {
    const response = await HTTP.get(`/accounts/${accountId}/information_packs/${id}/audit_summary`)
    return response
  },
  async create(accountId, data) {
    const response = await HTTP.post(`/accounts/${accountId}/information_packs`, {information_pack: data})
    return response
  },
  async update(accountId, id, data) {0

    const response = await HTTP.put(`/accounts/${accountId}/information_packs/${id}`, {information_pack: data})
    return response
  },
  async delete(accountId, id) {
    const response = await HTTP.delete(`/accounts/${accountId}/information_packs/${id}`)
    return response
  },
  async sendToPatient (accountId, id) {
    const response = await HTTP.post(`/accounts/${accountId}/information_packs/${id}/send-to-patient`)
  }
}