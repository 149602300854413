<template>
  <div>
    <h1>Category</h1>
    <spinner v-if="loading" />
    <div v-else class="categories" :key="updateKey">
      <category v-for="category in categories" :key="category.id" :id="category.id" :selectable="false" :showActions="true"/>
      <authorized-link :to="{name: 'new-category'}" class="btn btn-primary">Create a new Category</authorized-link>
    </div>
    <router-view />
  </div>
</template>

<script>
import category from '@/components/categories/category'
import { mapState } from "vuex";
export default {
  name: "category-edit",
  components: {
    category
  },
  computed: {
    ...mapState({
      categories: state => state.category.all,
      loading: state => state.category.loading.all,
      updateKey: state => state.category.updateKey
    })
  },
  watch: {
    updateKey() {
      this.$store.dispatch("category/getRoots");
    }
  },
  created() {
    this.$store.dispatch("category/getRoots");
  }
};
</script>

<style lang='scss' scoped>
/deep/ .categories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>