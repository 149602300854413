import api from '@/api'
import router from '@/router'

const state = {
  current: {
    id: 0,
    permissions: [],
    users: []
  },
  all: [
    {
      id: 0,
      permissions: [],
      users: []
    }
  ],
  loading: {
    current: false,
    all: false
  }
}

const actions = {
  async getAll ({ commit }, code) {
    commit('startLoading', 'all')
    const result = await api.group.getAll()
    const responseData = result.data
    commit('storeGroups', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get ({ commit }, id) {
    commit('startLoading', 'current')
    const result = await api.group.get(id)
    const responseData = result.data
    commit('storeGroup', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async create ({ commit }, data) {
    const result = await api.group.create(data)
    const responseData = result.data
    commit('storeGroup', responseData)
    return responseData
  },
  async update ({ commit }, { id, data }) {
    commit('startLoading', 'current')
    const result = await api.group.update(id, data)
    const responseData = result.data
    commit('storeGroup', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete ({ commit }, id) {
    const result = await api.group.delete(id)
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  async addMember ({ commit }, { id, userId }) {
    const result = await api.group.addMember(id, userId)
    if (result.status == 200) {
      commit('storeGroup', result.data)
    }
  },
  async removeMember ({ commit }, { id, userId }) {
    const result = await api.group.removeMember(id, userId)
    if (result.status == 200) {
      commit('storeGroup', result.data)
    }
  },
  async addPermission ({ commit }, { id, resourceId, action }) {
    const result = await api.group.addPermission(id, resourceId, action)
    if (result.status == 200) {
      commit('storeGroup', result.data)
    }
  },
  async removePermission ({ commit }, { id, permissionId }) {
    const result = await api.group.removePermission(id, permissionId)
    if (result.status == 200) {
      commit('storeGroup', result.data)
    }
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  }
}
const mutations = {
  storeGroup (state, group) {
    state.current = group
  },
  storeGroups (state, data) {
    state.all = data
  },
  clearCurrent (state) {
    state.current = { users: [], permissions: [] }
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
