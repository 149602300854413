import HTTP from '@/api/http'

export default {
  async getAll() {
    const response = await HTTP.get(`/accounts`)
    return response
  },
  async get(id) {
    const response = await HTTP.get(`/accounts/${id}`)
    return response
  },
  async create(data, user) {
    const response = await HTTP.post(`/accounts`, {account: data, user: user})
    return response
  },
  async update(id, data) {
    const response = await HTTP.put(`/accounts/${id}`, {account: data})
    return response
  },
  async delete(id) {
    const response = await HTTP.delete(`/accounts/${id}`)
    return response
  }
}