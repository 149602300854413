
<template>
  <div class="row metrics" v-if="metrics">
    <div class="col-sm-3 clickable" @click="navigateTo('live')">
      <metric title="Active" icon="icon-stack2" description="Sent but have not been completed">{{metrics.live}}</metric>
    </div>
    <div class="col-sm-3 clickable" @click="navigateTo('consented_this_week')">
      <metric title="Consented this week" icon="icon-calendar52" description="Completed within the last 7 days">{{metrics.consented_this_week}}</metric>
    </div>
    <div class="col-sm-3 clickable" @click="navigateTo('due_this_week')">
      <metric title="Due this week" type="warning" icon="icon-drawer-in" description="Required to be completed within the next 7 days">{{metrics.due_this_week}}</metric>
    </div>
    <div class="col-sm-3 clickable" @click="navigateTo('overdue')">
      <metric title="Overdue" type="error" icon="icon-alarm" description="Required by date has passed">{{metrics.overdue}}</metric>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "home",
  computed: {
    ...mapState({
      metrics: state => state.consent.metrics
    })
  },
  created() {
    this.$store.dispatch("consent/getMetrics");
  },
  methods: {
    navigateTo(filter) {
      this.$router.push({name: 'consents', query: {filter: filter}})
    }
  }
};
</script>

<style lang="scss" scoped>
.metrics {
  margin-bottom: 20px;

  .clickable {
    cursor: pointer;
  }
}
</style>