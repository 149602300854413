<template>
  <div class="row flex">
    <ul class="pagination pagination-flat align-self-center" v-if="pageCount > 1">
      <li class="page-item">
        <a :class="{disabled: isPrevDisabled}" @click="prevPage" class="page-link">&lt; Prev</a>
      </li>
      <li class="page-item" v-for="page in leftPages" :key="page" :class="{active: page==localCurrentPage}">
        <a @click="navigateTo(page)" class="page-link">{{page}}</a>
      </li>
      <li class="spacer" v-if="centerPages.length > 0" ></li>
      <li class="page-item" v-for="page in centerPages" :key="page" :class="{active: page==localCurrentPage}">
        <a @click="navigateTo(page)" class="page-link">{{page}}</a>
      </li>
      <li class="spacer" v-if="rightPages.length > 0"></li>
      <li class="page-item" v-for="page in rightPages" :key="page" :class="{active: page==localCurrentPage}">
        <a @click="navigateTo(page)" class="page-link">{{page}}</a>
      </li>
      <li class="page-item">
        <a :class="{disabled: isNextDisabled}" @click="nextPage" class="page-link">Next &gt;</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Paginate",
  props: {
    items: {
      type: Object,
      required: true
    },
    currentPage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fixedPages: 3,
      flexiblePages: 7,
      localCurrentPage: 1
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(
        parseFloat(this.items.count) / parseFloat(this.items.pageSize)
      );
    },
    pages() {
      let p = [...Array(this.pageCount + 1).keys()];
      p.shift();
      return p;
    },
    leftPages() {
      return this.pages.slice(0, this.fixedPages);
    },
    centerPages() {
      let eitherSide = Math.ceil(parseFloat(this.flexiblePages) / 2.0);
      let from = this.localCurrentPage - eitherSide;
      if (from < 0) {
        from = 0;
      }
      let to = this.localCurrentPage + eitherSide;

      return this.pages.slice(from, to).filter(
        function(page) {
          return (
            this.leftPages.indexOf(page) == -1 &&
            this.rightPages.indexOf(page) == -1
          );
        }.bind(this)
      );
    },
    rightPages() {
      return this.pages
        .slice(Math.max(this.pages.length - this.fixedPages, 1))
        .filter(
          function(page) {
            return this.leftPages.indexOf(page) == -1;
          }.bind(this)
        );
    },
    rightElipse() {
      return (
        this.centerPages[this.centerPages.length - 1] !== this.rightPages[0]
      );
    },
    leftElipse() {
      return this.leftPages[this.leftPages.length - 1] !== this.centerPages[0];
    },
    isNextDisabled() {
      return this.items.page >= this.pageCount;
    },
    isPrevDisabled() {
      return this.localCurrentPage < 2;
    }
  },
  methods: {
    navigateTo(page) {
      this.localCurrentPage = page;
      this.$emit("navigate-to", page);
    },
    nextPage() {
      if (!this.isNextDisabled) {
        this.localCurrentPage = this.items.page + 1;
        this.$emit("navigate-to", this.localCurrentPage);
      }
    },
    prevPage() {
      if (!this.isPrevDisabled) {
        this.localCurrentPage = this.items.page - 1;
        this.$emit("navigate-to", this.localCurrentPage);
      }
    }
  },
  mounted() {
    this.localCurrentPage = parseInt(this.items.page);
  }
};
</script>

<style lang="scss" scoped>

.pagination {
  margin: auto;
  a {
    cursor: pointer;
  }
}

li.spacer {
  display: block;
  width: 30px;
  position: relative;

  &:after {
    position: absolute;
    top: 10px;
    right: 10px;
    content: "...";
  }
}
</style>