<template>
  <div>
    <fragment-editor v-for="fragment in documentFragments" :key="`fragment-${fragment.id}`" :fragment="fragment" :read-only="readOnly"/>
    <button v-if="!readOnly" class="btn btn-primary clickable" @click="addNewFragment">Add Fragment</button>
  </div>
</template>

<script>
import fragmentEditor from "@/components/procedure/fragment-editor";
export default {
  name: "fragment-document-editor",
  props: {
    fragments: {
      type: Array,
      required: true
    },
    document: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    procedureId: {
      type: Number,
      required: true
    }
  },
  components: {
    fragmentEditor
  },
  computed: {
    documentFragments() {
      return this.fragments.filter(f => f.document_name == this.document);
    } 
  },
  methods: {
    addNewFragment() {
      const procedureId = this.procedureId
      const data = {document_name: this.document, section: "", content: ""}
      this.$store.dispatch("fragment/create", {procedureId, data}).then(() => {
        this.$store.dispatch("fragment/getAll", procedureId)
      })
    }
  }
  };
</script>