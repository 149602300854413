import HTTP from '@/api/http'

export default {
  async getAll(procedureId) {
    const response = await HTTP.get(`/procedures/${procedureId}/fragments`)
    return response
  },
  async get(procedureId, id) {
    const response = await HTTP.get(`/procedures/${procedureId}/fragments/${id}`)
    return response
  },
  async create(procedureId, data) {
    const response = await HTTP.post(`/procedures/${procedureId}/fragments`, {fragment: data})
    return response
  },
  async update(procedureId, id, data) {
    const response = await HTTP.put(`/procedures/${procedureId}/fragments/${id}`, {fragment: data})
    return response
  },
  async delete(procedureId, id) {
    const response = await HTTP.delete(`/procedures/${procedureId}/fragments/${id}`)
    return response
  },
  async revert(procedureId, id) {
    const response = await HTTP.post(`/procedures/${procedureId}/fragments/${id}/revert`)
    return response
  }
}