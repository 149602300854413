<template>
<div>
<div v-if='loading' class="spinner">Loading Fragment</div>
<div v-else>
<div class='form-group'>
<label for='document_name'>Document Name</label>
<input type='text' id='document_name' v-model='fragment.document_name'>
</div>
<div class='form-group'>
<label for='section'>Section</label>
<input type='text' id='section' v-model='fragment.section'>
</div>
<div class='form-group'>
<label for='order'>Order</label>
<input type='number' id='order' v-model='fragment.order'>
</div>
<div class='form-group'>
<label for='content'>Content</label>
<input type='text' id='content' v-model='fragment.content'>
</div>
<div class='form-group'>
<label for='procedure_id'>Procedure</label>
<input type='number' id='procedure_id' v-model='fragment.procedure_id'>
</div>
<div class='form-group'>
<label for='created_at'>Created At</label>
<input type='datetime' id='created_at' v-model='fragment.created_at'>
</div>
<div class='form-group'>
<label for='updated_at'>Updated At</label>
<input type='datetime' id='updated_at' v-model='fragment.updated_at'>
</div>
<div>
<a href='#' class='btn btn-primary' @click="save">Save Changes</a> 
<authorized-link :to="{name: 'fragments'}">Back</authorized-link>
</div>
</div>
</div>
</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: 'fragment-edit',
  computed:{
    ...mapState({
      fragment: state => state.fragment.current,
      loading: state => state.fragment.loading.current
    }),
  },
  methods: {
    save(){
      const data = this.fragment
      if (this.fragment.id !== undefined) {
        const id = this.fragment.id
        console.log("updating ", this.fragment)
        this.$store.dispatch('fragment/update', {id, data}).then(function(response) {this.$router.push({name: 'fragment', params: {id: response.id}})}.bind(this))
      } else {
        console.log("updating ", this.fragment)
        this.$store.dispatch('fragment/create', this.fragment).then(function(response) {this.$router.push({name: 'fragment', params: {id: response.id}})}.bind(this))
      }
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch('fragment/get', this.$route.params.id)
    } else {
      this.$store.dispatch('fragment/clearCurrent', this.$route.params.id)
    }
  }
};
</script>

<style lang='scss' scoped>
</style>