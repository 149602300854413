<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <h5 class="card-title">New Message</h5>
    </div>

    <div class="card-body">
      <fieldset class="mb-3">
        <div class="form-group row">
          <label class="col-form-label col-lg-2">To</label>
          <div class="col-form-label col-lg-10">{{consent.patient.title}} {{consent.patient.first_name}} {{consent.patient.last_name}}</div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-lg-2">Consent For</label>
          <div class="col-form-label col-lg-10">
            <authorized-link :to="{name: 'consent', params: {id: consent.id}}">{{consent.procedure.title}} on {{consent.scheduled_at | formatDate}}</authorized-link>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-lg-2">Subject</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" v-model="subject" />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-lg-2">Message</label>
          <div class="col-lg-10">
            <textarea v-focus class="form-control" v-model="body" />
          </div>
        </div>
      </fieldset>

      <div class="text-right">
        <button type="submit" class="btn btn-primary" :disabled="isSaving" @click="submit" disbaled data-loading>
          <i v-if="isSaving" class="icon-spinner spinner mr-2"></i>
          <i v-else class="icon-paperplane mr-2"></i>
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
export default {
  name: "create-message",
  computed: {
    ...mapState({
      consent: state => state.consent.current,
      sessionUser: state => state.session.user
    })
  },
  data() {
    return {
      isSaving: false,
      subject: "",
      body: "",
      replyToMessage: undefined
    };
  },
  methods: {
    submit() {
      this.isSaving = true;

      const data = {
        consent_id: this.consent.id,
        author_id: this.sessionUser.id,
        subject: this.subject,
        body: this.body
      };

      if (this.replyToMessage !== undefined) {
        data.in_reply_to_id = this.replyToMessage.id;
      }

      this.$store
        .dispatch("message/create", data)
        .then(
          function() {
            this.isSaving = false;
            this.$router.push({ name: "inbox" });
          }.bind(this)
        )
        .catch(
          function() {
            this.isSaving = false;
          }.bind(this)
        );
    }
  },
  mounted() {
    if (this.$route.params.in_reply_to !== undefined) {
      api.message.get(this.$route.params.in_reply_to).then(
        function(msg) {
          this.replyToMessage = msg.data;
          if (this.replyToMessage.subject.startsWith("Re: ")) {
            this.subject = `Re: ${this.replyToMessage.subject}`;
          } else {
            this.subject = `Re: ${this.replyToMessage.subject}`;
          }
        }.bind(this)
      );
    }
    this.$store.dispatch("consent/get", this.$route.params.consentId);
  }
};
</script>

<style lang="scss" scoped>
</style>