<template>
  <div class="boolean-response">
    {{displayValue}}
  </div>
</template>

<script>
export default {
  name: "boolean-response",
  props:{
    value: {
      required: true
    },
    unknownText: {
      type: String,
      default: "Not Answered Yet"
    },
    positiveText: {
      type: String,
      default: "Yes"
    },
    negativeText: {
      type: String,
      default: "No"
    }
  },
  computed: {
    displayValue() {
      if (this.value == null || this.value == undefined) {
        return this.unknownText
      } else if (this.value == false) {
        return this.negativeText
      } else {
        return this.positiveText
      }
    }
  }
}
</script>