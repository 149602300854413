import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  loading: {
    current: false,
    all: false
  }
}

const actions = {
  async getAll({
    commit
  }, code) {
    commit('startLoading', 'all')
    const result = await api.account.getAll()
    const responseData = result.data
    commit('storeAccounts', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get({
    commit
  }, id) {
    commit('startLoading', 'current')
    const result = await api.account.get(id)
    const responseData = result.data
    commit('storeAccount', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async create({commit}, {data, user}) {
    const result = await api.account.create(data, user)
    const responseData = result.data
    commit('storeAccount', responseData)
    return responseData
  },
  async update({commit}, {id, data}) {
    commit('startLoading', 'current')
    const result = await api.account.update(id, data)
    const responseData = result.data
    commit('storeAccount', responseData)
    commit('endLoading', 'current')
    router.push({name: 'account', params: {id: responseData.id}})
    return responseData
  },
  async delete({commit}, id) {
    const result = await api.account.delete(id)
    const responseData = result.data
    commit('clearCurrent')
    router.push({name: 'accounts', params: {id: responseData.id}})
    return responseData
  },
  clearCurrent({commit}) {
    commit("clearCurrent")
  },
  storeAccount({commit}, data) {
    commit("storeAccount", data)
  }

}
const mutations = {
  storeAccount(state, account) {
    state.current = account
  },
  storeAccounts(state, data) {
    state.all = data
  },
  clearCurrent(state) {
    state.current = {}
  },
  startLoading(state, key) {
    state.loading[key] = true
  },
  endLoading(state, key) {
    state.loading[key] = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
