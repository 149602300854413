<template>
  <div v-if="displayHeader" class="page-header page-header-light">
    <div class="page-header-content header-elements-md-inline">
      <div class="page-title d-flex">
        <h4>
          <i v-if="icon !== ''" :class="icon" class="mr-2"></i>
          {{title | titleize}}
        </h4>
        <a href="#" class="header-elements-toggle text-default d-md-none">
          <i class="icon-more"></i>
        </a>
      </div>

      <!-- <div class="header-elements d-none">
        <div class="d-flex justify-content-center">
          <a href="#" class="btn btn-link btn-float text-default">
            <i class="icon-bars-alt text-primary"></i>
            <span>Statistics</span>
          </a>
          <a href="#" class="btn btn-link btn-float text-default">
            <i class="icon-calculator text-primary"></i>
            <span>Invoices</span>
          </a>
          <a href="#" class="btn btn-link btn-float text-default">
            <i class="icon-calendar5 text-primary"></i>
            <span>Schedule</span>
          </a>
        </div>
      </div>-->
    </div>

    <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
      <div class="d-flex">
        <div class="breadcrumb">
          <span class="breadcrumb-item" v-for="(crumb, index) in breadcrumbs" :key="crumb.name">
            <authorized-link v-if="index < breadcrumbs.length-1" :to="{name: crumb.name}">
              <i v-if="crumb.icon" :class="crumb.icon" class="mr-2"></i>
              {{crumb.text}}
            </authorized-link>
            <span v-else class="breadcrumb-item active">{{crumb.text}}</span>
          </span>
        </div>

        <a href="#" class="header-elements-toggle text-default d-md-none">
          <i class="icon-more"></i>
        </a>
      </div>

      <div class="header-elements d-none">
        <div class="breadcrumb justify-content-center">
          <a href="#" class="breadcrumb-elements-item">
            <i class="icon-comment-discussion mr-2"></i>
            Support
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-header",
  computed: {
    displayHeader() {
      return Object.keys(this.header).length > 0;
    },
    header() {
      return this.$route.meta.header || {};
    },
    title() {
      if (this.$route.query.filter) {
        return `${this.header.title} - ${this.$route.query.filter}`;
      } else {
        return this.header.title;
      }
    },
    icon() {
      return this.header.icon;
    },
    breadcrumbs() {
      return this.header.breadcrumbs || [];
    }
  }
};
</script>

<style lang="scss" scoped>
span.breadcrumb-elements-item > a,
span.breadcrumb-item > a {
  display: inline-block;
  color: inherit;
  transition: opacity ease-in-out 0.15s;
}
</style>