<template>
  <div class="col-md-6 col-lg-4 col-xl-3">
    <card title="Note">
      <template v-slot:header>
        <div class="media">
          <div class="mr-3">
            <i class="icon-pencil3" />
          </div>

          <div class="media-body">
            <h6 class="mb-0">
              Medic Note
            </h6>
          </div>
        </div>
      </template>

      <html-content v-if="!hideContents" :html="contents" />
    </card>
  </div>
</template>

<script>
export default {
  name: "note",
  props: {
    contents: {
      type: String,
      required: true,
    },
    hideContents: {
      type: Boolean,
      default: false
    }
  },
};
</script>