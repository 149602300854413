<template>
  <div class="form-group">
    <input v-model="searchText" @keydown.enter="loadPage" class="form-control" />
    <button class="btn btn-primary" @click="loadPage">Search</button>
  </div>
</template>

<script>
export default {
  name: "information-leaflet-search",
  data() {
    return {
      searchText: "",
    };
  },
  methods: {
    loadPage() {
      this.$emit("search", this.searchText);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: initial;
    display: inline;
    flex: 1;
  }
}
</style>