import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  loading: {
    current: false,
    all: false
  }
}

const actions = {
  async getAll({
    commit
  }, code) {
    commit('startLoading', 'all')
    const result = await api.permission.getAll()
    const responseData = result.data
    commit('storePermissions', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get({
    commit
  }, id) {
    commit('startLoading', 'current')
    const result = await api.permission.get(id)
    const responseData = result.data
    commit('storePermission', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async create({commit}, data) {
    const result = await api.permission.create(data)
    const responseData = result.data
    commit('storePermission', responseData)
    return responseData
  },
  async update({commit}, {id, data}) {
    commit('startLoading', 'current')
    const result = await api.permission.update(id, data)
    const responseData = result.data
    commit('storePermission', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete({commit}, id) {
    const result = await api.permission.delete(id)
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  clearCurrent({commit}) {
    commit("clearCurrent")
  }

}
const mutations = {
  storePermission(state, permission) {
    state.current = permission
  },
  storePermissions(state, data) {
    state.all = data
  },
  clearCurrent(state) {
    state.current = {}
  },
  startLoading(state, key) {
    state.loading[key] = true
  },
  endLoading(state, key) {
    state.loading[key] = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
