<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <h6 class="card-title">{{location.name}}</h6>
      <div class="header-elements">
        <div class="list-icons list-icons-extended"></div>
      </div>
    </div>

    <div class="media card-body flex-column flex-md-row m-0">
      {{location.address}}
    </div>
    <div class="card-img-bottom map-container">
      <google-map :address="location.address" />
    </div>
    <div class="card-footer bg-white d-sm-flex justify-content-sm-between align-items-sm-center">
      <ul class="list-inline mb-0"></ul>

      <ul class="list-inline mb-0 mt-1 mt-sm-0" v-if="editable">
        <li class="list-inline-item">
          <authorized-link :to="{name: 'edit-location', params: {id: location.id}}">Edit</authorized-link>
        </li>
        <li class="list-inline-item">
          <delete-button @delete="deleteRecord">Delete</delete-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import googleMap from "@/components/google-map";
export default {
  name: "location",
  components: { googleMap },
  props: {
    location: {
      type: Object,
      default: function() {
        return {};
      }
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch("location/delete", this.location.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
}
.map-container {
  height: 200px;
}
</style>
