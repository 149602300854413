import store from '@/store'

export default {
  isValid (resource, action) {
    return this.permission(resource, action) !== undefined
  },
  permission (resource, action) {
    if (resource == 'general' && action == 'read') {
      return true
    } else {
      return store.state.session.permissions.find(
        function (p) {
          return p.resource.name == resource && p.action == action
        }.bind(this)
      )
    }
  }
}
