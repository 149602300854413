import HTTP from '@/api/http'

export default {
  async getAll() {
    const response = await HTTP.get(`/resources`)
    return response
  },
  async get(id) {
    const response = await HTTP.get(`/resources/${id}`)
    return response
  },
  async create(data) {
    const response = await HTTP.post(`/resources`, {resource: data})
    return response
  },
  async update(id, data) {
    const response = await HTTP.put(`/resources/${id}`, {resource: data})
    return response
  },
  async delete(id) {
    const response = await HTTP.delete(`/resources/${id}`)
    return response
  }
}