
<template>
  <tr>
    <td>{{informationPack.patient.chi_number}}</td>
    <td>
      <authorized-link :to="{name: 'informationpack', params: {id: informationPack.id }}">
        {{patientName}}
      </authorized-link>
    </td>
    <td>{{informationPack.information_leaflet_ids}}</td>
    <td><span class="appointment-request-status badge" :class="badgeStatus">{{informationPack.appointment_request_status}}</span></td>
    <td>
      
    </td>
  </tr>
</template>

<script>
export default {
  name: "information-pack-row",
  props: {
    informationPack: {
      type: Object,
      default: function() { return {} }
    },
    showDate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    badgeStatus() {
      return "badge-warning"
    },
    informationPackId() {
      if (this.informationPack) {
        return this.informationPack.id
      }
      return undefined
    },
    patientName() {
      if (this.informationPack) {
        if (this.informationPack.patient) {
          return `${this.informationPack.patient.first_name} ${this.informationPack.patient.last_name}`
        }
      }
      return undefined
    },
  }
};
</script>

<style lang="scss" scoped>

tr {
  // border-bottom: 1px solid black;

  &:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.appointment-request-status {
  text-transform: uppercase;
}
</style>