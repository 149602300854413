<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <p>Use the form below to generate a new information pack to send to a patient who is going to undergo a procedure or surgery. Note that you can preview the informationpack request prior to sending to the patient.</p>
      <p>On final approval an email with a secure link will be sent to the user. A text will be sent to the user with a unique code. This combination of link and unique code are required to make their informationpack secure.</p>

      <div class="row">
        <div class="col-12">
          <div class="content clearfix">
            <h6 class="title">Patient Information</h6>
            <fieldset class="body current" aria-hidden="true">
              <div class="form-group">
                <label for="patient_first_name" class="col-form-label">First Name</label>
                <input type="text" class="form-control" id="patient_first_name" v-model="localPatient.first_name" />
              </div>
              <div class="form-group">
                <label for="patient_last_name">Surname</label>
                <input type="text" class="form-control" id="patient_last_name" v-model="localPatient.last_name" />
              </div>
              <div class="form-group">
                <label for="patient_last_name">CHI Number</label>
                <!-- <patient-number v-model="chi_number" @dob="setDateOfBirth" @gender="setGender" /> -->
                <patient-number v-model="chi_number" />
              </div>
              <div class="form-group">
                <label for="procedure_id">Mobile Number</label>
                <!-- <vue-phone-number-input id="patient_mobile" v-model="localPatient.mobile" /> -->
                <input class="form-control" type="tel" v-model="localPatient.mobile" />
              </div>
              <div class="form-group">
                <label for="procedure_id">Email Address</label>
                <input type="email" class="form-control" id="patient_email" v-model="localPatient.email" />
              </div>
            </fieldset>
            <h6 class="title">Information Leaflets</h6>
            <fieldset class="body current" aria-hidden="true">
              <div class="form-group">
                <label for="procedure_id">Medic</label>
                <v-select :options="users" :getOptionLabel="item => [item.title, item.first_name, item.last_name].join(' ')" v-model="localInformationPack.user" :reduce="option => option" />
              </div>
              <div class="form-group">
                <label for="procedure_id">Information Leaflets</label>
                <v-select :options="informationleaflets" label="title" id="leaflets" v-model="localInformationPack.information_leaflet_ids" multiple taggable :reduce="option => option.id" />
              </div>
            </fieldset>
            <fieldset>
              <div class="form-group">
                <label for="notes">Notes</label>
                <html-editor v-model="localInformationPack.note"  :images="true" :media="true" :imageUploadUrl="uploadUrl" />
              </div>
              <button @click.prevent @click="save" href="#finish" class="btn btn-primary" role="menuitem">
                Save Information Pack
                <i class="icon-arrow-right14 ml-2"></i>
              </button>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import patientNumber from "@/components/patient-number";

import router from "@/router";

export default {
  name: "informationpack-edit",
  components: {
    patientNumber,
  },
  data() {
    return {
      chi_number: undefined,
      localPatient: {},
      localInformationPack: {
        user: {
          procedure_ids: [],
          location_ids: [],
        },
        note: ""
      },
      chiNumberValidationMessage: "",
    };
  },
  computed: {
    ...mapState({
      informationpack: (state) => state.informationpack.current,
      loading: (state) => state.informationpack.loading.current,
      informationleaflets: (state) => state.informationleaflet.all,
      currentUser: (state) => state.session.user,
      users: (state) => state.user.all,
    }),
    isExistingInformationPack() {
      return this.informationpack.id !== undefined;
    },
    note() {
      return this.informationpack.notes[0].body
    },
    uploadUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/assets`;
    }
  },
  methods: {
    save() {
      this.localInformationPack.user_id = this.localInformationPack.user.id;
      const data = this.informationpack;
      const patientData = this.localPatient;

      patientData.chi_number = this.chi_number;

      let patientPromise;

      if (this.localPatient.id !== undefined) {
        const id = patientData.id;
        patientPromise = this.$store.dispatch("patient/update", {
          id,
          patientData,
        });
      } else {
        patientPromise = this.$store.dispatch("patient/create", patientData);
      }

      patientPromise
        .then(
          function (patient) {
            this.localInformationPack.patient_id = patient.id;

            let action = this.isExistingInformationPack ? "update" : "create";

            const data = this.localInformationPack;
            console.log("data", data)
            const id = this.localInformationPack.id;
            if (this.isExistingInformationPack) {
              this.$store.dispatch(`informationpack/update`, { id, data }).then(
                function (informationpack) {
                  console.log("returned informationpack", informationpack);
                  this.$notify({
                    group: "success",
                    type: "success",
                    title: `Information Pack Updated`,
                    text: `Your information pack has been successfully updated`,
                  });
                  this.modified = false;
                  console.log("pushing", {
                    name: "informationpack",
                    params: { id: informationpack.id },
                  });
                  this.$router.push({
                    name: "informationpack",
                    params: { id: informationpack.id },
                  });
                }.bind(this)
              );
            } else {
              this.$store.dispatch(`informationpack/create`, data).then(
                function (informationpack) {
                  this.$notify({
                    group: "success",
                    type: "success",
                    title: `InformationPack Created`,
                    text: `Your informationpack has been successfully created`,
                  });
                  this.modified = false;
                  this.$router.push({
                    name: "informationpack",
                    params: { id: informationpack.id },
                  });
                }.bind(this)
              );
            }
          }.bind(this)
        )
        .catch(
          function (error) {
            this.$notify({
              group: "error",
              type: "error",
              title: `Error Saving Information Pack`,
              text: `There was a problem saving the patient data: ${error}`,
            });
          }.bind(this)
        );
      // }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.modified) {
      this.$dialog
        .confirm(
          "You have unsaved changes, are you sure you want to discard them?",
          {
            okText: "Discard Changes",
            cancelText: "Close",
          }
        )
        .then(function () {
          next();
        })
        .catch(function () {
          next(false);
        });
    } else {
      next();
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("informationpack/get", this.$route.params.id).then(
        function (informationpack) {
          this.localInformationPack = informationpack;
          this.localPatient = informationpack.patient;

          this.chi_number = this.localPatient.chi_number;
          if (informationpack.patient.date_of_birth) {
            this.dob = new Date(
              informationpack.patient.date_of_birth
            ).getTime();
          }
        }.bind(this)
      );
    } else {
      this.$store.dispatch(
        "informationpack/clearCurrent",
        this.$route.params.id
      );
      if (this.currentUser.user_type == "medic") {
        this.localInformationPack.user = this.currentUser;
      }
    }
    this.$store.dispatch("informationleaflet/getAllTitles");
    this.$store.dispatch("user/getAll", { user_type: "medic" });
  },
};
</script>

<style lang='scss' scoped>
textarea {
  min-height: 400px;
}

.link {
  cursor: pointer;
}

.steps {
  li {
    // opacity: 0.3;

    &.current {
      opacity: 1;
    }
  }
}
</style>
