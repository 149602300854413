<template>
  <div class="content d-flex justify-content-center align-items-center">
    <!-- Login form -->
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <img class="logo" src="@/assets/images/logo.png" />

          <div v-if="passwordRequestSent">
            <h6 class="mb-3">Thanks, we've received your request!</h6>
            <p class="text-center d-block text-muted">If the email address you supplied is registered with a user you will receive an email shortly. If you have already requested a reset link, please ensure you use the last message received.</p>
            <div class="text-center">
              <router-link :to="{name: 'sign-in'}">Sign In</router-link>
            </div>
          </div>
          <div v-else>
            <h5 class="mb-2">Request a reset password link</h5>
            <span v-if="!requestSent" class="d-block text-muted mb-3">Enter your email address below and we will send you a reset password link</span>
            <div class="form-group form-group-feedback form-group-feedback-left">
              <input class="form-control email-address" v-model="email" placeholder="Email Address" v-focus v-on:keypress="clearError" v-on:keyup.enter="focusPassword" type="email" data-kwimpalastatus="alive" data-kwimpalaid="1582284876512-3" />
              <div class="form-control-feedback">
                <i class="icon-user text-muted"></i>
              </div>
            </div>

            <div class="form-group">
              <a @click="submit" class="btn btn-primary btn-block sign-in-submit">
                Request Reset Link
                <i class="icon-circle-right2 ml-2"></i>
              </a>
            </div>
            <div class="error-container" v-if="error!==''">
              There has been a problem siging in:
              <div class="error">{{error}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "forgotten-password",
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapState({
      error: state => state.session.error,
      valid: state => state.session.valid,
      passwordRequestSent: state => state.user.passwordRequestSent
    })
  },
  methods: {
    submit() {
      const email = this.email;
      const password = this.password;
      this.$store.dispatch("user/requestPasswordReset", { email });
    },
    clearError() {
      if (this.error != "") {
        this.$store.dispatch("session/clearError");
      }
    },
    focusPassword() {
      this.$refs.password.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 300px;
}

img.logo {
  margin: 30px 0px;
  max-width: 80%;
}

.text-center {
  text-align: center;
}

.error-container {
  text-align: center;
  margin: 10px 0;
  .error {
    color: red;
    text-transform: uppercase;
  }
}
</style>