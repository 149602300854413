<template>
  <span>
    <a class="btn btn-danger" @click="deleteStarted">
      <slot></slot>
    </a>
    <div id="modal_theme_danger" v-if="modalVisible" class="modal fade show" tabindex="-1" style="display: block;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-danger">
            <h6 class="modal-title">Confirm Delete</h6>
            <button type="button" class="close" @click="closeModal" data-dismiss="modal">×</button>
          </div>

          <div class="modal-body">
            <h6 class="font-weight-semibold">Are you sure?</h6>
            <p v-if="confirmation==''">Click 'Confirm' to delete.</p>
            <div v-if="confirmation!==''">
              <p>
                To confirm the delete, enter the following
                <span class="confirmation">{{confirmation}}</span>
              </p>
              <input class="confirmation-input" type="text" v-model="confirmationEntered" />
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-link" @click="closeModal" data-dismiss="modal">Close</button>
              <button type="button" class="btn bg-danger" :class="{'disabled': !buttonEnabled}" @click="confirm">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="delete-modal" v-if="modalVisible">
      <div class="close" @click="closeModal">close</div>

      <div class="modal-content">
        <h4>Confirm Delete</h4>
        <p>Are you sure you want to delete?</p>

        <div v-if="confirmation!==''">
          <p>To confirm the delete, enter the following <span class="confirmation">{{confirmation}}</span></p>
          <input class="confirmation-input" type="text" v-model="confirmationEntered">
        </div>
        

        <a class="btn" @click="closeModal">Cancel</a>
        <a class="btn btn-danger" :class="{'disabled': !buttonEnabled}" @click="confirm">Confirm</a>
      </div>
    </div>-->
  </span>
</template>

<script>
export default {
  name: "delete-button",
  props: {
    confirmation: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      modalVisible: false,
      confirmationEntered: ""
    };
  },
  computed: {
    buttonEnabled() {
      return this.confirmation == this.confirmationEntered;
    }
  },
  methods: {
    deleteStarted() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    confirm() {
      this.$emit("delete");
      this.modalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
// .delete-modal {
//   position: fixed; /* Stay in place */
//   z-index: 1; /* Sit on top */
//   left: 0;
//   top: 0;
//   width: 100%; /* Full width */
//   height: 100%; /* Full height */
//   overflow: auto; /* Enable scroll if needed */
//   background-color: rgb(0,0,0); /* Fallback color */
//   background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
// }

// /* Modal Content/Box */
// .modal-content {
//   background-color: #fefefe;
//   margin: 15% auto; /* 15% from the top and centered */
//   padding: 20px;
//   border: 1px solid #888;
//   width: 50%; /* Could be more or less, depending on screen size */
//   min-width: 300px;
// }

// /* The Close Button */
// .close {
//   color: #aaa;
//   float: right;
//   font-size: 28px;
//   font-weight: bold;
// }

// .close:hover,
// .close:focus {
//   color: black;
//   text-decoration: none;
//   cursor: pointer;
// }

// .confirmation-input {
//   border: 2px solid #666;
//   border-radius: 3px;
//   width: 100%;
// }
</style>