<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <div class="form-group">
        <label for="format">Format</label>
        <input type="text" id="format" v-model="asset.format" />
      </div>
      <div class="form-group"></div>
      <div class="form-group">
        <label for="procedure_id">Procedure</label>
        <input type="number" id="procedure_id" v-model="asset.procedure_id" />
      </div>
      <a href="#" class="btn" @click="save">Save Changes</a>
      <div>
        <authorized-link :to="{name: 'assets'}">Back</authorized-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "asset-edit",
  computed: {
    ...mapState({
      asset: state => state.asset.current,
      loading: state => state.asset.loading.current
    })
  },
  methods: {
    save() {
      const data = this.asset;
      if (this.asset.id !== undefined) {
        const id = this.asset.id;
        console.debug("updating ", this.asset);
        this.$store.dispatch("asset/update", { id, data });
      } else {
        console.debug("updating ", this.asset);
        this.$store.dispatch("asset/create", this.asset);
      }
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("asset/get", this.$route.params.id);
    } else {
      this.$store.dispatch("asset/clearCurrent", this.$route.params.id);
    }
  }
};
</script>

<style lang='scss' scoped>
</style>