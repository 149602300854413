<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <card title="Account">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="account.name"
          />
        </div>
        <div class="form-group">
          <label for="name">Reference Prefix</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="account.reference_prefix"
          />
        </div>
        <div class="form-group">
          <label for="name">Required Consent Interval (days)</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="localInterval"
          />
        </div>
        <div class="form-group">
          <label for="name">Consent Time Limit (days)</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="localConsentTimeLimit"
          />
        </div>
        <div class="form-group small-logo-field">
          <label for="name">Small Logo Url</label>
          <input
            type="url"
            class="form-control"
            id="small-logo-url"
            v-model="account.small_logo_url"
          />
        </div>
        <div class="form-group large-logo-field">
          <label for="name">Large Logo Url</label>
          <input
            type="url"
            class="form-control"
            id="large-logo-url"
            v-model="account.large_logo_url"
          />
        </div>
        <div class="form-group">
          <label for="name">Primary Colour</label>
          <color-picker v-model="localPrimaryColor" />
        </div>
        <div class="form-group">
          <label for="name">Secondary Colour</label>
          <color-picker v-model="localSecondaryColor" />
        </div>
        <div class="form-group">
          <label for="name">Categories Template</label>
          <v-select v-model="localCategoryTemplate" :options="categoryTemplatesAvailable" :reduce="choice => choice.name" label="label" />
        </div>
      </card>

      <card v-if="$route.name == 'new-account'" title="Initial User">
        <div class="form-group">
          <label for="name">Title</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="user.title"
          />
        </div>
        <div class="form-group">
          <label for="name">First Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="user.first_name"
          />
        </div>
        <div class="form-group">
          <label for="name">Last Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="user.last_name"
          />
        </div>
        <div class="form-group">
          <label for="name">Email</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="user.email"
          />
        </div>
      </card>

      <a href="#" class="btn btn-primary" @click="save">{{ buttonText }}</a>
      <authorized-link class="btn btn-secondary" :to="{ name: 'accounts' }"
        >Back</authorized-link
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ColorPicker from "@/components/color-picker";

export default {
  name: "account-edit",
  data() {
    return {
      localInterval: undefined,
      localConsentTimeLimit: undefined,
      localCategoryTemplate: undefined,
      showLogoField: false,
      uploadParams: {},
      localPrimaryColor: "",
      localSecondaryColor: "",
      user: {
        title: "",
        first_name: "",
        last_name: "",
        email: "",
      },
      categoryTemplatesAvailable: [
        { name: "none", label: "None" },
        { name: "ccsd", label: "CCSD" },
      ],
    };
  },
  components: {
    ColorPicker,
  },
  computed: {
    ...mapState({
      account: (state) => state.account.current,
      loading: (state) => state.account.loading.current,
      token: (state) => state.session.token,
      imgDataUrl: (state) => state.user.current.avatar_url,
      sessionAccount: (state) => state.session.account,
    }),
    uploadHeaders() {
      return { Authorization: `Bearer ${this.token}` };
    },
    uploadUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/accounts/${this.account.id}`;
    },
    buttonText() {
      if (this.$route.name == "new-account") {
        return "Create Account";
      } else {
        return "Save Account";
      }
    },
  },
  methods: {
    clearAvatar() {
      const id = this.account.id;
      const data = { logo: null };
      this.$store.dispatch("account/update", { id, data });
    },
    cropSuccess(imgDataUrl, field) {
      console.log("cropsuccess");
    },
    cropUploadSuccess(jsonData, field) {
      console.log("upload-success", jsonData);
      this.$store.dispatch("account/storeAccount", jsonData);
      if (this.account.id == this.sessionAccount.id) {
        this.$store.dispatch("session/storeLogoUrl", jsonData.logo_url);
      }
      this.showLogoField = false;
    },
    cropUploadFail() {},

    save() {
      const data = this.account;
      data.consent_timelimit = this.localConsentTimeLimit * 60 * 60 * 24;
      data.consent_required_interval = this.localInterval * 60 * 60 * 24;
      data.primary_color = this.localPrimaryColor;
      data.secondary_color = this.localSecondaryColor;

      data.category_template = this.localCategoryTemplate;

      const user = this.user;

      if (this.account.id !== undefined) {
        const id = this.account.id;
        this.$store.dispatch("account/update", { id, data });
      } else {
        this.$store.dispatch("account/create", { data, user }).then(() => {
          this.$notify({
              group: "success",
              type: "success",
              title: `Account Creating`,
              text: `Your account is in the process of being created, the new user will receive an email when complete`,
            });
          this.$router.push({name: 'accounts'})
        })
      }
    },
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("account/get", this.$route.params.id).then(
        function (account) {
          this.localPrimaryColor = account.primary_color || "#2196F3";
          this.localSecondaryColor = account.secondary_color || "#333333";
          this.localInterval = account.consent_required_interval / 60 / 60 / 24;
          this.localConsentTimeLimit = account.consent_timelimit / 60 / 60 / 24;
        }.bind(this)
      );
    } else {
      this.$store.dispatch("account/clearCurrent", this.$route.params.id);
    }
  },
};
</script>

<style lang='scss' scoped>
.flex {
  display: flex;
}
.small-logo-field {
  img {
    max-width: 250px;
    max-height: 40px;
  }
}

.large-logo-field {
  img {
    max-width: 400px;
    max-height: 50px;
  }
}
</style>