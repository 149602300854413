<template>
  <li class="nav-item dropdown">
    <a href="#" class="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
      <i class="icon-bubbles4"></i>
      <span class="d-md-none ml-2">Messages</span>
      <span class="badge badge-pill bg-warning-400 ml-auto ml-md-0" v-if="unreadCount > 0">{{unreadCount}}</span>
    </a>

    <div class="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
      <div class="dropdown-content-header">
        <span class="font-weight-semibold">Messages</span>
        <!-- <a href="#" class="text-default">
          <i class="icon-compose"></i>
        </a> -->
      </div>

      <div class="dropdown-content-body dropdown-scrollable">
        <ul class="media-list" v-if="unreadMessages.length > 0">
          <li class="media" v-for="message in unreadMessages" :key="message.id">
            <div class="mr-3 position-relative">
              <img src="/global_assets/images/placeholders/placeholder.jpg" width="36" height="36" class="rounded-circle" alt />
            </div>

            <div class="media-body">
              <div class="media-title">
                <router-link :to="{name: 'message', params: {id: message.id}}" class="message-link">
                  <span class="font-weight-semibold">{{message.sender}}</span>
                  <span class="text-muted font-size-sm">{{message.created_at | formatDateTime}}</span>
                </router-link>
              </div>

              <span class="text-muted">{{message.subject}}</span>
            </div>
          </li>
        </ul>
        <div v-else>
          <p>There are no unread messages for you just now</p>
        </div>
      </div>

      <div class="dropdown-content-footer justify-content-center p-0" v-if="unreadMessages.length > 0">
        <a href="#" class="bg-light text-grey w-100 py-2" data-popup="tooltip" title="Load more">
          <i class="icon-menu7 d-block top-0"></i>
        </a>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "messages-dropdown",
  computed: {
    ...mapState({
      unreadMessages: state => state.message.unread || []
    }),
    unreadCount() {
      if (this.unreadMessages) {
        return this.unreadMessages.filter(
          function(m) {
            return m.read_at == null;
          }.bind(this)
        ).length;
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.message-link {
  display: flex;
  flex-direction: column;
}
</style>