<template>
  <div class="picker-container">
    <div v-if="showPreview" class="color-preview" :style="{'background-color': value}" @click="showPreview = false"></div>
    <div v-else class="color-picker">
      <a @click="showPreview=true">
        <i class="icon-cross" />
      </a>
      <chrome-picker v-model="color" />
    </div>
  </div>
</template>

<script>
import { Chrome } from "vue-color";
export default {
  name: "color-picker",
  components: {
    "chrome-picker": Chrome
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      color: "",
      showPreview: true
    };
  },
  watch: {
    color() {
      this.$emit("input", this.color.hex);
    }
  },
  created() {
    this.color = this.value;
  }
};
</script>

<style lang="scss" scoped>
.picker-container {
  .color-picker {
    position: relative;
    padding: 20px;
    width: 244px;

    a {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .color-preview {
    width: 100px;
    height: 40px;
  }
}
</style>