<template>
  <li @click="toggleVisible" class="nav-item nav-item-submenu clickable" :class="{'nav-item-open': selected}">
    <a class="nav-link">
      <i :class="icon"></i>
      <span>{{title}}</span>
    </a>
    <transition name="fade">
      <ul class="nav nav-group-sub" v-if="open" :data-submenu-title="title">
        <slot />
      </ul>
    </transition>
  </li>
</template>

<script>
export default {
  name: "nav-item-menu",
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "icon-copy"
    },
    resource: {
      type: String,
      required: true
    }
  },
  computed: {
    selected() {
      console.log("route meta", this.$route.meta)
      if (this.$route.meta.auth) {
        return this.$route.meta.auth.resource == this.resource
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    setVisible() {
      this.open = true;
    },
    toggleVisible() {
      this.open = !this.open;
    }
  }
};
</script>

<style lang="scss" scoped>
.nav.nav-group-sub {
  display: block;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.clickable {
  cursor: pointer;
}

</style>