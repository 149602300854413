import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  categories: [],
  titles: [],
  paged: {
    items: [],
    count: 0,
    page: 0,
    pageSize: 20
  },
  templates: [],
  loading: {
    current: false,
    all: false,
    templates: false,
    page: false,
    categories: false,
    titles: false
  }
}

const actions = {
  async getAll ({ commit }) {
    commit('startLoading', 'all')
    const result = await api.procedure.getAll()
    const responseData = result.data
    commit('storeProcedures', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async getPage ({ commit }, options) {
    
    commit('startLoading', 'page')
    const result = await api.procedure.getPage(options)
    const data = result.data
    const page = options.page
    commit('storeProcedurePage', { data, page })
    commit('endLoading', 'page')
    return data
  },
  async getTemplates ({ commit }) {
    commit('startLoading', 'templates')
    const result = await api.procedure.getTemplates()
    const responseData = result.data
    commit('storeTemplates', responseData)
    commit('endLoading', 'templates')
    return responseData
  },
  async get ({ commit }, id) {
    commit('startLoading', 'current')
    const result = await api.procedure.get(id)
    const responseData = result.data
    commit('storeProcedure', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async getCategories ({ commit }) {
    commit('startLoading', 'categories')
    const result = await api.procedure.categories()
    const responseData = result.data
    commit('storeCategories', responseData)
    commit('endLoading', 'categories')
  },
  async getTitles ({ commit }) {
    commit('startLoading', 'titles')
    const result = await api.procedure.titles()
    const responseData = result.data
    commit('storeTitles', responseData)
    commit('endLoading', 'titles')
  },
  async create ({ commit }, data) {
    const result = await api.procedure.create(data)
    const responseData = result.data
    commit('storeProcedure', responseData)
    console.debug()
    router.push({ name: 'procedure', params: { id: responseData.id } })
    return responseData
  },
  async update ({ commit }, { id, data }) {
    commit('startLoading', 'current')
    const result = await api.procedure.update(id, data)
    const responseData = result.data
    commit('storeProcedure', responseData)
    commit('endLoading', 'current')
    router.push({ name: 'procedure', params: { id: responseData.id } })
    return responseData
  },
  async delete ({ commit }, id) {
    const result = await api.procedure.delete(id)
    const responseData = result.data
    commit('clearCurrent')
    router.push({ name: 'procedures', params: { id: responseData.id } })
    return responseData
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  }
}
const mutations = {
  storeProcedure (state, procedure) {
    state.current = procedure
  },
  storeProcedures (state, data) {
    state.all = data
  },
  storeCategories(state, data) {
    state.categories = data
  },
  storeTitles(state, data) {
    state.titles = data
  },
  storeProcedurePage (state, { data, page }) {
    state.paged.items = data.items
    state.paged.count = data.count
    state.paged.page = page
  },
  storeTemplates (state, data) {
    state.templates = data
  },
  clearCurrent (state) {
    state.current = {}
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
