<template>
  <ckeditor v-if="editorReady" :editor="editor" :value="localValue" @input="valueChanged" :config="editorConfig"></ckeditor>
</template>

<script>
import CKEditor from "@/assets/js/ckeditor";

import { mapState } from "vuex";
export default {
  name: "html-editor",
  props: ["value", "config", "images", "media", "imageUploadUrl"],
  data() {
    return {
      localValue: "",
      editor: CKEditor,
      editorReady: false,
      toolbar: [
        "Paragraph",
        "bold",
        "italic",
        "link",
        "numberedList",
        "bulletedList",
        "undo",
        "redo",
        "insertTable",
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "alignment:left",
        "alignment:right",
        "alignment:center",
        "alignment:justify",
        "alignment",
        "underline",
      ],
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.session.token,
    }),
    editorConfig() {
      if (this.config) {
        return this.config;
      } else {
        const toolbar = this.toolbar;

        if (this.images) {
          toolbar.push("imageTextAlternative");
          toolbar.push("imageStyle:full");
          toolbar.push("imageStyle:side");
          toolbar.push("imageUpload");
        }
        if (this.media) {
          toolbar.push("mediaEmbed");
        }

        const conf = { toolbar: toolbar };

        if (this.imageUploadUrl) {
          conf.simpleUpload = {
            uploadUrl: this.imageUploadUrl,
            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
              "X-CSRF-TOKEN": "CSFR-Token",
              Authorization: `Bearer ${this.token}`,
            },
          };
        }

        return conf;
      }
    },
  },
  methods: {
    valueChanged(val) {
      this.$emit("input", val);
    },
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  mounted() {
    this.localValue = this.value;
    window.setTimeout(() => {
      this.editorReady = true;
    }, 10);
  },
};
</script>