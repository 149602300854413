
<template>
  <div>
    <page-title>Dashboard</page-title>
    <metrics v-can:read="'consent'"/>
    <information-packs v-can:read="'information_pack'" />
    <consents v-can:read="'consent'"/>
  </div>
</template>

<script>
import metrics from "@/components/dashboard/metrics";
import informationPacks from '@/components/dashboard/information-packs'
import consents from "@/components/dashboard/consents";
import authorization from "@/lib/authorization"
export default {
  name: "dashboard",
  components: {
    metrics,
    consents,
    informationPacks
  },
  computed: {
    consentReadValid() {
      return authorization.isValid('consent', 'read')
    },
    informationPackReadValid() {
      return authorization.isValid('information_pack', 'read')
    }
  }
};
</script>


<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
}
</style>