<template>
  <div class="wysiwyg ck-content" v-html="parsedContent" />
</template>

<script>
export default {
  name: "html-content",
  props: {
    html: {
      type: String,
    },
  },
  computed: {
    parsedContent() {
      console.log("parsing html")
      let regexp = /<figure class="media"><oembed url="(.*?)"><\/oembed><\/figure>/g;
      let counter = 0;
      return this.html.replace(regexp, (match) => {
        const result = /<oembed url="(.*?)"><\/oembed>/.exec(match);
        if (result) {
          let oembedUrl = result[1];
          console.log("oembedUrl", oembedUrl);
          oembedUrl = oembedUrl.replace(
            /https:\/\/youtu.be\/(.*?)$/g,
            "https://www.youtube.com/embed/$1"
          );
          console.log("oembedUrl", oembedUrl);
          oembedUrl = oembedUrl.replace(
            /https:\/\/www.youtube.com\/watch?v=(.*?)/g,
            "https://www.youtube.com/embed/$1"
          );
          console.log("oembedUrl", oembedUrl);
          oembedUrl = oembedUrl.replace(
            /https:\/\/vimeo.com\/([0-9]*?)\/.*?$/g,
            "https://player.vimeo.com/video/$1"
          );
          console.log("oembedUrl", oembedUrl);
          const setup = {
            techOrder: ["youtube"],
            src: oembedUrl,
          };
          console.log("setup", setup);
          counter++;

          const id = `player-${counter}`;

          this.$nextTick(function () {
            window.setTimeout(
              function () {
                const player = new Plyr("#" + id);
              }.bind(this),
              100
            );
          });

          return `<div class="plyr__video-embed" id="${id}"><iframe src="${oembedUrl}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media" allowfullscreen allowtransparency allow="autoplay"></iframe></div>`;
        } else {
          return "";
        }
      });
    }
  }
};
</script>