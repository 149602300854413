import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  auditSummary: {},
  loading: {
    current: false,
    all: false,
    auditSummary: false
  }
}

const actions = {
  async getAll ({ commit, rootState }, options) {
    commit('startLoading', 'all')
    const result = await api.informationpack.getAll(
      rootState.session.account.id,
      options
    )
    const responseData = result.data
    commit('storeInformationpacks', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get ({ commit, rootState }, id) {
    commit('startLoading', 'current')
    const result = await api.informationpack.get(
      rootState.session.account.id,
      id
    )
    const responseData = result.data
    commit('storeInformationpack', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async getAuditSummary ({ commit, rootState }, id) {
    commit('startLoading', 'audit-summary')
    const result = await api.informationpack.getAuditSummary(
      rootState.session.account.id,
      id
    )
    const responseData = result.data
    commit('storeAuditSummary', responseData)
    commit('endLoading', 'audit-summary')
    return responseData
  },
  async create ({ commit, rootState }, data) {
    const result = await api.informationpack.create(
      rootState.session.account.id,
      data
    )
    const responseData = result.data
    commit('storeInformationpack', responseData)
    return responseData
  },
  async update ({ commit, rootState }, { id, data }) {
    commit('startLoading', 'current')
    const result = await api.informationpack.update(
      rootState.session.account.id,
      id,
      data
    )
    const responseData = result.data
    commit('storeInformationpack', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete ({ commit, rootState }, id) {
    const result = await api.informationpack.delete(
      rootState.session.account.id,
      id
    )
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  },
  async send ({ commit, rootState }, id) {
    const result = await api.informationpack.sendToPatient(
      rootState.session.account.id,
      id
    )
    return result
  }
}
const mutations = {
  storeInformationpack (state, informationpack) {
    state.current = informationpack
  },
  storeInformationpacks (state, data) {
    state.all = data
  },
  storeAuditSummary (state, summary) {
    state.auditSummary = summary
  },

  clearCurrent (state) {
    state.current = {}
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
