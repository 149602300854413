import api from '@/api'
import sessionHelper from '@/lib/session'

// initial state
const state = {
  valid: false,
  token: '',
  expires: '',
  user: {},
  account: {},
  permissions: [],
  error: ''
}

const actions = {
  async signin ({ commit }, { email, password }) {
    try {
      const response = await api.session.create(email, password)
      if (response.status == '200') {
        commit('storeSession', response.data)
      } else if (response.status !== undefined) {
        commit('storeError', response.data)
        commit('clearSession')
      } else if (response.response !== undefined) {
        if (response.response.status == 401) {
          commit('storeError', 'Invalid email address or password')
        } else {
          commit('storeError', response.response.statusText)
        }
        commit('clearSession')
      } else {
        console.debug('response.error', response.error)
        console.debug('status is undefined')
        console.debug('response', response)
        console.debug('response.message', response.message)
        commit('storeError', response.message)
        commit('clearSession')
      }
    } catch (error) {
      console.debug('error...', error)
      console.debug('error.response...', error.response)
      if (error.response) {
        commit('storeError', error.response.statusText)
      } else {
        commit('storeError', error.message)
      }
      commit('clearSession')
    }
  },
  async refreshToken ({ commit }) {
    const response = await api.session.refresh()
    if (response.status == '200') {
      commit('storeSession', response.data)
    }
  },
  async acceptInvitation ({ commit }, { token, password }) {
    const response = await api.session.acceptInvitation(token, password)
    if (response.status == '200') {
      commit('storeSession', response.data)
    }
  },
  updateUser ({ commit }, user) {
    commit('updateUser', user)
  },
  signout ({ commit }) {
    commit('clear')
  },
  clear ({ commit }) {
    commit('clearSession')
  },
  clearError ({ commit }) {
    commit('storeError', '')
    commit('clearSession')
  },
  updateUser ({ commit }, newUser) {
    commit('storeUser', newUser)
  },
  updateUserAvatar ({ commit }, url) {
    commit('storeAvatar', url)
  },
  async ping ({ commit }) {
    const result = await api.session.ping()
  },
  storeLogoUrl({commit}, url) {
    commit("storeLogo", url)
  }

}

const mutations = {
  storeSession (state, data) {
    state.valid = true
    state.token = data.session.token
    state.expires = data.session.expires
    state.user = data.user
    state.account = data.account
    state.permissions = data.permissions
    sessionHelper.startSessionTimer()
  },
  storeUser (state, user) {
    state.user = user
  },
  storeError (state, data) {
    state.error = data
  },
  clearSession (state) {
    sessionHelper.clearSessionTimer()
    state.valid = false
    state.token = ''
    state.expires = ''
    state.user = {}
    state.account = {}
    state.permissions = []
  },
  updateUser (state, user) {
    state.user = user
  },
  storeAvatar (state, url) {
    state.user.avatar_url = url
  },
  storeLogo (state, url) {
    state.account.logo_url = url
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
