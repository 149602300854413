
<template>
  <div class="card text-center">
    <div class="card-body">
      <!-- Progress counter -->
      <div class="svg-center position-relative" id="hours-available-progress">
        <i :class="[icon, type]"></i>
        <h2 class="pt-1 mt-2 mb-1">
          <slot />
        </h2>
        <div>{{title}}</div>
        <!-- <div class="description">{{description}}</div> -->
      </div>
      <!-- /progress counter -->

      <!-- Bars -->
      <div id="hours-available-bars"></div>
      <!-- /bars -->
    </div>
  </div>
</template>
<script>
export default {
  name: "metric",
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  computed: {
    metricClass() {
      return this.type;
    }
  }
};
</script>

<style lang="scss" scoped>
i {
  font-size: 40px;
  &.error {
    color: #b90202;
  }
  &.warning {
    color: #da8d00;
  }
}
</style>