
<template>
  <div>
    <transition>
      <div id="modal_theme_danger" class="modal fade show" tabindex="-1" style="display: block;" aria-modal="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header bg-danger">
              <h6 class="modal-title">Confirm Delete Category</h6>
              <button type="button" class="close" data-dismiss="modal">×</button>
            </div>

            <div class="modal-body">
              <h6 class="font-weight-semibold">Please confirm you wish to delete this category</h6>
              <p>When you delete a category, all sub-categories are deleted and any Medics who have associated with that category will loose the association and any procedures that were mapped to all the deleted categories.</p>

              <hr />

              <h6 class="font-weight-semibold">{{categories.length}} {{categories.length > 1 ? "Categories Are" : "Category Is"}} Being Deleted</h6>
              <p>Below is the list of categories and the number of procedures associated with each category.</p>
              <div class="delete-list">
                <ul>
                  <li v-for="category in categories" :key="`category-${category.id}`">{{category.name}} ({{category.procedure_ids.length}})</li>
                </ul>
              </div>

              <p>If you are sure you wish to proceed, click Confirm Delete or close to dismiss.</p>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-link" @click="close">Close</button>
              <button type="button" class="btn bg-danger" @click="confirm">Confirm Delete</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "confirm-delete-category",
  methods: {
    confirm() {
      this.$store
        .dispatch("category/delete", this.$route.params.id)
        .then(() => this.$router.push({ name: "categories" }));
    },
    close() {
      this.$router.push({ name: "categories" });
    }
  },
  computed: {
    ...mapState({
      categories: state => state.category.self_and_descendants
    }),
    categoryList() {
      console.log(this.categories);
      return this.categories.map(c => c.name).join(", ");
    }
  },
  created() {
    this.$store.dispatch(
      "category/getSelfAndDescendants",
      this.$route.params.id
    );
  }
};
</script>
<style scoped>
.delete-list {
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
}
</style>