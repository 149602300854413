<template>
  <div>
    <h1>Patient</h1>
    <spinner v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <th>Title</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Date Of Birth</th>
          <th></th>
        </tr>
        <tr v-for="patient in patients" :key="patient.id">
          <td>{{patient.title}}</td>
          <td>{{patient.first_name}}</td>
          <td>{{patient.last_name}}</td>
          <td>{{patient.date_of_birth}}</td>
          <td><authorized-link :to="{name: 'patient', params: {id: patient.id}}">Show</authorized-link>
          <!-- | -->
          <!-- <authorized-link :to="{name: 'edit-patient', params: {id: patient.id}}">Edit</authorized-link> -->
          </td>
        </tr>
      </table>
      <authorized-link :to="{name: 'new-patient'}">Create a new Patient</authorized-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "patient-edit",
  computed: {
    ...mapState({
      patients: state => state.patient.all,
      loading: state => state.patient.loading.all
    })
  },
  created() {
    this.$store.dispatch("patient/getAll");
  }
};
</script>

<style lang='scss' scoped>
</style>