<template>
  <card title="Audit Log" class="audit">
    <table class="table log-entry">
      <tr v-for="entry in entries" :key="entry.id">
        <td>{{entry.occurred_at | timeInWords}}</td>
        <td>{{entry.action_name | titleize}}</td>
        <td>{{entry.payload.page | titleize}}</td>
        <td>
          <span v-if="entry.user" class="text-muted">{{entry.user.first_name}} {{entry.user.last_name}}</span>
        </td>
      </tr>
    </table>
    <a v-if="!allVisible" class="btn btn-primary float-right" @click="allVisible = true && source.audit_entries>this.max">View All</a>
  </card>
</template>

<script>
import auditCard from "@/components/audit-card";
export default {
  name: "audit-log",
  components: {
    auditCard
  },
  data() {
    return {
      allVisible: false
    }
  },
  props: {
    source: {
      type: Object,
      default: function() {
        return {};
      }
    },
    max: {
      type: Number,
      default: undefined
    }
  }, 
  computed: {
    entries() {
      if (this.max == undefined || this.allVisible) {
        return this.source.audit_entries
      } else {
        return this.source.audit_entries.slice(0, this.max)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  padding-right: 20px;

  .timeline-container {
    margin-top: 0;
  }
  .card {
    width: 100%;
  }
}
</style>