<template>
  <div>
    <h1>Procedure</h1>
    <spinner v-if="loading" />
    <div v-else>
      <div class="form-group">
        <label>Search</label>
        <input v-model="searchText" @keydown.enter="loadPage" class="form-control" />
        <button class="btn btn-primary" @click="loadPage">Search</button>
      </div>
      <div class="flex row">
        <div class="col-md-6 col-lg-4 col-xl-3" v-for="procedure in procedures.items" :key="procedure.id">
          <card :title="procedure.title">
            <template v-slot:header>
              <div class="media">
                <div class="mr-3">
                  <i :class="settings.icon" />
                </div>

                <div class="media-body">
                  <h6 class="mb-0">
                    <authorized-link :to="{name: 'procedure', params: {id: procedure.id}}" class="font-weight-semibold text-default d-block">{{procedure.title}}</authorized-link>
                  </h6>
                  <span class="text-muted">{{procedure.category | titleize}}</span>
                </div>
              </div>
            </template>
            {{procedure.short_description}}
            <template v-slot:footer>
              <ul class="list-inline mb-0">
                <!-- <li class="list-inline-item"><a href="#">3 reports</a></li>
                <li class="list-inline-item"><a href="#">7 files</a></li>-->
              </ul>

              <ul class="list-inline mb-0 mt-1 mt-sm-0">
                <li class="list-inline-item">
                  <authorized-link :to="{name: 'procedure', params: {id: procedure.id}}">Show</authorized-link>
                </li>
                <li class="list-inline-item">
                  <authorized-link :to="{name: 'edit-procedure', params: {id: procedure.id}}">Edit</authorized-link>
                </li>
              </ul>
            </template>
          </card>
        </div>
      </div>
    </div>
    <paginate :items="procedures" @navigate-to="navigateTo"></paginate>
    <authorized-link class="btn btn-primary" :to="{name: 'new-procedure'}">Create a new Procedure</authorized-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import card from "@/components/card";
import clickableBadge from "@/components/clickable-badge";
export default {
  name: "procedures",
  components: {
    card,
    clickableBadge
  },
  data() {
    return {
      searchText: "",
      currentPage: 1
    };
  },
  computed: {
    ...mapState({
      settings: state => state.application.settings.procedure,
      procedures: state => state.procedure.paged,
      loading: state => state.procedure.loading.page,
      categories: state => state.procedure.categories.map(cat => cat.name)
    }),
    options() {
      const options = { page: this.currentPage };
      if (this.searchText !== "") {
        options.query = this.searchText;
      }
      return options;
    }
  },
  methods: {
    navigateTo(page) {
      this.currentPage = page;
      this.loadPage()
    },
    loadPage() {
      this.$store.dispatch("procedure/getPage", this.options);
    }
  },
  created() {
    this.$store.dispatch("procedure/getPage", this.options);
  }
};
</script>

<style lang='scss' scoped>
.flex {
  display: flex;
  flex-direction: row;
}
.card {
  height: calc(100% - 1.25rem);
}
table {
  margin-bottom: 20px;
  i {
    margin-right: 10px;
  }
}
</style>