<template>
  <div>
    <div id="modal_scrollable" class="modal fade show" v-if="showpicker" tabindex="-1" style="display: block;" aria-modal="true">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Select Categories</h5>
            <button type="button" class="close" data-dismiss="modal" @click="showpicker=false">×</button>
          </div>

          <div class="modal-body py-0">
            <spinner v-if="loading" />

            <div v-else class="categories">
              <div v-for="category in selectedCategories" :key="`select-${category}`">{{category.id}}</div>
              <category v-for="category in categories" :key="category.id" :id="category.id" />
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal" @click="showpicker=false">Close</button>
            <!-- <button type="button" class="text-white btn bg-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>

    <a @click="showpicker=true" class="text-white btn bg-primary">Select Categories</a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import category from "@/components/categories/category";
export default {
  name: "category-picker",
  components: { category },
  data() {
    return {
      showpicker: false,
      selectedCategories: []
    };
  },
  computed: {
    ...mapState({
      categories: state => state.category.all,
      loading: state => state.category.loading.all
    })
  },
  created() {
    this.$store.dispatch("category/getRoots");
  }
};
</script>