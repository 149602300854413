<template>
  <span class="badge ml-md-3 mr-md-auto" :class="status">{{title}}</span>
</template>

<script>
export default {
  name: "badge",
  props: {
    title: {
      type: String,
      default: "Unknown"
    },
    status: {
      type: String,
      default: ""
    }
  }
}
</script>