<template>
  <div id="app">
    <div v-if="fullscreen">
      <router-view />
    </div>
    <div v-else>
      <navigation />
      <div class="page-content">
        <sidebar />
        <div class="content-wrapper">
          <page-header />
          <div class="content">
            <router-view />
          </div>
          <site-footer />
        </div>
      </div>
    </div>
    <notifications group="success" position="bottom right" />
  </div>
</template>

<script>
import sidebar from "@/components/shared/sidebar";
import pageHeader from "@/components/page-header";
import siteFooter from "@/components/shared/site-footer";
import navigation from "@/components/shared/navigation";
import { mapState } from "vuex";
import sessionHelper from "@/lib/session";
export default {
  name: "app",
  components: {
    siteFooter,
    navigation,
    sidebar,
    pageHeader,
  },
  computed: {
    fullscreen() {
      return this.$route.meta.fullscreen;
    },
  },
  created() {
    sessionHelper.startSessionTimer();
    if (sessionHelper.isValid()) {
      this.$store.dispatch("message/getUnread");
    }
  },
};
</script>

<style lang="scss">
.btn-primary {
  background-color: #3770a0;
}
.sidebar-dark .nav-sidebar > .nav-item-open > .nav-link:not(.disabled),
.sidebar-dark .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light
  .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent)
  .nav-sidebar
  > .nav-item-open
  > .nav-link:not(.disabled),
.sidebar-light
  .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent)
  .nav-sidebar
  > .nav-item
  > .nav-link.active {
  background-color: #3770a0;
}
</style>