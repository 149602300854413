<template>
  <div>
    <h1>Location</h1>
    <spinner v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <th>Name</th>
        </tr>
        <tr v-for="location in locations" :key="location.id">
          <td><authorized-link :to="{name: 'location', params: {id: location.id}}">{{location.name}}</authorized-link></td>
        </tr>
      </table>
      <authorized-link class="btn btn-primary" :to="{name: 'new-location'}">Create a new Location</authorized-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "location-edit",
  computed: {
    ...mapState({
      locations: state => state.location.all,
      loading: state => state.location.loading.all
    })
  },
  created() {
    this.$store.dispatch("location/getAll");
  }
};
</script>

<style lang='scss' scoped>
</style>