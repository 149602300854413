<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" v-model="category.name" />
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <input type="text" class="form-control" id="description" v-model="category.description" />
      </div>
      <div class="form-group">
        <label for="code">Code</label>
        <input type="text" class="form-control" id="code" v-model="category.code" />
      </div>
      <div class="form-group">
        <label for="code">Procedures</label>
        <v-select :options="procedures" label="title" :reduce="object => object.id" taggable multiple v-model="category.procedure_ids" />
      </div>
      <div>
        <a href="#" class="btn btn-primary" @click="save">Save Changes</a>
        <authorized-link :to="{name: 'categories'}">Back</authorized-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
export default {
  name: "category-edit",
  computed: {
    ...mapState({
      category: state => state.category.current,
      loading: state => state.category.loading.current,
      procedures: state => state.procedure.titles
    })
  },
  methods: {
    save() {
      const data = this.category;
      delete data.children;
      if (this.category.id !== undefined) {
        const id = this.category.id;
        this.$store.dispatch("category/update", { id, data }).then(
          function(response) {
            this.$router.push({
              name: "categories"
            });
          }.bind(this)
        );
      } else {
        this.category.parent_id = this.$route.params.parentId;
        this.$store.dispatch("category/create", this.category).then(
          function(response) {
            this.$router.push({
              name: "categories"
            });
          }.bind(this)
        );
      }
    }
  },
  async created() {
    this.$store.dispatch("procedure/getTitles");
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("category/get", this.$route.params.id);
    } else {
      this.$store.dispatch("category/clearCurrent", this.$route.params.id);
    }
  }
};
</script>

<style lang='scss' scoped>
</style>