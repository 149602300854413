<template>
  <tr class="confirmation">
    <td class="text">{{confirmation.text}}</td>
    <td v-if="confirmation.value" class="value true">Yes</td>
    <td v-else class="value false">No</td>
  </tr>
</template>

<script>
export default {
  name: "confirmation",
  props: {
    confirmation: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation {
  width: 80%;
  .text {
    padding-right: 30px;
  }

  .value {
  }
}
</style>