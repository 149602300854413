import resource from '@/api/modules/resource'
import patient from '@/api/modules/patient'
import consent from '@/api/modules/consent'
import account from '@/api/modules/account'
import accountuser from '@/api/modules/accountuser'
import procedure from '@/api/modules/procedure'
import asset from '@/api/modules/asset'
import permission from '@/api/modules/permission'
import user from '@/api/modules/user'
import groupuser from '@/api/modules/groupuser'
import location from '@/api/modules/location'
import group from '@/api/modules/group'
import session from '@/api/modules/session'
import google from '@/api/modules/google'
import message from '@/api/modules/message'
import category from '@/api/modules/category'
import fragment from '@/api/modules/fragment'
import informationleaflet from '@/api/modules/informationleaflet'
import informationpack from '@/api/modules/informationpack'

export default {
  resource: resource,
  patient: patient,
  consent: consent,
  account: account,
  accountuser: accountuser,
  procedure: procedure,
  asset: asset,
  permission: permission,
  user: user,
  groupuser: groupuser,
  location: location,
  group: group,
  session: session,
  google: google,
  message: message,
  category: category,
  fragment: fragment,
  informationleaflet: informationleaflet,
  informationpack: informationpack
}
