<template>
  <div>
    <spinner v-if="loading" />
    <div v-else class="row">
      <div class="search-bar">
        <search-term v-model="searchText" placeholder="Patient Number or Name" hide-button/>
        <authorized-link class="btn btn-primary" :to="{name: 'new-consent'}">Create a new Consent</authorized-link>
      </div>
      <div class="col-xl-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>
                <nobr>
                  First Name
                  <order-control field="patients.first_name" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Surname
                  <order-control field="patients.last_name" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Number
                  <order-control field="patients.number" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Procedure
                  <order-control field="procedures.title" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Scheduled At
                  <order-control field="scheduled_at" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Created At
                  <order-control field="created_at" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Required By
                  <order-control field="required_by" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Consented
                  <order-control field="consented" />
                </nobr>
              </th>
              <th>
                <nobr>
                  Consented At
                  <order-control field="consented_at" />
                </nobr>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="consent in filteredConsents" :key="consent.id">
              <td>
                <i class="icon-user" />
              </td>
              <td>
                <authorized-link :to="{name: 'consent', params: {id: consent.id}}">{{consent.patient.first_name}}</authorized-link>
              </td>
              <td>{{consent.patient.last_name}}</td>
              <td>{{consent.patient.chi_number}}</td>
              <td>{{consent.procedure.title}}</td>
              <td>
                <span v-if="consent.scheduled_at">{{consent.scheduled_at | formatDate}}</span>
                <span v-else class="badge badge-warning">Not Scheduled</span>
              </td>
              <td>{{consent.created_at | formatDate}}</td>
              <td>{{consent.required_by | formatDate}}</td>
              <td>
                <span v-if="consent.consented" class="badge badge-success">Consented</span>
                <span v-else class="badge badge-danger">Not Consented</span>
              </td>
              <td>{{consent.consented_at | formatDate}}&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import orderControl from "@/components/order-control";
import { mapState } from "vuex";
export default {
  name: "consents",
  components: {
    orderControl
  },
  computed: {
    ...mapState({
      consents: state => state.consent.all,
      loading: state => state.consent.loading.all,
      filter: state => state.consent.consents.filter,
      order: state => state.consent.consents.order
    }),
    requestedFilter() {
      return this.$route.query.filter;
    },
    filteredConsents() {
      if (this.searchText.length > 0) {
        return this.consents.filter(p => p.patient.first_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || p.patient.last_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || p.patient.chi_number.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 )
      } else {
        return this.consents
      }
    }

  },
  data() {
    return {
      searchText: ""
    };
  },
  watch: {
    requestedFilter() {
      this.$store.dispatch("consent/setFilter", this.$route.query.filter);
      this.refreshConsents();
    },
    order() {
      this.refreshConsents();
    }
  },
  created() {
    if (this.$route.query.filter) {
      this.$store.dispatch("consent/setFilter", this.$route.query.filter);
    } else {
      this.$store.dispatch("consent/setFilter", undefined);
    }

    this.refreshConsents();
  },
  methods: {
    search() {
      this.$store
        .dispatch("consent/setSearch", this.searchTerm)
        .then(() => this.refreshConsents());
    },
    refreshConsents() {
      const filter = this.$route.query.filter;
      const order = this.order;

      console.log("filter", filter);
      console.log("order", order);

      this.$store.dispatch("consent/getAll");
    }
  }
};
</script>

<style lang='scss' scoped>
.badge {
  width: 100%;
  &.badge-success {
    background-color: #327735;
  }

  &.badge-warning {
  }

  &.badge-danger {
    background-color: #a52c24;
  }
}

.search {
  display: flex;

  * {
    margin-right: 2px;
  }
}

.btn {
  &.search-button {
    margin: 0px;
  }

  &.create-new {
    margin: 0;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1280px) {
  td:nth-of-type(1) {
    display: none;
  }

  th:nth-of-type(1) {
    display: none;
  }

}

@media only screen and (max-width: 993px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40%;
    padding-top: 0;
    padding-bottom: 0;
    height: 25px;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 1px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
	*/
  td:nth-of-type(2):before {
    content: "First Name";
  }
  td:nth-of-type(3):before {
    content: "Last Name";
  }
  td:nth-of-type(4):before {
    content: "Procedure";
  }
  td:nth-of-type(5):before {
    content: "Scheduled At";
  }
  td:nth-of-type(6):before {
    content: "Required By";
  }
  td:nth-of-type(7):before {
    content: "Consented";
  }
  td:nth-of-type(8):before {
    content: "Consented At";
  }
}

.search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 10px 20px 10px;
}

</style>