<template>
  <li class="nav-item">
    <authorized-link :to="to" class="nav-link">
      <i v-if="icon !== ''" :class="icon"></i>
      <span>
        <slot />
      </span>
    </authorized-link>
  </li>
</template>

<script>
export default {
  name: "nav-item",
  props: {
    to: {
      type: Object,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>
