import HTTP from '@/api/http';

export default {
  async getAll() {
    const response = await HTTP.get(`/categories`)
    return response
  },
  async getRoots() {
    const response = await HTTP.get(`/category-roots`)
    return response
  },
  async getSelfAndDescendants(id) {
    const response = await HTTP.get(`/categories/${id}/self_and_descendants`)
    return response
  },
  async get(id) {
    const response = await HTTP.get(`/categories/${id}`)
    return response
  },
  async create(data) {
    const response = await HTTP.post(`/categories`, {category: data})
    return response
  },
  async update(id, data) {
    const response = await HTTP.put(`/categories/${id}`, {category: data})
    return response
  },
  async delete(id) {
    const response = await HTTP.delete(`/categories/${id}`)
    return response
  }
}
