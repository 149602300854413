<template>
  <div class="timeline-row">
    <div class="timeline-icon">
      <i :class="iconClass" />
    </div>
    <div class="card">
      <div class="card-header">
        <strong>{{entry.occurred_at | formatDateTime}}</strong>
      </div>
      <div class="card-body">{{entry.action_name | titleize}} {{entry.payload  | titleize }}
      </div>
      <div class="card-footer d-flex justify-content-between">
        <span v-if="entry.user" class="text-muted">{{entry.user.first_name}} {{entry.user.last_name}}</span>

        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            <a href="#">Edit</a>
          </li>
          <li class="list-inline-item">
            <a href="#">Delete</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "audit-card",
  props: {
    entry: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    iconClass() {
      switch (this.entry.action_name) {
        case "consent_created":
          return "icon-pencil3";
          break;
        case "consent_updated":
          return "icon-floppy-disks";
          break;
        case "consent_deleted":
          return "icon-trash";
          break;
        case "patient_signed_consent":
          return "icon-checkmark4";
          break;
        case "medic_signed_consent":
          return "icon-quill4";
          break;
        case "consent_sent":
          return "icon-envelop";
          break;
        case "signed_into_consent":
          return "icon-key";
          break;
        case "confirmation_code_sent":
          return "icon-station";
          break;
        default:
          return "icon-book";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  font-size: 1.2em;
}

.timeline-icon {
  i {
    width: 100%;
    height: 100%;
    margin-top: 8px;
    font-size: 22px;
    color: #ccc;
  }
}
</style>