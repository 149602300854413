<template>
  <div class="spinner-container">
    <div class="spinner" :style="spinnerStyle">
      <i :class="iconClass" :style="iconStyle"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "spinner",
  props: {
    type: {
      type: String,
      default: "spinner2" 
    },
    height: {
      type: String,
      default: "200px"
    },
    fontSize: {
      type: String,
      default: "50px"
    }

  },
  computed: {
    spinnerStyle() {
      return {
        height: this.fontSize
      }
    },
    iconStyle() {
      return {
       "font-size": this.fontSize
      }
    },
    iconClass() {
      return `icon-${this.type}`
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner-container {
  width: 100%;
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  .spinner {
    // height: 50px;
    // i {
    //   font-size: 50px;
    // }
  }
}
</style>