<template>
  <div>
    <h1>User</h1>
    <spinner v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <th style="width: 80px;"></th>
          <th>Name</th>
          <th>Email</th>
          <th>Accepted</th>
          <th>Invitation Expires on</th>
          <th></th>
        </tr>
        <tr v-for="user in users" :key="user.id">
          <td>
            <img :src="user.avatar_url" class="rounded-circle" width="40" height="40" alt />
          </td>
          <td>
            <authorized-link :to="{name: 'user', params: {id: user.id}}">{{user.title}} {{user.first_name}} {{user.last_name}}</authorized-link>
          </td>
          <td>{{user.email}}</td>
          <td>{{user.invitation_accepted | formatBoolean}}</td>
          <td>{{user.invitation_due_at | formatDate}}</td>
          <td>
            <authorized-link :to="{name: 'edit-user', params: {id: user.id}}">Edit</authorized-link>
          </td>
        </tr>
      </table>
      <authorized-link :to="{name: 'new-user'}" class="btn btn-primary">Create a new User</authorized-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "user-edit",
  computed: {
    ...mapState({
      users: state => state.user.all,
      loading: state => state.user.loading.all
    })
  },
  created() {
    this.$store.dispatch("user/getAll");
  }
};
</script>

<style lang='scss' scoped>
table {
  margin-bottom: 20px;
}
</style>