<template>
  <div>
    <h1>Information Leaflets</h1>
    <spinner v-if="loading" />
    <div>
      <div class="flex row">
        <div class="col-md-9">
          <information-leaflet-search @search="search" />
        </div>
        <div class="col-md-3 top-actions mb-3">
          <authorized-link
            class="btn btn-primary"
            :to="{ name: 'new-informationleaflet' }"
            >Create a new Information Leaflet</authorized-link
          >
        </div>
      </div>
      <div class="flex row">
        <information-leaflet-card
          :informationleaflet="informationleaflet"
          v-for="informationleaflet in informationleaflets"
          :key="informationleaflet.id"
        />
      </div>
    </div>
    <paginate :items="pagedInformation" @navigate-to="navigateTo"></paginate>
  </div>
</template>

<script>
import { mapState } from "vuex";
import informationLeafletCard from "@/components/information-leaflet/card";
import informationLeafletSearch from "@/components/information-leaflet/search";
export default {
  name: "informationleaflet-edit",
  components: {
    informationLeafletCard,
    informationLeafletSearch,
  },
  data() {
    return {
      searchText: "",
      currentPage: 1,
    };
  },
  computed: {
    ...mapState({
      informationleaflets: (state) => state.informationleaflet.all,
      total: (state) => state.informationleaflet.total,
      loading: (state) => state.informationleaflet.loading.all,
    }),
    pagedInformation() {
      return {
        items: this.informationleaflets,
        count: this.total,
        page: 1,
        pageSize: 20,
      };
    },
    requestParams() {
      const val = { page: this.currentPage };
      if (this.searchText !== "") {
        val.filter = this.searchText;
      }

      return val;
    },
  },
  created() {
    this.loadPage();
  },
  methods: {
    search(text) {
      this.searchText = text;
      this.$store.dispatch("informationleaflet/getPage", this.requestParams);
    },
    navigateTo(page) {
      this.currentPage = page;
      this.loadPage();
    },
    loadPage() {
      this.$store.dispatch("informationleaflet/getPage", this.requestParams);
    },
  },
};
</script>

<style lang='scss' scoped>
.top-actions {
  text-align: right;

  .btn {
    flex: 0;
  }
}
</style>