import HTTP from '@/api/http'

export default {
  async getAll() {
    const response = await HTTP.get(`/patients`)
    return response
  },
  async get(id) {
    const response = await HTTP.get(`/patients/${id}`)
    return response
  },
  async create(data) {
    const response = await HTTP.post(`/patients`, {patient: data})
    return response
  },
  async update(id, data) {
    const response = await HTTP.put(`/patients/${id}`, {patient: data})
    return response
  },
  async delete(id) {
    const response = await HTTP.delete(`/patients/${id}`)
    return response
  }
}