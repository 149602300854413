import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.config.devtools = true

require('@/assets/scss/override.scss')

// import birthDatepicker from 'vue-birth-datepicker'
// require('vue-birth-datepicker/dist/vueBirthDatepicker.css')
require('vue-wysiwyg/dist/vueWysiwyg.css')
require('@/assets/css/ck-content.css')

require('@/../public/global_assets/css/icons/fontawesome/styles.min.css')

require('@/components/vnode')

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faCheckCircle,
  faTimesCircle,
  faTrash,
  faPlus,
  faPen
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faTimesCircle,
  faCheckCircle,
  faUser,
  faTrash,
  faTrash,
  faPen,
  faPlus
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VueSignaturePad from 'vue-signature-pad'
Vue.use(VueSignaturePad)

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c3931e4963994583b348b2b097f6bd4d@sentry.io/4904863',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    beforeSend: (event, hint) => {
      console.error(hint.originalException || hint.syntheticException)
      return event
    }
  })
} else {
  console.warn('Sentry not initialized as not in production')
}

import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
Vue.use(VuejsDialog, {
  animation: 'fade'
})

import searchTerm from '@/components/search-term'
Vue.component('search-term', searchTerm)

import card from '@/components/card'
Vue.component('card', card)

import categoryPicker from '@/components/category-picker'
Vue.component('category-picker', categoryPicker)

import paginate from '@/components/paginate'
Vue.component('paginate', paginate)

import Switches from 'vue-switches'
Vue.component('vue-switch', Switches)

import title from '@/components/title'
Vue.component('page-title', title)

import panel from '@/components/panel'
Vue.component('panel', panel)

import metric from '@/components/metric'
Vue.component('metric', metric)

import htmlEditor from '@/components/html-editor'
Vue.component('html-editor', htmlEditor)

import htmlContent from '@/components/html-content'
Vue.component('html-content', htmlContent)

import authorizedLink from '@/components/authorized-link'
Vue.component('authorized-link', authorizedLink)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
require('vue-select/dist/vue-select.css')

import Gravatar from 'vue-gravatar';
Vue.component('v-gravatar', Gravatar);

import vueStarRating from 'vue-star-rating'
Vue.component('vue-star-rating', vueStarRating)

import wysiwyg from 'vue-wysiwyg'
Vue.use(wysiwyg, {}) // config is optional. more below

import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use(CKEditor)

import badge from '@/components/badge'
Vue.component('badge', badge)

import cropUpload from 'vue-image-crop-upload'
Vue.component('upload-image', cropUpload)

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
Vue.component('vue-phone-number-input', VuePhoneNumberInput)

import spinner from '@/components/spinner'
Vue.component('spinner', spinner)

import deleteButton from '@/components/delete-button'
Vue.component('delete-button', deleteButton)

import singleClickButton from '@/components/single-click-button'
Vue.component('single-click-button', singleClickButton)

import VCalendar from 'v-calendar'
Vue.use(VCalendar)

import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {
  name: 'timeago', // Component name, `Timeago` by default
  locale: 'en' // Default locale
})

import scriptjs from 'scriptjs'
Object.defineProperty(Vue.prototype, '$script', { value: scriptjs })

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

import authorization from '@/lib/authorization'
Vue.directive('can', function (el, bindings, vnode) {
  const behaviour = bindings.modifiers.disable ? 'disable' : 'hide'
  const ok = authorization.isValid(bindings.value, bindings.arg)
  if (!ok) {
    if (behaviour === 'hide') {
      const comment = document.createComment(' ')

      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined
      })

      vnode.text = ' '
      vnode.elm = comment
      vnode.isComment = true
      vnode.context = undefined
      vnode.tag = undefined
      vnode.data.directives = undefined

      if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment
      }

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el)
      }
    } else if (behaviour === 'disable') {
      el.disabled = true
    }
  }
})

var Oembed = require('oembed-all')
Vue.directive('oembed', {
  inserted: function (el) {
    const items = el.querySelectorAll('oembed')
    for (let item of items) {
      item.setAttribute('href', item.getAttribute('url'))
    }
    new Oembed(el.querySelectorAll('oembed'))
  }
})

Vue.filter('titleize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value
    .toLowerCase()
    .split(/[\s_-]/)
    .map(function (w) {
      return w.charAt(0).toUpperCase() + w.slice(1)
    })
    .join(' ')
})

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  value = value.toString()
  const moment = Vue.moment(value)
  return moment.format('Do MMMM YYYY')
})
Vue.filter('formatDateTime', function (value) {
  if (!value) return ''
  value = value.toString()
  const moment = Vue.moment(value)
  return moment.format('MMMM Do YYYY, h:mm:ss a')
})
Vue.filter('timeInWords', function (value) {
  if (!value) return ''
  value = value.toString()
  const moment = Vue.moment(value)
  if (moment.isBefore(new Date())) {
    return moment.fromNow(true) + ' ago'
  } else {
    return 'in ' + moment.toNow(true)
  }
})
Vue.filter('formatBoolean', function (value) {
  return value ? 'Yes' : 'No'
})
Vue.filter("uppercase", function(value) {return value.toUpperCase()}) 

Vue.use(require('vue-moment'))

Vue.filter('simpleDateTime', function (value) {
  if (!value) return ''
  value = value.toString()
  const moment = Vue.moment(value)

  if (moment.isSame(new Date(), 'day')) {
    return moment.format('HH:mm:ss')
  } else {
    return moment.format('DD/MM HH:mm')
  }

  return moment.fromNow()
})

Vue.filter('shortDateTime', function (value) {
  if (!value) return ''
  value = value.toString()
  const moment = Vue.moment(value)

  if (moment.isSame(new Date(), 'day')) {
    return moment.format('h:mm a')
  } else {
    return moment.format('MMM D')
  }

  return moment.fromNow()
})

Vue.filter('secondsToHuman', function (value) {
  let hours = parseInt(Math.floor(value / 360))
  let minutes = parseInt(Math.floor((value - hours * 360) / 60))
  let seconds = parseInt((value - (hours * 360 + minutes * 60)) % 60)

  let dHours = hours > 9 ? hours : '0' + hours
  let dMins = minutes > 9 ? minutes : '0' + minutes
  let dSecs = seconds > 9 ? seconds : '0' + seconds

  return dHours + ':' + dMins + ':' + dSecs
})

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
