<template>
  <div class="card">
    <form class="wizard-form steps-enable-all wizard clearfix" action="#" data-fouc role="application" id="steps-uid-4" style>
      <div class="steps clearfix">
        <ul role="tablist">
          <li v-for="(title, index) in titles" :key="title" role="tab" aria-disabled="false" :class="{first: index==0, done: done[index], current: selectedIndex==index}" :aria-selected="selectedIndex==index">
            <a @click="select(index)" href="#">
              <span v-if="selectedIndex == index" class="current-info audible">current step:</span>
              <span class="number">{{index + 1}}</span>
              {{title}}
            </a>
          </li>
        </ul>
      </div>
      <div class="content clearfix">
        <h6 class="title">{{selectedTitle}}</h6>
        <fieldset class="body current" aria-hidden="true">
          <vnode :node="selectedTab" />
        </fieldset>
      </div>
      <div class="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li class aria-disabled="false" v-if="selectedIndex > 0">
            <a @click="move(-1)"  class="btn btn-light" role="menuitem">
              <i class="icon-arrow-left13 mr-2"></i> Previous
            </a>
          </li>
          <li aria-hidden="false" v-if="selectedIndex < tabCount-1" aria-disabled="false">
            <a @click="move(1)" class="btn btn-primary" role="menuitem">
              Next
              <i class="icon-arrow-right14 ml-2"></i>
            </a>
          </li>
          <li aria-hidden="true" v-if="selectedIndex == tabCount - 1">
            <button @click.prevent="" @click="save" href="#finish" class="btn btn-primary" :disabled="!complete" role="menuitem">
              Submit form
              <i class="icon-arrow-right14 ml-2"></i>
            </button>
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "tabbed-form",
  props: {
    done: {
      type: Array,
      default: function() {return []}
    }
  },
  data() {
    return {
      selectedIndex: 0
    };
  },
  computed: {
    complete() {
      return this.done.filter(d => d).length == this.done.length  
    },
    selectedTab() {
      return this.$slots.tabs[this.selectedIndex];
    },
    selectedTitle() {
      return this.titles[this.selectedIndex]
    },
    titles() {
      return this.$slots.tabs.map(tab => tab.data.attrs.title);
    },
    tabCount() {
      return this.$slots.tabs.length
    }
  },
  methods: {
    select(index) {
      this.selectedIndex = index;
    },
    move(by) {
      this.selectedIndex = this.selectedIndex + by
    },
    save() {
      this.$emit("submitted")
    }
  }
};
</script>