<template>
  <div class="col-md-6 col-lg-4 col-xl-3">
    <card :title="informationleaflet.title">
      <template v-slot:header>
        <div class="media">
          <div class="mr-3">
            <i :class="iconClass" />
          </div>

          <div class="media-body">
            <h6 class="mb-0">
              <authorized-link :to="{name: 'informationleaflet', params: {id: informationleaflet.id}}" class="font-weight-semibold text-default d-block">{{informationleaflet.title}}</authorized-link>
            </h6>
            <span class="text-muted">{{informationleaflet.category | titleize}}</span>
          </div>
        </div>
      </template>
      {{informationleaflet.short_description}}
      <template v-slot:footer>
        <ul class="list-inline mb-0">
          <!-- <li class="list-inline-item"><a href="#">3 reports</a></li>
          <li class="list-inline-item"><a href="#">7 files</a></li>-->
        </ul>

        <ul class="list-inline mb-0 mt-1 mt-sm-0">
          <li class="list-inline-item">
            <authorized-link :to="{name: 'informationleaflet', params: {id: informationleaflet.id}}">Show</authorized-link>
          </li>
          <li class="list-inline-item">
            <authorized-link :to="{name: 'edit-informationleaflet', params: {id: informationleaflet.id}}">Edit</authorized-link>
          </li>
        </ul>
      </template>
    </card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "information-leaflet-card",
  props: {
    informationleaflet: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      iconClass: (state) => state.application.settings.information_leaflet.icon,
    }),
  },
  methods: {
    clicked() {
      this.$emit("selected");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: calc(100% - 1.25rem);
}
</style>