<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <h6 class="card-title">Messages</h6>
    </div>

    <div class="media card-body flex-column flex-md-row m-0">
      <div class="media-body">
        <div class="table-responsive">
          <p v-if="noMessages">There are no messages yet for this consent</p>
          <table v-else class="table table-inbox">
            <tbody data-link="row" class="rowlink">
              <inbox-message :showSender="true" :showProcedure="false" v-for="message in messages" :key="`message-${message.id}`" :message="message" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InboxMessage from "@/components/messages/inbox-message";
export default {
  name: "patient",
  components: {
    InboxMessage
  },
  props: {
    consent: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      messages: state => state.consent.messages
    }),
    noMessages() {
      return this.messages.length == 0;
    }
  },
  mounted() {
    this.$store.dispatch("consent/getMessages", this.consent.id);
  }
};
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
  .avatar i {
    font-size: 64px;
  }
}
</style>