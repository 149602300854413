<template>
  <table class="table table-striped">
    <consent-row v-for="consent in consents" :consent="consent" :key="consent.id" />
  </table>
</template>

<script>
import consentRow from "@/components/consent/row"
export default {
  name: "consent-table",
  props:{
    consents: {
      type: Array,
      default: function() {return []}
    }
  },
  components: {
    consentRow
  }
}
</script>