<template>
  <div class="search-term">
    <input type="text" class="form-control" @keyup="updateText" :placeholder="placeholder" />
    <button v-if="!hideButton" class="btn btn-primary">Search</button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Search"
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateText(e) {
      console.log("emit-text");
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-term {
  display: flex;
  margin: 0 10px;

  button {
    margin: 0 0 0 5px;
  }
  input {
    font-size: 12px;
    height: 36px;
    color: #999;
    padding: 5px;
    width: 200px;
  }
}
</style>