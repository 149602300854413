import HTTP from '@/api/http'

export default {
  async getAll (filter) {
    let url = '/users'

    if (filter !== undefined) {
      url = `${url}?${Object.keys(filter)
        .map(function (k) {
          return `${k}=${filter[k]}`
        })
        .join('&')}`
    }

    const response = await HTTP.get(url)
    return response
  },
  async get (id) {
    const response = await HTTP.get(`/users/${id}`)
    return response
  },
  async create (data) {
    const response = await HTTP.post(`/users`, { user: data })
    return response
  },
  async update (id, data) {
    const response = await HTTP.put(`/users/${id}`, { user: data })
    return response
  },
  async delete (id) {
    const response = await HTTP.delete(`/users/${id}`)
    return response
  },
  async requestPasswordReset (payload) {
    const response = await HTTP.post("/users/request-password-reset-link", {user: payload})
    return response
  },
  async resetPassword (password, passwordConfirmation, token) {
    const response = await HTTP.put("/users/password", {user: {reset_password_token: token, password: password, password_confirmation: passwordConfirmation}})
    return response
  }
}
