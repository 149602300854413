<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <div class="form-group">
        <label for="title">Title</label>
        <input type="text" class="form-control" id="title" v-model="informationleaflet.title" />
      </div>
      <div class="form-group">
        <label for="body">Contents</label>
        <html-editor
          id="body"
          v-model="informationleaflet.body"
          :images="true"
          :media="true"
          :imageUploadUrl="uploadUrl"
        />
      </div>
      <div>
        <a href="#" class="btn btn-primary" @click="save">Save Changes</a>
        <authorized-link :to="{name: 'informationleaflets'}">Back</authorized-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "informationleaflet-edit",
  computed: {
    ...mapState({
      informationleaflet: state => state.informationleaflet.current,
      loading: state => state.informationleaflet.loading.current
    }),
    uploadUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/assets`;
    }
  },
  methods: {
    save() {
      const data = this.informationleaflet;
      if (this.informationleaflet.id !== undefined) {
        const id = this.informationleaflet.id;
        console.log("updating ", this.informationleaflet);
        this.$store.dispatch("informationleaflet/update", { id, data }).then(
          function(response) {
            this.$router.push({
              name: "informationleaflet",
              params: { id: response.id }
            });
          }.bind(this)
        );
      } else {
        console.log("updating ", this.informationleaflet);
        this.$store
          .dispatch("informationleaflet/create", this.informationleaflet)
          .then(
            function(response) {
              this.$router.push({
                name: "informationleaflet",
                params: { id: response.id }
              });
            }.bind(this)
          );
      }
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("informationleaflet/get", this.$route.params.id);
    } else {
      this.$store.dispatch(
        "informationleaflet/clearCurrent",
        this.$route.params.id
      );
    }
  }
};
</script>

<style lang='scss' scoped>
</style>