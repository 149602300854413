<template>
  <div class="d-md-flex align-items-md-start">
    <div class="flex-fill overflow-auto">
      <!-- Single line -->
      <div class="card">
        <div class="card-header bg-transparent header-elements-inline">
          <h6 class="card-title">Inbox</h6>

          <div class="header-elements">
            <span class="badge bg-blue">{{numberToday}} new today</span>
          </div>
        </div>
        <div class="table-responsive" v-if="messages.length > 0">
          <table class="table table-inbox">
            <tbody data-link="row" class="rowlink">
              <inbox-message v-for="message in messages" :key="`message-${message.id}`" :message="message" />
            </tbody>
          </table>
        </div>
        <div v-else class="no-messages">
          <p>You have no messages in your inbox</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InboxMessage from "@/components/messages/inbox-message";
export default {
  name: "inbox",
  components: {
    InboxMessage
  },
  computed: {
    ...mapState({
      messages: state => state.message.all,
      loading: state => state.messages.loading.all
    }),
    unreadCount() {
      return this.messages.filter(
        function(msg) {
          msg.read_at === null;
        }.bind(this)
      ).length;
    },
    numberToday() {
      if (this.messages) {
        return this.messages.filter(
          function(m) {
            return this.$moment().diff(this.$moment(m.created_at), "days") == 0;
          }.bind(this)
        ).length;
      } else {
        return 24;
      }
    }
  },
  created() {
    this.$store.dispatch("message/getAll");
  }
};
</script>

<style lang="scss" scoped>
.no-messages {
  margin: 30px auto;
  font-size: 1.5em;
}
</style>