<template>
  <div class="fragment-editor">
    <div v-if="editing" class="editor">
      <!-- <div class="form-group">
        <label for="section">Section</label>
        <i class="fa fa-information" />
        <input class="form-control" v-model="section" id="section" />
      </div>-->
      <div class="form-group">
        <!-- <label for="content">Content</label> -->
        <html-editor
          v-model="content"
          id="content"
          :images="true"
          :media="true"
          :imageUploadUrl="uploadUrl"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <a class="btn btn-primary" @click="save">Save Changes</a>
        </div>
        <div class="col-md-6 text-right">
          <a class="btn btn-danger" @click="del">Delete</a>
          <a class="btn btn-danger" @click="revert">Revert</a>
          <a class="btn btn-secondary" @click="stopEditing">Cancel</a>
        </div>
      </div>
    </div>
    <div v-else @click="startEditing" class="view">
      <div v-if="content == ''" class="placeholder">No Content Yet</div>
      <html-content v-else :html="content" />
    </div>
  </div>
</template>

<script>
import paragraphEditor from "@/components/procedure/paragraph-editor";

export default {
  name: "fragment-editor",
  props: {
    fragment: {
      type: Object,
      required: true,
    },
    updateFragment: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    paragraphEditor,
  },
  data() {
    return {
      content: "",
      section: "",
      editing: false,
    };
  },
  computed: {
    uploadUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/assets`;
    },
    fragmentContent() {
      return this.fragment.content;
    },
    fragmentSection() {
      return this.fragment.section;
    },
  },
  methods: {
    startEditing() {
      if (this.readOnly) {
        return;
      }
      this.editing = true;
    },
    stopEditing() {
      this.editing = false;
    },
    save() {
      const procedureId = this.fragment.procedure_id;
      const id = this.fragment.id;
      const data = { content: this.content, section: this.section };
      if (this.updateFragment) {
        if (this.fragment.id !== undefined) {
          this.$store
            .dispatch("fragment/update", { procedureId, id, data })
            .then(() => {
              this.$store.dispatch("fragment/getAll", procedureId);
            });
        } else {
          this.$store
            .dispatch("fragment/create", { procedureId, data })
            .then(() => {
              this.$store.dispatch("fragment/getAll", procedureId);
            });
        }
      } else {
        this.$store.dispatch(
          "fragment/updateFragmentInAll",
          Object.assign({}, this.fragment, data)
        );
      }
      this.stopEditing();
    },
    revert() {
      const procedureId = this.fragment.procedure_id;
      const id = this.fragment.id;
      this.$store
        .dispatch("fragment/revert", { procedureId, id })
        .then((fragment) => {
          this.content = fragment.content;
          this.section = fragment.section;
          this.$store.dispatch("fragment/getAll", procedureId).then(
            function () {
              this.stopEditing();
            }.bind(this)
          );
        });
    },
    del() {
      const procedureId = this.fragment.procedure_id;
      const id = this.fragment.id;
      this.$store.dispatch("fragment/delete", { procedureId, id }).then(() => {
        this.$store.dispatch("fragment/getAll", procedureId).then(
          function () {
            this.stopEditing();
          }.bind(this)
        );
      });
    },
  },
  watch: {
    fragmentContent() {
      this.content = this.fragment.content;
    },
    fragmentSection() {
      this.section = this.fragment.section;
    },
  },
  mounted() {
    this.content = this.fragment.content;
    this.section = this.fragment.section;
  },
};
</script>

<style lang="scss" scoped>
.fragment-editor {
  border-left: 3px solid;
  .editor {
    margin: 0;
    padding: 20px;
    margin: 20px 0;
  }
  .view {
    padding-left: 10px;
    margin: 10px 0px;

    .placeholder {
      min-height: 20x;
      padding: 20px 0;
      opacity: 0.6;
    }
  }
}

@media screen and (max-width: 1024px) {
  .btn {
    width: 100%;
  }
}
</style>