
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "home",
  created() {
    this.$store.dispatch("session/ping")
  }
};
</script>


<style lang="scss" scoped>
ul {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}
</style>