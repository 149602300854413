<template><div><h1>Fragment</h1>
<div class="spinner" v-if='loading'>Loading Fragments</div>
<div v-else>
<table>
<tr>
<th>Document Name</th>
<th>Section</th>
<th>Order</th>
<th>Content</th>
<th>Procedure</th>
<th>Created At</th>
<th>Updated At</th>
<th></th>
</tr>
<tr v-for='fragment in fragments' :key='fragment.id'>
<td>{{fragment.document_name}}</td>
<td>{{fragment.section}}</td>
<td>{{fragment.order}}</td>
<td>{{fragment.content}}</td>
<td>{{fragment.procedure_id}}</td>
<td>{{fragment.created_at}}</td>
<td>{{fragment.updated_at}}</td>
<td><authorized-link :to="{name: 'fragment', params: {id: fragment.id}}">Show</authorized-link> | 
<authorized-link :to="{name: 'edit-fragment', params: {id: fragment.id}}">Edit</authorized-link></td>
</tr>
</table>
<authorized-link :to="{name: 'new-fragment'}">Create a new Fragment</authorized-link>
</div>
</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
name: 'fragment-edit',
computed:{
...mapState({
fragments: state => state.fragment.all,
loading: state => state.fragment.loading.all
}),
},
created() {
this.$store.dispatch('fragment/getAll', this.$route.params.procedureId)
}
};
</script>

<style lang='scss' scoped>
</style>