<template>
  <div>
    <div v-if="procedureId == undefined" class="warning">
      <i class="icon-warning22" />Please select a procedure first.
    </div>
    <card v-else title="Content Editor">
      <ul class="nav nav-tabs">
        <li v-for="document in documentsAvailable" :key="document" class="nav-item">
          <a href="#basic-tab1" class="nav-link" :class="{active: selectedTab==document}" @click="selectedTab = document">{{document | titleize}}</a>
        </li>
      </ul>
      <div>
        <html-editor v-if="documents[selectedTab] !== undefined" :key="`${selectedTab}-editor`" v-model="documents[selectedTab]" />
      </div>
    </card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import fragmentEditor from "@/components/procedure/fragment-editor.vue";
export default {
  name: "consent-content-editor",
  props: {
    procedureId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedTab: "",
      documents: {}
    };
  },
  components: {
    fragmentEditor
  },
  computed: {
    ...mapState({
      fragments: state => state.fragment.all
    }),
    documentsAvailable() {
      return Object.keys(this.documents);
    },
    documentFragments() {
      return this.fragments.filter(
        fragment => fragment.document_name == this.selectedTab
      );
    },
    documentJson() {
      return JSON.stringify(this.documents);
    }
  },
  watch: {
    documentJson() {
      this.$emit("updated", this.documents);
    },
    deep: true
  },
  mounted() {
    this.$store.dispatch("fragment/getAll", this.procedureId).then(
      function(fragments) {
        this.documents = {};
        this.fragments.map(
          function(fragment) {
            if (this.documents[fragment.document_name] == undefined) {
              this.$set(this.documents, fragment.document_name, "");
            }
            this.$set(
              this.documents,
              fragment.document_name,
              this.documents[fragment.document_name] + fragment.content + "\n\n"
            );
          }.bind(this)
        );
        this.selectedTab = Object.keys(this.documents)[0];
      }.bind(this)
    );
  }
};
</script>

<style lang="scss" scoped>
.warning {
  font-size: 20px;
  text-align: center;
  margin: 50px;

  i {
    margin-right: 10px;
    margin-left: 30px;
    color: #d82a2a;
    // font-size: 1.5em;
  }
}
</style>