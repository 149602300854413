import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  messages: [],
  all: [],
  consents: {
    all: [],
    upcoming: [],
    overdue: [],
    order: {
      field: 'scheduled_at',
      direction: 'asc'
    },
    filter: undefined,
    search: undefined
  },
  loading: {
    current: false,
    all: false,
    upcoming: false,
    overdue: false,
    metrics: false
  },
  metrics: {
    live: undefined,
    consented_this_week: undefined,
    due_this_week: undefined,
    overdue: undefined
  },
  auditSummary: {}
}

const actions = {
  async getUpcoming ({ commit }) {
    commit('startLoading', 'upcoming')
    const result = await api.consent.getUpcoming()
    const consents = result.data

    commit('storeUpcoming', consents)
    commit('endLoading', 'upcoming')
  },
  async getOverdue ({ commit }) {
    commit('startLoading', 'overdue')
    const result = await api.consent.getOverdue()
    const consents = result.data

    commit('storeOverdue', consents)
    commit('endLoading', 'overdue')
  },
  async getMetrics ({ commit }) {
    commit('startLoading', 'metrics')
    const result = await api.consent.getMetrics()
    const metrics = result.data

    commit('storeMetrics', metrics)
    commit('endLoading', 'metrics')
  },
  async getAll ({ commit, state }) {
    commit('startLoading', 'all')
    console.log(state)
    const result = await api.consent.getAll(
      state.consents.filter,
      state.consents.order,
      state.consents.search
    )
    const responseData = result.data
    commit('storeConsents', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get ({ commit }, id) {
    commit('startLoading', 'current')
    const result = await api.consent.get(id)
    const responseData = result.data
    commit('storeConsent', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async getAuditSummary ({ commit }, id) {
    commit('startLoading', 'audit-summary')
    const result = await api.consent.getAuditSummary(id)
    const responseData = result.data
    commit('storeAuditSummary', responseData)
    commit('endLoading', 'audit-summary')
    return responseData
  },
  async create ({ commit }, data) {
    const result = await api.consent.create(data)
    const responseData = result.data
    commit('storeConsent', responseData)
    return responseData
  },
  async update ({ commit }, { id, data }) {
    commit('startLoading', 'current')
    const result = await api.consent.update(id, data)
    const responseData = result.data
    commit('storeConsent', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete ({ commit }, id) {
    const result = await api.consent.delete(id)
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  async send ({ commit }, id) {
    const result = await api.consent.sendToPatient(id)
    return result
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  },
  async getMessages ({ commit }, id) {
    const result = await api.consent.getMessages(id)
    const responseData = result.data
    commit('storeConsentMessages', responseData)
    return responseData
  },
  setOrder ({ commit }, order) {
    commit('setOrder', order)
  },
  setFilter ({ commit }, filter) {
    commit('setFilter', filter)
  },
  setSearch ({ commit }, searchTerm) {
    commit('setSearch', searchTerm)
  }
}
const mutations = {
  storeUpcoming (state, consents) {
    state.consents.upcoming = consents
  },
  storeOverdue (state, consents) {
    state.consents.overdue = consents
  },
  storeMetrics (state, metrics) {
    state.metrics = metrics
  },
  storeConsent (state, consent) {
    state.current = consent
  },
  storeConsents (state, data) {
    state.all = data
  },
  storeAuditSummary (state, summary) {
    state.auditSummary = summary
  },
  clearCurrent (state) {
    state.current = []
    state.auditSummary = {}
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  },
  storeConsentMessages (state, messages) {
    state.messages = messages
  },
  setOrder (state, order) {
    state.consents.order = order
  },
  setFilter (state, filter) {
    state.consents.filter = filter
  },
  setSearch (state, searchTerm) {
    state.consents.search = searchTerm
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
