import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import resource from '@/store/modules/resource'
import patient from '@/store/modules/patient'
import consent from '@/store/modules/consent'
import account from '@/store/modules/account'
import accountuser from '@/store/modules/accountuser'
import procedure from '@/store/modules/procedure'
import asset from '@/store/modules/asset'
import permission from '@/store/modules/permission'
import user from '@/store/modules/user'
import groupuser from '@/store/modules/groupuser'
import location from '@/store/modules/location'
import group from '@/store/modules/group'
import session from '@/store/modules/session'
import sidebar from '@/store/modules/sidebar'
import message from '@/store/modules/message'
import application from '@/store/modules/application'
import category from '@/store/modules/category'
import fragment from '@/store/modules/fragment'
import informationleaflet from '@/store/modules/informationleaflet'
import informationpack from '@/store/modules/informationpack'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export default new Vuex.Store({
  modules: {
    resource,
    patient,
    consent,
    account,
    accountuser,
    procedure,
    asset,
    permission,
    user,
    groupuser,
    location,
    group,
    session,
    sidebar,
    message,
    application,
    category,
    fragment,
    informationleaflet,
    informationpack
  },
  plugins: [vuexLocal.plugin]

})
