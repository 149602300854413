import HTTP from '@/api/http'

export default {
  async getAll () {
    const response = await HTTP.get(`/messages`)
    return response
  },
  async get (id) {
    const response = await HTTP.get(`/messages/${id}`)
    return response
  },
  async create (data) {
    const response = await HTTP.post(`/messages`, { message: data })
    return response
  },
  async update (id, data) {
    const response = await HTTP.put(`/messages/${id}`, { message: data })
    return response
  },
  async delete (id) {
    const response = await HTTP.delete(`/messages/${id}`)
    return response
  },
    async getUnread () {
    const response = await HTTP.get(`/unread-messages`)
    return response
  }
}
