import store from '@/store'
let sessionTimer

const session = {
  isValid () {
    return (
      store.state.session.valid &&
      new Date(store.state.session.expires) > new Date()
    )
  },
  tokenExpiresAt () {
    return store.state.session.expires
  },
  startSessionTimer () {
    if (this.isValid()) {
      const expiresIn =
        (new Date(this.tokenExpiresAt()) - new Date()) / 1000 / 60
      if (expiresIn > 60) console.debug('Expires in', expiresIn / 60, 'hours')
      else {
        console.debug('Session expires in', expiresIn.toFixed(1), 'minutes')
      }

      let milliseconds = expiresIn * 60 * 1000 - 30000

      console.debug(
        'Refreshing session in',
        (milliseconds / 1000 / 60).toFixed(2),
        'minutes'
      )

      window.clearTimeout(sessionTimer)
      sessionTimer = window.setTimeout(function () {
        console.debug('Refreshing Session...')
        store.dispatch('session/refreshToken')
      }, milliseconds)
    }
  },
  clearSessionTimer () {
    console.debug('Clearing session timer')
    window.clearTimeout(sessionTimer)
  }
}

export default session
