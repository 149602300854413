
<template>
  <tr>
    <td>
      <authorized-link :to="{name: 'consent', params: {id: consentId }}">
        {{patientName}}
      </authorized-link>
    </td>
    <td>{{procedureTitle}}</td>
    <td>{{locationName}}</td>
    <td>
      <div v-if="showDate">{{consentRequiredBy | formatDate}}</div>
      <timeago
        v-else
        :datetime="consentRequiredBy"
      ></timeago>
    </td>
  </tr>
</template>

<script>
export default {
  name: "consent-row",
  props: {
    consent: {
      type: Object,
      default: function() { return {} }
    },
    showDate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    consentId() {
      if (this.consent) {
        return this.consent.id
      }
      return undefined
    },
    patientName() {
      if (this.consent) {
        if (this.consent.patient) {
          return `${this.consent.patient.first_name} ${this.consent.patient.last_name}`
        }
      }
      return undefined
    },
    procedureTitle() {
      return this.consent.procedure ? this.consent.procedure.title : "n/a"
    },
    locationName() {
      return this.consent.location ? this.consent.location.name : "n/a"
    },
    consentRequiredBy() {
      return this.consent ? this.consent.required_by : ""
    }
  }
};
</script>

<style lang="scss" scoped>

tr {
  // border-bottom: 1px solid black;

  &:nth-child(even) {
    background-color: #f2f2f2;
  }
}
</style>