<template>
  <div class="message">
    <div class="row">
      <div class="col-md-8">
        <div class="flex-fill overflow-auto">
          <!-- Single mail -->
          <div class="card">
            <!-- Action toolbar -->
            <div class="navbar navbar-light bg-light navbar-expand-lg border-bottom-0 py-lg-2 rounded-top">
              <div class="text-center d-lg-none w-100">
                <button type="button" class="navbar-toggler w-100 h-100" data-toggle="collapse" data-target="#inbox-toolbar-toggle-read">
                  <i class="icon-circle-down2"></i>
                </button>
              </div>

              <div class="navbar-collapse text-center text-lg-left flex-wrap collapse" id="inbox-toolbar-toggle-read">
                <div class="mt-3 mt-lg-0 mr-lg-3">
                  <div class="btn-group">
                    <authorized-link :to="{name: 'inbox'}" type="button" class="btn btn-light">
                      <i class="icon-mail5"></i>
                      <span class="d-none d-lg-inline-block ml-2">Back to Inbox</span>
                    </authorized-link>
                    <authorized-link :to="{name: 'create-message', params: {consentId: message.consent.id,in_reply_to: message.id}}" type="button" class="btn btn-light">
                      <i class="icon-reply"></i>
                      <span class="d-none d-lg-inline-block ml-2">Reply</span>
                    </authorized-link>
                    <authorized-link :to="{name: 'delete-message', params: {id: message.id}}" type="button" class="btn btn-light">
                      <i class="icon-bin"></i>
                      <span class="d-none d-lg-inline-block ml-2">Delete</span>
                    </authorized-link>
                  </div>
                </div>

                <div class="navbar-text ml-lg-auto">{{message.created_at | simpleDateTime}}</div>

                <div class="ml-lg-3 mb-3 mb-lg-0">
                  <div class="btn-group">
                    <authorized-link type="button" class="btn btn-light" :to="{name: 'print-email'}">
                      <i class="icon-printer"></i>
                      <span class="d-none d-lg-inline-block ml-2">Print</span>
                    </authorized-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- /action toolbar -->

            <!-- Mail details -->
            <div class="card-body">
              <div class="media flex-column flex-md-row">
                <a href="#" class="d-none d-md-block mr-md-3 mb-3 mb-md-0">
                  <span class="btn bg-teal-400 btn-icon btn-lg rounded-round">
                    <span class="letter-icon">A</span>
                  </span>
                </a>

                <div class="media-body">
                  <h6 class="mb-0">{{message.subject}}</h6>
                  <div class="letter-icon-title font-weight-semibold">{{message.sender}}</div>
                </div>

                <div class="align-self-md-center ml-md-3 mt-3 mt-md-0">
                  <ul class="list-inline list-inline-condensed mb-0">
                    <li class="list-inline-item">
                      <a href="#">
                        <img src="/global_assets/images/demo/users/face1.jpg" class="rounded-circle" width="32" height="32" alt />
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <img src="/global_assets/images/demo/users/face24.jpg" class="rounded-circle" width="32" height="32" alt />
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <img src="/global_assets/images/demo/users/face11.jpg" class="rounded-circle" width="32" height="32" alt />
                      </a>
                    </li>
                    <!-- <li class="list-inline-item">
                <span class="btn btn-sm bg-transparent border-slate-300 text-slate rounded-round border-dashed">+26</span>
                    </li>-->
                  </ul>
                </div>
              </div>
            </div>
            <!-- /mail details -->

            <!-- Mail container -->
            <div class="card-body">
              <div class="overflow-auto mw-100">{{message.body}}</div>
            </div>
            <!-- /mail container -->
          </div>
          <!-- /single mail -->
        </div>
      </div>
      <div class="col-md-4">
        <patient :patient="message.consent.patient" />
        <procedure-card :procedure="message.consent.procedure" :selectable="false"/>
        <audit-summary :consent="message.consent" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import patient from '@/components/patient'
import procedureCard from '@/components/procedure/procedure-card'
import auditSummary from '@/components/consent/audit-summary'
export default {
  name: "message",
  components: {
    patient, procedureCard, auditSummary
  },
  computed: {
    ...mapState({
      message: state => state.message.current
    }),
    messageId() {
      return this.$route.params.id;
    }
  },
  watch: {
    messageId() {
      this.$store
        .dispatch("message/get", this.messageId)
        .then(() => this.$store.dispatch("message/getUnread"));
    }
  },
  created() {
    this.$store
      .dispatch("message/get", this.messageId)
      .then(() => this.$store.dispatch("message/getUnread"));
  }
};
</script>