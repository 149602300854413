<template>
  <div>
    <h1>Information Packs</h1>
    <spinner v-if="loading" />
    <div v-else>
      <div class="search-bar">
        <search-term v-model="searchText" placeholder="Patient Number or Name" hide-button key=""/>
        <authorized-link class="btn btn-primary" :to="{name: 'new-informationpack'}">Create a new Pack</authorized-link>
      </div>
      <table class="table">
        <tr>
          <th>Patient</th>
          <th>Patient Number</th>
          <th>Medic</th>
          <th>Read And Understood</th>
          <th>Request Appointment</th>
          <th>Appointment Request Status</th>
          <th>Created</th>
          <th></th>
        </tr>
        <tr v-for="informationpack in filteredInformationPacks" :key="informationpack.id">
          <td>{{informationpack.patient.first_name}} {{informationpack.patient.last_name }}</td>
          <td>{{informationpack.patient.chi_number}}</td>
          <td>{{informationpack.user.first_name}} {{informationpack.user.last_name}}</td>
          <td>{{informationpack.read_and_understood ? "Yes" : "No"}}</td>
          <td>{{informationpack.request_appointment ? "Yes" : "No"}}</td>
          <td>
            <span v-if="informationpack.request_appointment" class="badge badge-warning">{{informationpack.appointment_request_status | uppercase}}</span>
          </td>
          <td>{{informationpack.created_at | formatDate}}</td>
          <td>
            <authorized-link class="btn btn-primary" :to="{name: 'informationpack', params: {id: informationpack.id}}">Show</authorized-link>
            <authorized-link class="btn btn-secondary" :to="{name: 'edit-informationpack', params: {id: informationpack.id}}">Edit</authorized-link>
          </td>
        </tr>
      </table>
      <authorized-link :to="{name: 'new-informationpack'}">Create a new Information Pack</authorized-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "informationpack-edit",
  data() {
    return {
      searchText: ""
    }
  },
  computed: {
    ...mapState({
      informationpacks: (state) => state.informationpack.all,
      loading: (state) => state.informationpack.loading.all,
    }),
    filteredInformationPacks() {
      if (this.searchText.length > 0) {
        return this.informationpacks.filter(p => p.patient.first_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || p.patient.last_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || p.patient.chi_number.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 )
      } else {
        return this.informationpacks
      }
    }
  },
  created() {
    this.$store.dispatch("informationpack/getAll");
  },
};
</script>

<style lang='scss' scoped>
  table.table {
    td, th {
      padding: 0;
    }
  }
  .search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 10px 20px 10px;
}
</style>