<template><div>
<div v-if='loading' class="spinner">Loading Fragment</div>
<div v-else>
<table>
<tr>
<th>Document Name</th><td>{{fragment.document_name}}</td>
</tr>
<tr>
<th>Section</th><td>{{fragment.section}}</td>
</tr>
<tr>
<th>Order</th><td>{{fragment.order}}</td>
</tr>
<tr>
<th>Content</th><td>{{fragment.content}}</td>
</tr>
<tr>
<th>Procedure</th><td>{{fragment.procedure_id}}</td>
</tr>
<tr>
<th>Created At</th><td>{{fragment.created_at}}</td>
</tr>
<tr>
<th>Updated At</th><td>{{fragment.updated_at}}</td>
</tr>
</table>
<div>
<authorized-link :to="{name: 'edit-fragment', params: {id: fragment.id}}">Edit</authorized-link>
<a href='#' @click='deleteRecord'>Delete</a>
</div>
</div>
</div></template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'fragment-show',
  computed:{
    ...mapState({
      fragment: state => state.fragment.current,
      loading: state => state.fragment.loading.current
    }),
  },
  created() {
    this.$store.dispatch('fragment/get', this.$route.params.id)
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch('fragment/delete', this.fragment.id).then(function(response) {this.$router.push({name: 'fragments'})}.bind(this))
    }
  }
};
</script>

<style lang='scss' scoped>
</style>