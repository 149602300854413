import axios from 'axios'
import router from '@/router'
import store from '@/store'

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT || 'http://localhost:3000'
})

HTTP.interceptors.request.use(
  function (config) {
    const token = store.state.session.token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'
    return config
  },
  function (err) {
    console.log(err)
    return Promise.reject(err)
  }
)

HTTP.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error == 'Error: Network Error') {
      if (router.currentRoute.name !== 'maintenance') {
        console.debug('network error - maintenance')
        router.push({ name: 'maintenance' })
      }
    } else if (error.response) {
      if (error.response.status === 401) {
        if (router.currentRoute.name !== 'sign-in') {
          console.debug('401... sending to sign in')
          router.push({ name: 'sign-in' })
        }
      }
    }
    return error
  }
)

export default HTTP
