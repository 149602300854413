<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <th>Title</th>
          <td>{{patient.title}}</td>
        </tr>
        <tr>
          <th>First Name</th>
          <td>{{patient.first_name}}</td>
        </tr>
        <tr>
          <th>Last Name</th>
          <td>{{patient.last_name}}</td>
        </tr>
        <tr>
          <th>Date Of Birth</th>
          <td>{{patient.date_of_birth}}</td>
        </tr>
      </table>
      <div>
        <!-- <authorized-link class="btn btn-primary" :to="{name: 'edit-patient', params: {id: patient.id}}">Edit</authorized-link> -->
        <!-- <delete-button @delete="deleteRecord">Delete</delete-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "patient-show",
  computed: {
    ...mapState({
      patient: state => state.patient.current,
      loading: state => state.patient.loading.current
    })
  },
  created() {
    this.$store.dispatch("patient/get", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch("patient/delete", this.patient.id);
    }
  }
};
</script>

<style lang='scss' scoped>
</style>