<template>
  <div class="row">
    <div class="col-lg-8">
      <procedure :procedure="consent.procedure" :documents="consent.custom_procedure" />
      <confirmations :consent="consent" />
    </div>
    <div class="col-lg-4">
      <div class="row">
        <consent-status :consent="consent" />
        <audit-summary :consent="consent" />
        <user :user="consent.user" />
        <patient :patient="consent.patient" />
        <consent-messages :consent="consent" />
        <location :location="consent.location" />
        <audit-log :max="10" :source="consent" v-if="consent.audit_entries" />
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/components/user";
import location from "@/components/location";
import patient from "@/components/patient";
import procedure from "@/components/procedure";
import auditLog from "@/components/audit-log";
import auditSummary from "@/components/consent/audit-summary";
import consentMessages from "@/components/consent/messages";
import consentStatus from "@/components/consent/consent-status";
import confirmations from '@/components/consent/confirmations'
export default {
  name: "consent",
  components: {
    user,
    location,
    patient,
    procedure,
    auditLog,
    auditSummary,
    consentMessages,
    consentStatus,
    confirmations
  },
  props: {
    consent: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
}
</style>