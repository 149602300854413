<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <div v-if="selectTemplate">
        <h2>Select a Template</h2>
        <div class="row">
          <div class="col-md-3">
            <procedure-card
              :procedure="{ title: 'Blank' }"
              @selected="template = {}"
            />
          </div>

          <div
            class="col-md-3"
            v-for="procedure in templates.items"
            :key="procedure.id"
          >
            <procedure-card
              :procedure="procedure"
              @selected="setTemplate(procedure)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-6">
            <card title="Details">
              <div class="form-group">
                <label for="title">Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  v-model="localProcedure.title"
                />
              </div>
              <div class="form-group">
                <label for="title">Group</label>
                <input
                  type="text"
                  class="form-control"
                  id="group"
                  v-model="localProcedure.category"
                />
              </div>
              <div class="form-group">
                <label for="title">Short Description</label>
                <input
                  type="text"
                  class="form-control"
                  id="short_description"
                  v-model="localProcedure.short_description"
                />
              </div>
              <div class="form-group">
                <label for="title">Template</label>
                <vue-switch
                  v-model="localProcedure.is_template"
                  text-enabled="Yes"
                  text-disabled="No"
                  theme="bootstrap"
                  color="primary"
                />
              </div>
              <div class="form-group">
                <label for="procedure_id">Information Leaflets</label>
                <v-select
                  :options="informationleaflets"
                  label="title"
                  id="leaflets"
                  v-model="localProcedure.information_leaflet_ids"
                  multiple
                  taggable
                  :reduce="(option) => option.id"
                />
              </div>
            </card>
          </div>
          <div class="col-md-6">
            <card title="Categories">
              <div v-if="selectedCategories.length == 0">
                No categories associated yet
              </div>
              <div v-else>
                <ul>
                  <li v-for="category in selectedCategories" :key="category.id">
                    {{ category.name }}
                  </li>
                </ul>
              </div>

              <category-picker v-model="localCategories" />
            </card>
          </div>
        </div>

        <card title="Content">
          <div v-if="this.procedure.id !== undefined">
            <ul class="nav nav-tabs nav-tabs-highlight">
              <li
                class="nav-item"
                v-for="tab in tabs"
                :key="tab.title"
                @click="selectTab(tab)"
              >
                <a
                  href="#highlight-tab1"
                  class="nav-link"
                  :class="{ active: tab.active }"
                  data-toggle="tab"
                  >{{ tab.key | titleize }}</a
                >
              </li>
            </ul>

            <div class="form-group">
              <div v-if="activeTab.key != 'patient_information'">
                <fragment-document-editor
                  :fragments="fragments"
                  :document="activeTab.key"
                  :procedureId="procedure.id"
                />
              </div>
            </div>
          </div>
          <div v-else>
            Please save your procedure details before adding content
          </div>
        </card>
        <a href="#" class="btn btn-primary" @click="save">Save Changes</a>
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from "@/assets/js/ckeditor";
import ProcedureCard from "@/components/procedure/procedure-card";
import fragmentDocumentEditor from "@/components/procedure/fragment-document-editor";

import { mapState } from "vuex";
export default {
  name: "procedure-edit",
  components: {
    ProcedureCard,
    fragmentDocumentEditor,
  },
  data() {
    return {
      localCategories: [],
      localProcedure: {},
      template: undefined,
      selectedTab: { key: "" },
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.session.token,
      procedure: (state) => state.procedure.current,
      loading: (state) => state.procedure.loading.current,
      templates: (state) => state.procedure.templates,
      selectedCategories: (state) => state.category.selected,
      fragments: (state) => state.fragment.all,
      informationleaflets: (state) => state.informationleaflet.all,
    }),
    uploadUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/assets?procedure_id=${this.procedure.id}`;
    },
    selectTemplate() {
      return this.procedure.id == undefined && this.template == undefined;
    },
    tabs() {
      return this.documentNames.map(
        function (name) {
          return { key: name, active: this.selectedTab.key == name };
        }.bind(this)
      );
    },
    activeTab() {
      return this.tabs.find((tab) => tab.active) || {};
    },
    documentNames() {
      const names = [
        ...new Set(this.fragments.map((item) => item.document_name)),
      ];
      return names;
    },
  },
  methods: {
    selectTab(selectedTab) {
      this.selectedTab = selectedTab;
    },
    setTemplate(template) {
      this.template = template;
      this.localProcedure.patient_information = template.patient_information;
      this.localProcedure.description = template.description;
      this.localProcedure.options = template.options;
      this.localProcedure.risks = template.risks;
    },
    save() {
      const data = this.localProcedure;
      delete data.categories;
      data.category_ids = this.selectedCategories.map((c) => c.id);
      if (this.localProcedure.id !== undefined) {
        const id = this.localProcedure.id;
        this.$store
          .dispatch("procedure/update", { id, data })
          .then(() => this.$store.dispatch("category/clearCurrent"));
      } else {
        this.$store
          .dispatch("procedure/create", this.localProcedure)
          .then(() => this.$store.dispatch("category/clearCurrent"));
      }
    },
  },
  created() {
    this.$store.dispatch("procedure/getTemplates");

    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("procedure/get", this.$route.params.id).then(
        function (procedure) {
          this.$store.dispatch("category/setSelected", procedure.categories);
        }.bind(this)
      );
      this.$store.dispatch("fragment/getAll", this.$route.params.id);
    } else {
      this.$store.dispatch("procedure/clearCurrent", this.$route.params.id);
      this.$store.dispatch("fragment/setAsDefault", this.$route.params.id);
    }
    this.$store.dispatch("informationleaflet/getAllTitles");

    // this.$store.dispatch("procedure/getCategoryRoots");
  },
  watch: {
    procedure() {
      this.localProcedure = this.procedure;
    },
    documentNames() {
      if (this.selectedTab["key"] == "") {
        this.selectedTab = {key: this.documentNames[0]};
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.vue-switcher {
  display: block;
  margin: 10px;
}
</style>