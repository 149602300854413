<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <div class="row">
        <div class="col-md-8">
          <user title="User" :user="user" />
          <card title="Details">
            <table class="table table-striped">
              <tr>
                <th>Enabled</th>
                <td>{{user.enabled}}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{user.email}}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{{userStatus}}</td>
              </tr>
              <tr v-if="!user.invitation_accepted">
                <th>Invitation Expiry</th>
                <td>{{user.invitation_due_at | timeInWords}}</td>
              </tr>
            </table>
          </card>
        </div>
        <div class="col-md-4">
          <card title="Categories" v-if="user.user_type == 'medic'">
            <div v-for="category in user.categories" :key="category.id">
              <p>
                <i :class="settings.category.icon" />
                <authorized-link :to="{name: 'category', params: {id: category.id}}">{{category.name}}</authorized-link>
              </p>
            </div>
          </card>
          <card title="Procedures" v-if="user.user_type == 'medic'">
            <div v-for="procedure in userProcedures" :key="procedure.id">
              <p>
                <i :class="settings.procedure.icon" />
                <authorized-link :to="{name: 'procedure', params: {id: procedure.id}}">{{procedure.title}}</authorized-link>
              </p>
            </div>
          </card>
          <card title="Locations" v-if="user.user_type == 'medic'">
            <div v-for="location in userLocations" :key="location.id">
              <p>
                <i :class="settings.location.icon" />
                <authorized-link :to="{name: 'location', params: {id: location.id}}">{{location.name}}</authorized-link>
              </p>
            </div>
          </card>
          <card title="Contact Details">
            <html-content :html="user.contact_details || '<p>No contact details added</p>'"/>
          </card>
        </div>
      </div>
      <div>
        <authorized-link :to="{name: 'edit-user', params: {id: user.id}}" class="btn btn-primary">Edit</authorized-link>
        <delete-button @delete="deleteRecord">Delete</delete-button>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/components/user";
import card from "@/components/card";
import { mapState } from "vuex";
export default {
  name: "user-show",
  components: {
    user,
    card
  },
  computed: {
    ...mapState({
      user: state => state.user.current,
      procedures: state => state.procedure.titles,
      locations: state => state.location.all,
      loading: state => state.user.loading.current,
      settings: state => state.application.settings
    }),
    userProcedures() {
      return this.user.procedure_ids.map(id =>
        this.procedures.find(p => p.id == id)
      );
    },
    userLocations() {
      return this.user.location_ids.map(id =>
        this.locations.find(p => p.id == id)
      );
    },
    userStatus() {
      if (this.user.enabled) {
        if (this.user.invitation_accepted) {
          return "Enabled";
        } else {
          return "Invited"
        }
      } else {
        return "Disabled";
      }
    }
  },
  created() {
    this.$store.dispatch("procedure/getTitles", this.$route.params.id);
    this.$store.dispatch("location/getAll", this.$route.params.id);
    this.$store.dispatch("user/get", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store
        .dispatch("user/delete", this.user.id)
        .then(() => this.$router.push({ name: "users" }));
    }
  }
};
</script>

<style lang='scss' scoped>
i {
  margin-right: 10px;
  margin-top: -1px;
}
</style>