<template>
  <div class="col-md-6 col-lg-4 col-xl-3">
    <card :title="leaflet.title">
      <template v-slot:header>
        <div class="media">
          <div class="mr-3">
            <i class="icon-info3" />
          </div>

          <div class="media-body">
            <h6 class="mb-0">
              {{leaflet.title}}
            </h6>
            <span class="text-muted">{{leaflet.category | titleize}}</span>
          </div>
        </div>
      </template>
      {{leaflet.short_description}}
    </card>
  </div>
</template>

<script>
export default {
  name: "information-leaflet",
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      leaflet: {}
    }
  },
  mounted() {
    this.$store.dispatch("informationleaflet/get", this.id).then((leaflet) => { this.leaflet = leaflet} )
  }
}
</script>

<style lang="scss" scoped>
  .card {height: calc(100% - 1.25rem);}
</style>