const state = {
  settings: {
    dashboard: {
      icon: 'icon-home4'
    },
    inbox: {
      icon: 'icon-mail5'
    },
    consent: { icon: 'icon-checkmark-circle2' },
    account: {
      icon: 'icon-portfolio'
    },
    patient: {
      icon: 'icon-accessibility'
    },
    procedure: {
      icon: 'icon-folder-plus2'
    },
    location: { icon: 'icon-location3' },
    user: { icon: 'icon-user' },
    group: { icon: 'icon-users4' },
    asset: { icon: 'icon-file-media' },
    category: { icon: 'icon-price-tag2' },
    information_leaflet: { icon: 'icon-info3' },
    information_pack: { icon: 'icon-stack' }
  }
}

const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
