<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <p>
        Use the form below to generate a new consent request for a patient who
        is going to undergo a procedure or surgery. Note that you can preview
        the consent request prior to sending to the patient.
      </p>
      <p>
        On final approval an email with a secure link will be sent to the user.
        A text will be sent to the user with a unique code. This combination of
        link and unique code are required to make their consent secure.
      </p>

      <div class="row">
        <div class="col-12">
          <form
            class="wizard-form steps-enable-all wizard clearfix"
            action="#"
            data-fouc
            role="application"
            id="steps-uid-4"
            style
          >
            <div class="steps clearfix">
              <ul role="tablist">
                <li
                  v-for="(title, index) in titles"
                  :key="title"
                  role="tab"
                  aria-disabled="false"
                  :class="{
                    first: index == 0,
                    done: tabStatus[index],
                    current: selectedIndex == index,
                  }"
                  :aria-selected="selectedIndex == index"
                >
                  <a @click="selectTab(index)" class="link">
                    <span
                      v-if="selectedIndex == index"
                      class="current-info audible"
                      >current step:</span
                    >
                    <span class="number">{{ index + 1 }}</span>
                    {{ title }}
                  </a>
                </li>
              </ul>
            </div>

            <div class="content clearfix" v-if="selectedIndex == 0">
              <h6 class="title">Patient Information</h6>
              <fieldset class="body current" aria-hidden="true">
                <div class="form-group">
                  <label for="patient_first_name" class="col-form-label"
                    >First Name</label
                  >
                  <input
                    type="text"
                    @change="inputChanged"
                    class="form-control"
                    id="patient_first_name"
                    v-model="localPatient.first_name"
                  />
                </div>
                <div class="form-group">
                  <label for="patient_last_name">Surname</label>
                  <input
                    type="text"
                    @change="inputChanged"
                    class="form-control"
                    id="patient_last_name"
                    v-model="localPatient.last_name"
                  />
                </div>
                <div class="form-group">
                  <label for="patient_last_name">CHI Number</label>
                  <!-- <patient-number @change="inputChanged" v-model="chi_number" @dob="setDateOfBirth" @gender="setGender" /> -->
                  <patient-number @change="inputChanged" v-model="chi_number" />
                </div>

                <div class="form-group">
                  <label for="date_of_birth">Date of Birth</label>
                  <birth-datepicker
                    :minYear="1890"
                    delimiter="/"
                    @change="inputChanged"
                    class="form-control"
                    v-model="dob"
                  />
                </div>
                <div class="form-group">
                  <label for="date_of_birth">Gender</label>
                  <v-select
                    @change="inputChanged"
                    :options="['Male', 'Female', 'Other']"
                    v-model="gender"
                  />
                </div>
                <div class="form-group">
                  <label for="procedure_id">Mobile Number</label>
                  <!-- <vue-phone-number-input id="patient_mobile" v-model="localPatient.mobile" /> -->
                  <input
                    @change="inputChanged"
                    class="form-control"
                    type="tel"
                    v-model="localPatient.mobile"
                  />
                </div>
                <div class="form-group">
                  <label for="procedure_id">Email Address</label>
                  <input
                    type="email"
                    @change="inputChanged"
                    class="form-control"
                    id="patient_email"
                    v-model="localPatient.email"
                  />
                </div>
              </fieldset>
            </div>

            <div class="content clearfix" v-if="selectedIndex == 1">
              <h6 class="title">Procedure Information</h6>
              <fieldset class="body current" aria-hidden="true">
                <div class="form-group">
                  <label for="procedure_id">Medic</label>
                  <v-select
                    @change="inputChanged"
                    :options="users"
                    :getOptionLabel="
                      (item) =>
                        [item.title, item.first_name, item.last_name].join(' ')
                    "
                    v-model="localConsent.user"
                    :reduce="(option) => option"
                  />
                </div>
                <div class="form-group">
                  <label for="procedure_id">Procedure</label>
                  <v-select
                    @change="inputChanged"
                    :options="userProcedures"
                    label="title"
                    id="consent_procedure_id"
                    v-model="localConsent.procedure_id"
                    :reduce="(option) => option.id"
                  />
                </div>
                <div class="form-group">
                  <label class="d-block font-weight-semibold"
                    >Side of Body</label
                  >
                  <div class="form-check form-check-inline">
                    <label class="">
                      <input
                        type="radio"
                        @change="inputChanged"
                        class=""
                        name="left"
                        value="left"
                        v-model="localConsent.side_of_body"
                      />
                      <div class="label-text">Left</div>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="">
                      <input
                        type="radio"
                        @change="inputChanged"
                        class=""
                        name="right"
                        value="right"
                        v-model="localConsent.side_of_body"
                      />
                      <div class="label-text">Right</div>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label
                    ><input
                      type="checkbox"
                      v-model="localConsent.covid_check_required"
                    />
                    <div class="label-text">Covid Check Required</div></label
                  >
                </div>
                <div class="form-group">
                  <label for="procedure_id">Location</label>
                  <v-select
                    :options="userLocations"
                    @change="inputChanged"
                    label="name"
                    id="consent_location_id"
                    v-model="localConsent.location_id"
                    :reduce="(option) => option.id"
                  />
                </div>
                <div class="form-group">
                  <label for="procedure_id">Scheduled For</label>
                  <v-date-picker
                    id="consent_location_id"
                    @change="inputChanged"
                    v-model="localConsent.scheduled_at"
                  />
                </div>
              </fieldset>
            </div>
            <div class="content clearfix" v-if="selectedIndex == 2">
              <h6 class="title">Contents</h6>
              <fieldset class="body current" aria-hidden="true">
                <consent-content-editor
                  :procedureId="localConsent.procedure_id"
                  @updated="documentsUpdated"
                />
              </fieldset>
            </div>

            <div class="content clearfix" v-if="selectedIndex == 3">
              <h6 class="title">Preview</h6>
              <fieldset class="body current" aria-hidden="true">
                <consent :consent="localPopulatedConsent" />
              </fieldset>
            </div>

            <div class="actions clearfix">
              <ul role="menu" aria-label="Pagination">
                <li class aria-disabled="false" v-if="selectedIndex > 0">
                  <a @click="move(-1)" class="btn btn-light" role="menuitem">
                    <i class="icon-arrow-left13 mr-2"></i> Previous
                  </a>
                </li>
                <li
                  aria-hidden="false"
                  v-if="selectedIndex < titles.length - 1"
                  aria-disabled="false"
                >
                  <a @click="move(1)" class="btn btn-primary" role="menuitem">
                    Next
                    <i class="icon-arrow-right14 ml-2"></i>
                  </a>
                </li>
                <li
                  aria-hidden="true"
                  v-if="selectedIndex == titles.length - 1"
                >
                  <button
                    @click.prevent
                    @click="save"
                    href="#finish"
                    class="btn btn-primary"
                    :disabled="!complete"
                    role="menuitem"
                  >
                    Save Consent
                    <i class="icon-arrow-right14 ml-2"></i>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import birthDatepicker from "vue-birth-datepicker/src/birth-datepicker";
import tabbedForm from "@/components/tabbed-form";
import consentContentEditor from "@/components/consent/consent-content-editor";
import consent from "@/components/consent/consent";
import patientNumber from "@/components/patient-number";

import router from "@/router";

export default {
  name: "consent-edit",
  components: {
    birthDatepicker,
    tabbedForm,
    consentContentEditor,
    consent,
    patientNumber,
  },
  data() {
    return {
      redraw: 1,
      dob: undefined,
      chi_number: undefined,
      gender: "",
      localPatient: {},
      localConsent: {
        user: {
          procedure_ids: [],
          location_ids: [],
        },
      },
      procedureParagraphs: [],
      optionParagraphs: [],
      riskParagraphs: [],
      chiNumberValidationMessage: "",
      titles: [
        "Patient Information",
        "Procedure Information",
        "Contents",
        // "Options",
        // "Risks",
        "Preview",
      ],
      selectedIndex: 0,
      modified: false,
    };
  },
  computed: {
    ...mapState({
      consent: (state) => state.consent.current,
      loading: (state) => state.consent.loading.current,
      procedures: (state) => state.procedure.titles,
      procedure: (state) => state.procedure.current,
      locations: (state) => state.location.all,
      users: (state) => state.user.all,
      user: (state) => state.user.current,
      location: (state) => state.location.current,
      currentUser: (state) => state.session.user,
      fragments: (state) => state.fragment.all,
    }),
    userProcedures() {
      console.log("lookup procedures for user", this.localConsent.user.id);
      if (this.localConsent.user && this.localConsent.user.procedure_ids) {
        if (this.localConsent.user.procedure_ids.length > 0) {
          return this.localConsent.user.procedure_ids.map(
            function (id) {
              return this.procedures.find((p) => p.id == id);
            }.bind(this)
          );
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    userLocations() {
      console.log("lookup locations for user", this.localConsent.user.id);
      if (this.localConsent.user && this.localConsent.user.location_ids) {
        if (this.localConsent.user.location_ids.length > 0) {
          return this.localConsent.user.location_ids.map((id) =>
            this.locations.find((p) => p.id == id)
          );
        } else {
          return this.locations;
        }
      } else {
        return [];
      }
    },
    procedureId() {
      return this.localConsent.procedure_id;
    },
    userId() {
      return this.localConsent.user ? this.localConsent.user.id : undefined;
    },
    locationId() {
      return this.localConsent.location_id;
    },
    procedureText() {
      return this.procedureParagraphs
        .filter((p) => p.selected)
        .map((p) => p.text)
        .join("\n\n");
    },
    optionText() {
      return this.optionParagraphs
        .filter((p) => p.selected)
        .map((p) => p.text)
        .join("\n\n");
    },
    riskText() {
      return this.riskParagraphs
        .filter((p) => p.selected)
        .map((p) => p.text)
        .join("\n\n");
    },
    isExistingConsent() {
      return this.consent.id !== undefined;
    },
    recordType() {
      return this.isExistingConsent ? "edit" : "new";
    },
    tabStatus() {
      return [
        this.localPatient.date_of_birth !== undefined &&
          this.localPatient.first_name &&
          this.localPatient.last_name &&
          this.localPatient.mobile &&
          this.localPatient.email,
        this.localConsent.user &&
          this.localConsent.procedure_id &&
          this.localConsent.location_id &&
          this.localConsent.side_of_body,
        this.fragments.filter((d) => d.document_name == "description").length >
          0,
        this.fragments.filter((d) => d.document_name == "options").length > 0,
        this.fragments.filter((d) => d.document_name == "risks").length > 0,
      ];
    },

    localPopulatedConsent() {
      return Object.assign({}, this.localConsent, {
        patient: this.localPatient,
        user: this.user,
        location: this.location,
        procedure: this.procedure,
      });
    },
    complete() {
      return this.tabStatus.filter((d) => d).length == this.tabStatus.length;
    },
  },
  methods: {
    inputChanged() {
      this.modified = true;
    },
    setDateOfBirth(date) {
      this.dob = date.valueOf();
    },
    setGender(gender) {
      this.gender = gender;
    },
    selectTab(index) {
      this.selectedIndex = index;
    },
    move(by) {
      this.selectedIndex = this.selectedIndex + by;
    },
    updateSelectedProcedures(data) {
      this.procedureParagraphs = data;
    },
    updateSelectedOptions(data) {
      this.optionParagraphs = data;
    },
    updateSelectedRisks(data) {
      this.riskParagraphs = data;
    },
    documentsUpdated(documents) {
      this.localConsent.custom_procedure = {};

      Object.keys(documents).map(
        function (name) {
          this.localConsent.custom_procedure[name] = documents[name];
        }.bind(this)
      );
    },
    save() {
      this.localConsent.user_id = this.localConsent.user.id;
      const data = this.consent;
      // if (this.isExistingConsent) {
      //   console.log("existing consent")
      //   const id = this.consent.id;
      //   console.log("updating")
      //   this.$store.dispatch("consent/update", { id, data }).then(function() {
      //     this.$notify({
      //       group: "success",
      //       type: "success",
      //       title: "Consent Updated",
      //       text: "Your consent has been successfully updated"
      //     });
      //     console.log("pushing")
      //     this.modified = false;
      //     this.$router.push({
      //       name: "consent",
      //       params: { id: this.consent.id }
      //     })
      //   }.bind(this)).catch(function(error) {
      //       console.log(error)
      //     });;
      // } else {
      const patientData = this.localPatient;

      patientData.date_of_birth = this.$moment(
        patientData.date_of_birth
      ).format();

      patientData.chi_number = this.chi_number;
      patientData.gender = this.gender;

      let patientPromise;

      if (this.localPatient.id !== undefined) {
        const id = patientData.id;
        patientPromise = this.$store.dispatch("patient/update", {
          id,
          patientData,
        });
      } else {
        patientPromise = this.$store.dispatch("patient/create", patientData);
      }

      patientPromise
        .then(
          function (patient) {
            this.localConsent.patient_id = patient.id;

            let action = this.isExistingConsent ? "update" : "create";

            const data = this.localConsent;
            const id = this.localConsent.id;
            if (this.isExistingConsent) {
              this.$store.dispatch(`consent/update`, { id, data }).then(
                function (consent) {
                  console.log("returned consent", consent);
                  this.$notify({
                    group: "success",
                    type: "success",
                    title: `Consent Updated`,
                    text: `Your consent has been successfully updated`,
                  });
                  this.modified = false;
                  console.log("pushing", {
                    name: "consent",
                    params: { id: consent.id },
                  });
                  this.$router.push({
                    name: "consent",
                    params: { id: consent.id },
                  });
                }.bind(this)
              );
            } else {
              this.$store.dispatch(`consent/create`, data).then(
                function (consent) {
                  this.$notify({
                    group: "success",
                    type: "success",
                    title: `Consent Created`,
                    text: `Your consent has been successfully created`,
                  });
                  this.modified = false;
                  this.$router.push({
                    name: "consent",
                    params: { id: consent.id },
                  });
                }.bind(this)
              );
            }
          }.bind(this)
        )
        .catch(
          function (error) {
            this.$notify({
              group: "error",
              type: "error",
              title: `Consent ${action}`,
              text: `There was a problem saving the patient data: ${error}`,
            });
          }.bind(this)
        );
      // }
    },
  },
  watch: {
    dob() {
      const date = this.$moment(this.dob);
      if (date.isValid()) {
        console.log("setting date to", date.format("YYYY/MM/DD"));
        this.localPatient.date_of_birth = date.format("YYYY/MM/DD");
      } else {
        console.log("invalid date:", this.dob);
      }
    },
    locationId() {
      this.$store.dispatch("location/get", this.localConsent.location_id);
    },
    procedureId() {
      this.$store
        .dispatch("procedure/get", this.localConsent.procedure_id)
        .then(
          function () {
            var procedureElement = document.createElement("html");
            procedureElement.innerHTML = this.procedure.description;
            var optionElement = document.createElement("html");
            optionElement.innerHTML = this.procedure.options;
            var riskElement = document.createElement("html");
            riskElement.innerHTML = this.procedure.risks;

            this.procedureParagraphs = Array.prototype.slice
              .call(procedureElement.getElementsByTagName("p"), 0)
              .map(function (t) {
                return { text: t.outerHTML, selected: true };
              });
            this.optionParagraphs = Array.prototype.slice
              .call(optionElement.getElementsByTagName("p"), 0)
              .map(function (t) {
                return { text: t.outerHTML, selected: true };
              });
            this.riskParagraphs = Array.prototype.slice
              .call(riskElement.getElementsByTagName("p"), 0)
              .map(function (t) {
                return { text: t.outerHTML, selected: true };
              });
          }.bind(this)
        );
      this.$store.dispatch("fragment/getAll", this.procedureId);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.modified) {
      this.$dialog
        .confirm(
          "You have unsaved changes, are you sure you want to discard them?",
          {
            okText: "Discard Changes",
            cancelText: "Close",
          }
        )
        .then(function () {
          next();
        })
        .catch(function () {
          next(false);
        });
    } else {
      next();
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("consent/get", this.$route.params.id).then(
        function (consent) {
          console.log("consent", consent);
          this.localConsent = consent;
          this.localPatient = consent.patient;

          this.gender = this.localPatient.gender;
          this.chi_number = this.localPatient.chi_number;
          if (consent.patient.date_of_birth) {
            this.dob = new Date(consent.patient.date_of_birth).getTime();
          }

          this.localConsent.procedure_id = this.localConsent.procedure.id;
          this.localConsent.location_id = this.localConsent.location.id;

          if (this.localConsent.scheduled_at) {
            this.localConsent.scheduled_at = new Date(
              this.localConsent.scheduled_at
            );
          }
        }.bind(this)
      );
    } else {
      this.$store.dispatch("consent/clearCurrent", this.$route.params.id);
      if (this.currentUser.user_type == "medic") {
        this.localConsent.user = this.currentUser;
      }
    }
    this.$store.dispatch("procedure/clearCurrent");
    this.$store.dispatch("procedure/getTitles");
    this.$store.dispatch("location/getAll");
    this.$store.dispatch("user/getAll", { user_type: "medic" });
  },
};
</script>

<style lang='scss' scoped>
textarea {
  min-height: 400px;
}

.link {
  cursor: pointer;
}

.steps {
  li {
    // opacity: 0.3;

    &.current {
      opacity: 1;
    }
  }
}

input[type="checkbox"], input[type="radio"] {
  height: 13px;
  line-height: 9px;
  vertical-align: middle;
}
.label-text {
  display: inline-block;
  line-height: 20px;
  vertical-align: middle;
  height: 18px;
  margin-left: 8px;
}
</style>