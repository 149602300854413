<template>
  <span>
    <div @click="setDirection('asc')" class="direction-arrow" :class="{selected: ascSelected}">
      <i class="fa fa-arrow-up" />
    </div>
    <div @click="setDirection('desc')" class="direction-arrow" :class="{selected: descSelected}">
      <i class="fa fa-arrow-down" />
    </div>
  </span>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "order-control",
  props: {
    field: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      order: state => state.consent.consents.order
    }),
    ascSelected() {
      return this.order.direction == "asc" && this.order.field == this.field;
    },
    descSelected() {
      return this.order.direction == "desc" && this.order.field == this.field;
    }
  },
  methods: {
    setDirection(direction) {
      this.$store.dispatch("consent/setOrder", {
        field: this.field,
        direction: direction
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.direction-arrow {
  line-height: 20px;
  display: inline;
  cursor: pointer;
  margin-left: 2px;

  i {
    font-size: 10px;
  }
}
.selected {
  font-weight: bold;
  color: #2196F3;
}
</style>