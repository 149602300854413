<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <slot v-if="hasHeaderSlot" name="header"></slot>
      <div v-else>
        <h3 class="card-title" v-if="title">{{title}}</h3>
        <div class="header-elements"></div>
      </div>
    </div>

    <div class="card-body">
      <slot />
    </div>

    <div v-if="hasFooterSlot" class="card-footer bg-white d-sm-flex justify-content-sm-between align-items-sm-center">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasHeaderSlot() {
      return !!this.$slots["header"];
    },
    hasFooterSlot() {
      return !!this.$slots["footer"];
    }
  }
};
</script>