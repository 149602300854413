
import api from '@/api'
import router from '@/router'

const state = {
  current: {},
  all: [],
  unread: [],
  loading: {
    current: false,
    all: false
  }
}

const actions = {
  async getAll ({ commit }) {
    commit('startLoading', 'all')
    const result = await api.message.getAll()
    const responseData = result.data
    commit('storeMessages', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async getUnread ({ commit }) {
    commit('startLoading', 'all')
    const result = await api.message.getUnread()
    const responseData = result.data
    commit('storeUnread', responseData)
    commit('endLoading', 'all')
    return responseData
  },
  async get ({ commit }, id) {
    commit('startLoading', 'current')
    const result = await api.message.get(id)
    const responseData = result.data
    commit('storeMessage', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async create ({ commit }, data) {
    const result = await api.message.create(data)
    const responseData = result.data
    commit('storeMessage', responseData)
    return responseData
  },
  async update ({ commit }, { id, data }) {
    commit('startLoading', 'current')
    const result = await api.message.update(id, data)
    const responseData = result.data
    commit('storeMessage', responseData)
    commit('endLoading', 'current')
    return responseData
  },
  async delete ({ commit }, id) {
    const result = await api.message.delete(id)
    const responseData = result.data
    commit('clearCurrent')
    return responseData
  },
  clearCurrent ({ commit }) {
    commit('clearCurrent')
  }
}
const mutations = {
  storeMessage (state, message) {
    state.current = message
  },
  storeMessages (state, data) {
    state.all = data
  },
  clearCurrent (state) {
    state.current = {}
  },
  startLoading (state, key) {
    state.loading[key] = true
  },
  endLoading (state, key) {
    state.loading[key] = false
  },
  storeUnread(state, messages) {
    state.unread = messages
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
