<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <div class="form-group">
        <label for="name">Name</label>
        <input class="form-control" type="text" id="name" v-model="location.name" />
      </div>
      <div class="form-group">
        <label for="name">Address</label>
        <input class="form-control" type="text" id="name" v-model="location.address" />
      </div>
      <div class="form-group">
        <label for="name">Code</label>
        <input class="form-control" type="text" id="name" v-model="location.code" />
      </div>
      <a href="#" class="btn btn-primary" @click="save">Save Changes</a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "location-edit",
  computed: {
    ...mapState({
      location: state => state.location.current,
      loading: state => state.location.loading.current
    })
  },
  methods: {
    save() {
      const data = this.location;
      if (this.location.id !== undefined) {
        const id = this.location.id;
        console.debug("updating ", this.location);
        this.$store.dispatch("location/update", { id, data });
      } else {
        console.debug("updating ", this.location);
        this.$store.dispatch("location/create", this.location);
      }
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("location/get", this.$route.params.id);
    } else {
      this.$store.dispatch("location/clearCurrent", this.$route.params.id);
    }
  }
};
</script>

<style lang='scss' scoped>
</style>