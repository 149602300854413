<template>
  <div>
    <div>
      <h3>Details</h3>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" v-model="group.name" />
      </div>
      <div v-if="!isNewRecord">
        <h3>Members</h3>
        <div class="users" v-if="!loading && !loadingUsers">
          <user-toggle v-for="user in allUsers" :key="user.id" :user="user" :group="group" />
        </div>

        <h3>Permissions</h3>

        <table v-if="!loading && !loadingPermissions">
          <tr>
            <th>Resource</th>
            <th>Create</th>
            <th>Read</th>
            <th>Update</th>
            <th>Delete</th>
            <th>Send to Patient</th>
            <th>Update Status</th>
          </tr>
          <tr v-for="resource in resources" :key="resource.id">
            <td>{{resource.name | titleize}}</td>
            <td>
              <permission :group="group" :resource="resource" action="create" />
            </td>
            <td>
              <permission :group="group" :resource="resource" action="read" />
            </td>
            <td>
              <permission :group="group" :resource="resource" action="update" />
            </td>
            <td>
              <permission :group="group" :resource="resource" action="delete" />
            </td>
            <td>
              <permission v-if="resource.name == 'consent' || resource.name == 'information_pack'" :group="group" :resource="resource" action="send_to_patient" />
            </td>
            <td>
              <permission v-if="resource.name == 'information_pack'" :group="group" :resource="resource" action="update_status" />
            </td>
          </tr>
        </table>
      </div>

      <a href="#" class="btn btn-primary" @click="save">Save Changes</a>
      <div>
        <authorized-link :to="{name: 'groups'}">Back</authorized-link>
      </div>
    </div>
  </div>  
</template>

<script>
import { mapState } from "vuex";
import permission from "@/components/permission";
import userToggle from "@/components/user-toggle";
export default {
  name: "group-edit",
  components: {
    permission,
    userToggle
  },
  computed: {
    ...mapState({
      group: state => state.group.current,
      loading: state => state.group.loading.current,
      allUsers: state => state.user.all,
      loadingUsers: state => state.user.loading.all,
      groupUsers: state => state.group.current.members,
      resources: state => state.resource.all,
      permissions(state) {
        return state.permission.all.filter(
          function(p) {
            return p.group_id == this.group.id;
          }.bind(this)
        );
      },
      loadingPermissions: state => state.permission.loading.all
    }),
    isNewRecord() {
      return this.group.id === undefined;
    }
  },
  methods: {
    save() {
      const data = { name: this.group.name };
      if (this.group.id !== undefined) {
        const id = this.group.id;
        this.$store.dispatch("group/update", { id, data }).then(
          function() {
            this.$router.push({ name: "groups" });
          }.bind(this)
        );
      } else {
        this.$store.dispatch("group/create", data).then(
          function() {
            this.$router.push({ name: "groups" });
          }.bind(this)
        );
      }
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.$store.dispatch("group/get", this.$route.params.id);
    } else {
      this.$store.dispatch("group/clearCurrent", this.$route.params.id);
    }

    this.$store.dispatch("user/getAll");
    this.$store.dispatch("resource/getAll");
  }
};
</script>

<style lang='scss' scoped>
.users {
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .user {
    svg {
      margin-right: 5px;
    }
  }
}

table {
  text-align: center;

  tr > th {
    background-color: #ccc;
    &:first-child {
      width: 150px;
    }
  }
}
</style>