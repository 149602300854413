<template>
  <div>
    <spinner v-if="loading" />
    <div v-else class="row flex">
      <div class="col-md-8">
        <card title='Information Pack'>
          <div class="card-body">
            <table class="table mb-4">
              <tr>
                <th>Read And Understood</th>
                <td>
                  <boolean-response :value="informationpack.read_and_understood" />
                </td>
              </tr>
              <tr>
                <th>Request Appointment</th>
                <td>
                  <boolean-response :value="informationpack.request_appointment" />
                </td>
              </tr>
              <tr>
                <th>Appointment Request Status</th>
                <td>
                  <span v-if="informationpack.request_appointment" class="badge badge-warning">{{informationpack.appointment_request_status}}</span>
                </td>
              </tr>
              <tr>
                <th>Last Sent to Patient</th>
                <td>
                  {{informationpack.last_sent_to_patient_at | formatDateTime}}
                </td>
              </tr>
              <tr>
                <th>Rating</th>
                <td>
                  <vue-star-rating :max-rating="10" :increment="1" :show-rating="false" :glow="1" v-model="informationpack.rating" :read-only="true" />
                </td>
              </tr>
            </table>
                        <div class="row flex">
                <information-leaflet :id="id" v-for="id in informationpack.information_leaflet_ids" :key="id"/>
                <note v-if="notePresent" hide-contents :contents="informationpack.note" />
            </div>

          </div>
          <div class="card-footer">
            <authorized-link class="btn btn-primary" :to="{name: 'send-information-pack-to-patient', params: {id: informationpack.id}}">Send to Patient</authorized-link>
            <authorized-link class="btn btn-secondary" :to="{name: 'edit-informationpack', params: {id: informationpack.id}}">Edit</authorized-link>
            <a class="btn btn-danger" v-can:delete="information_pack" href="#" @click="deleteRecord">Delete</a>
          </div>
        </card>
      </div>
      <div class="col-md-4">
        <audit-summary :information-pack="informationpack" />
        <patient :patient="informationpack.patient" />
        <user :user="informationpack.user" />
        <audit-log :max="10" :source="informationpack" v-if="informationpack.audit_entries" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import patient from "@/components/patient";
import user from "@/components/user";
import booleanResponse from "@/components/boolean-response";
import informationLeaflet from '@/components/information-leaflet/detail'
import note from '@/components/information-pack/note'
import auditLog from '@/components/audit-log'
import auditSummary from '@/components/information-pack/audit-summary'
export default {
  name: "informationpack-show",
  components: {
    patient,
    user,
    booleanResponse,
    informationLeaflet,
    note,
    auditLog,
    auditSummary
  },
  computed: {
    ...mapState({
      informationpack: (state) => state.informationpack.current,
      loading: (state) => state.informationpack.loading.current,
    }),
    notePresent() {
      return this.informationpack.note !== undefined && this.informationpack.note !== null && this.informationpack.note !== ""
    }
  },
  created() {
    this.$store.dispatch("informationpack/get", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store
        .dispatch("informationpack/delete", this.informationpack.id)
        .then(
          function (response) {
            this.$router.push({ name: "informationpacks" });
          }.bind(this)
        );
    },
  },
};
</script>

<style lang='scss' scoped>
</style>