import HTTP from '@/api/http'

export default {
  async getAll (filter, order, search) {
    const params = {}
    if (filter) {
      params.filter = filter
    }
    if (order) {
      params.order_field = order.field
      params.order_direction = order.direction
    }
    if (search) {
      params.search = search
    }
    const response = await HTTP.get(`/consents`, {
      params: params
    })
    return response
  },
  async get (id) {
    const response = await HTTP.get(`/consents/${id}`)
    return response
  },
  async getAuditSummary (id) {
    const response = await HTTP.get(`/consents/${id}/audit_summary`)
    return response
  },
  async create (data) {
    const response = await HTTP.post(`/consents`, {
      consent: data
    })
    return response
  },
  async update (id, data) {
    const response = await HTTP.put(`/consents/${id}`, {
      consent: data
    })
    return response
  },
  async delete (id) {
    const response = await HTTP.delete(`/consents/${id}`)
    return response
  },
  async sendToPatient (id) {
    const response = await HTTP.post(`/consents/${id}/send-to-patient`)
  },
  async getOverdue () {
    const response = await HTTP.get(`/overdue_consents`)
    return response
  },
  async getUpcoming () {
    const response = await HTTP.get(`/upcoming_consents`)
    return response
  },
  async getMetrics () {
    const response = await HTTP.get(`/metrics/consent`)
    return response
  },
  async getMessages (id) {
    const response = await HTTP.get(`/consents/${id}/messages`)
    return response
  }
}
