<template>
  <a @click="handleClick" class="btn">
    <spinner fontSize="initial" height="initial" v-if="active"/>
    <slot />
  </a>
</template>
<script>
export default {
  name: "single-click-button",
  props: {
    actionInProgress: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false
    }
  },
  watch: {
    actionInProgress() {
      this.active = this.actionInProgress
    }
  },
  methods: {
    handleClick() {
      if (!this.active) {
        this.active = true
        this.$emit("click")
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.spinner-container {
  display: inline !important;
  margin-right: 10px;
  .spinner {
    height: 13px !important;

    i {
      font-size: 13px !important;
    }
  }
}

</style>