<template>
  <div class="content d-flex justify-content-center align-items-center">
    <!-- Login form -->
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <img class="logo" src="@/assets/images/logo.png" />
          <h5 class="mb-0">Login to your account</h5>
          <span class="d-block text-muted">Enter your credentials below</span>
        </div>

        <div class="form-group form-group-feedback form-group-feedback-left">
          <input class="form-control email-address" v-model="email" placeholder="Email Address" v-focus v-on:keypress="clearError" v-on:keyup.enter="focusPassword" type="email" data-kwimpalastatus="alive" data-kwimpalaid="1582284876512-3" />
          <div class="form-control-feedback">
            <i class="icon-user text-muted"></i>
          </div>
        </div>

        <div class="form-group form-group-feedback form-group-feedback-left">
          <input type="password" class="form-control password" placeholder="Password" data-kwimpalastatus="alive" data-kwimpalaid="1582284876512-2" v-model="password" v-on:keypress="clearError" ref="password" v-on:keyup.enter="submit" />
          <div class="form-control-feedback">
            <i class="icon-lock2 text-muted"></i>
          </div>
        </div>

        <div class="form-group">
          <button @click="submit" class="btn btn-primary btn-block sign-in-submit">
            Sign in
            <i class="icon-circle-right2 ml-2"></i>
          </button>
        </div>
        <div class="error-container" v-if="error!==''">
          There has been a problem siging in:
          <div class="error">{{error}}</div>
        </div>

        <div class="text-center">
          <router-link :to="{name: 'forgotten-password'}">Forgot password?</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "sign-in",
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapState({
      error: state => state.session.error,
      valid: state => state.session.valid
    })
  },
  methods: {
    submit() {
      const email = this.email;
      const password = this.password;
      this.$store.dispatch("session/signin", { email, password }).then(
        function() {
          if (this.valid) {
            this.$store.dispatch("message/getUnread");
            this.$router.push({ name: "dashboard" });
          }
        }.bind(this)
      );
    },
    clearError() {
      if (this.error != "") {
        this.$store.dispatch("session/clearError");
      }
    },
    focusPassword() {
      this.$refs.password.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 300px;
}

img.logo {
  margin: 30px 0px;
  max-width: 80%;
}

button {
  margin: 0;
}

.error-container {
  text-align: center;
  margin: 10px 0;
  .error {
    color: red;
    text-transform: uppercase;
  }
}
</style>