<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <h3>{{ informationleaflet.title }}</h3>
      <div class="flex row">
        <card class="col-md-8">
          <html-content :html="informationleaflet.body"></html-content>
        </card>
      </div>
      <div>
        <authorized-link
          class="btn btn-primary"
          :to="{
            name: 'edit-informationleaflet',
            params: { id: informationleaflet.id },
          }"
          >Edit</authorized-link
        >
        <a
          href="#"
          v-can:create="information_leaflet"
          class="btn btn-secondary"
          @click="duplicate"
          >Duplicate</a
        >
        <a
          class="btn btn-secondary"
          @click="downloadPdf"
          >Download</a
        >
        <a
          href="#"
          v-can:delete="information_leaflet"
          class="btn btn-danger"
          @click="deleteRecord"
          >Delete</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api/http"
import { mapState } from "vuex";
export default {
  name: "informationleaflet-show",
  computed: {
    ...mapState({
      informationleaflet: (state) => state.informationleaflet.current,
      currentUser: (state) => state.session.user,
      currentAccount: (state) => state.session.account,
      loading: (state) => state.informationleaflet.loading.current,
    }),
  },
  created() {
    this.$store.dispatch("informationleaflet/get", this.$route.params.id);
  },
  methods: {
    async downloadPdf() {
      const response = await HTTP.get(
        `/accounts/${this.currentAccount.id}/information_leaflets/${this.$route.params.id}.pdf`,
        { responseType: "blob" }
      );

      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${this.informationleaflet.title}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    deleteRecord() {
      this.$store
        .dispatch("informationleaflet/delete", this.informationleaflet.id)
        .then(
          function (response) {
            this.$router.push({ name: "informationleaflets" });
          }.bind(this)
        );
    },
    duplicate() {
      this.$store
        .dispatch("informationleaflet/duplicate", this.informationleaflet.id)
        .then(
          function (duplicate) {
            this.$notify({
              group: "success",
              type: "success",
              title: "Information Leaflet Duplicated",
              text: `${duplicate.title} has been created`,
            });
            this.$store.dispatch("informationleaflet/get", duplicate.id);
            this.$router.next({
              name: "informationleaflet",
              params: { id: duplicate.id },
            });
          }.bind(this)
        );
    },
  },
};
</script>

<style lang='scss' scoped>
</style>