<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <h1 class="card-title">Confirmations</h1>
    </div>

    <div class="card-body">
      <table class="table">
        <confirmation
          v-for="confirmation in consent.confirmation"
          :key="confirmation.name"
          :confirmation="confirmation"
        />
      </table>
    </div>
  </div>
</template>

<script>
import confirmation from "@/components/consent/confirmation";
export default {
  name: "confirmations",
  components: {
    confirmation,
  },
  props: {
    consent: {
      type: Object,
      required: true,
    },
  },
};
</script>