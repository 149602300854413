import HTTP from '@/api/http'

export default {
  async getAll() {
    const response = await HTTP.get(`/locations`)
    return response
  },
  async get(id) {
    const response = await HTTP.get(`/locations/${id}`)
    return response
  },
  async create(data) {
    const response = await HTTP.post(`/locations`, {location: data})
    return response
  },
  async update(id, data) {
    const response = await HTTP.put(`/locations/${id}`, {location: data})
    return response
  },
  async delete(id) {
    const response = await HTTP.delete(`/locations/${id}`)
    return response
  }
}