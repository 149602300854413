<template>
  <div class="selectable" :class="{selected: localSelected}">
    <!-- <div class="check">
      <i class="icon-check2" />
    </div>-->
    <div class="text">
      <html-content v-if="!isEditing" :html="localText" @click="toggleSelection" />
      <html-editor v-else v-model="localText" :images="true" :media="true" :imageUploadUrl="uploadUrl" />
    </div>
    <div class="editToggle" @click="toggleEdit">
      <i class="icon-cog" />
    </div>
  </div>
</template>

<script>
export default {
  name: "paragraph-editor",
  props: {
    text: {
      type: String,
      default: ""
    },
    selected: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      localText: "",
      localSelected: "",
      isEditing: false
    };
  },
  computed: {
    uploadUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/assets`;
    }
  },
  methods: {
    toggleSelection(index) {
      this.localSelected = !this.localSelected;
      this.$emit("updated", {
        text: this.localText,
        selected: this.localSelected,
        index: this.index
      });
    },
    toggleEdit(index) {
      this.isEditing = !this.isEditing;
      this.$emit("updated", {
        text: this.localText,
        selected: this.localSelected,
        index: this.index
      });
    }
  },
  created() {
    this.localText = this.text;
    this.localSelected = this.selected;
  }
};
</script>

<style lang="scss" scoped>
.text {
  margin-top: 5px;
  width: 100%;
  width: 100%;
  padding-right: 20px;
  margin: 0 20px;
}

.check {
  i {
    display: none;
  }
}

.editToggle {
  i {
    display: none;
  }
}

.selected {
  .text {
    color: #000;
  }

  .check {
    i {
      display: block;
      font-size: 30px;
      color: green;
    }
  }

  .editToggle {
    i {
      display: block;
      color: #000;
    }
  }
}
</style>