<template>
  <div>
    <spinner v-if="loading" />
    <location v-else :location="location" />
  </div>
</template>

<script>
import location from '@/components/location'
import { mapState } from "vuex";
export default {
  name: "location-show",
  components: { location },
  computed: {
    ...mapState({
      location: state => state.location.current,
      loading: state => state.location.loading.current
    })
  },
  created() {
    this.$store.dispatch("location/get", this.$route.params.id);
  },
};
</script>

<style lang='scss' scoped>
</style>