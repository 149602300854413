<template>
  <tr :class="{'unread': !isRead}">
    <td class="table-inbox-name" v-if="showProcedure">
      {{message.consent.procedure.title}}
    </td>

    <td class="table-inbox-name" v-if="showSender">
      <router-link :to="{name: 'message', params:{id: message.id}}">
        <div class="letter-icon-title text-default">{{message.sender}}</div>
      </router-link>
    </td>
    <td class="table-inbox-message">
      <router-link :to="{name: 'message', params:{id: message.id}}">
      <!-- <div class="table-inbox-subject">{{message.subject}} &nbsp;-&nbsp;</div> -->
      <span class="text-muted font-weight-normal">{{message.body}}</span>
      </router-link>
    </td>
    <td class="table-inbox-attachment">
      <i v-if="hasAttachment" class="icon-attachment text-muted"></i>
    </td>
    <td class="table-inbox-time">{{message.created_at | shortDateTime}}</td>
  </tr>
</template>

<script>
export default {
  name: "inbox-message",
  props: {
    message: {
      type: Object,
      required: true
    },
    showProcedure: {
      type: Boolean,
      default: true
    },
    showSender: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isRead() {
      return this.message.read_at !== null;
    },
    hasAttachment() {
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-inbox-subject {
  color: black;
}
</style>