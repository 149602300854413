<template>
  <div>
    <spinner v-if="loading" />
    <div v-else>
      <table>
        <tr>
          <th>Name</th>
          <td>{{category.name}}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{{category.description}}</td>
        </tr>
        <tr>
          <th>Code</th>
          <td>{{category.code}}</td>
        </tr>
      </table>
      <div>
        <authorized-link :to="{name: 'edit-category', params: {id: category.id}}">Edit</authorized-link>
        <a href="#" @click="deleteRecord">Delete</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "category-show",
  computed: {
    ...mapState({
      category: state => state.category.current,
      loading: state => state.category.loading.current
    })
  },
  created() {
    this.$store.dispatch("category/get", this.$route.params.id);
  },
  methods: {
    deleteRecord() {
      this.$store.dispatch("category/delete", this.category.id).then(
        function(response) {
          this.$router.push({ name: "categories" });
        }.bind(this)
      );
    }
  }
};
</script>

<style lang='scss' scoped>
</style>