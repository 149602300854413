<template>
  <div class="card">
    <div class="card-header">
      <h4>
        <i :class="iconClass" />
        {{procedure.title}}
      </h4>
    </div>
    <div class="card-body">
      <p>{{procedure.short_description}}</p>
    </div>
    <div class="card-footer bg-transparent d-flex justify-content-between border-top-0 pt-0" v-if="selectable">
      <div></div>
      <div>
        <a class="btn btn-primary" @click="clicked">Select</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "procedure-card",
  props: {
    procedure: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  computed:{
    ...mapState({
      iconClass: state => state.application.settings.procedure.icon
    })
  },
  methods: {
    clicked() {
      this.$emit("selected")
    }
  }
};
</script>