<template>
    <div class="user" @click="toggle" :class="{selected: isSelected}">
      <font-awesome-icon icon="user" />
      {{user.first_name}} {{user.last_name}}
    </div>
</template>

<script>
export default {
  name: "user-toggle",
  props: {
    user: {
      type: Object
    },
    group: {
      type: Object
    }
  },
  computed: {
    isSelected() {
      return this.group.members.map(function(u) { return u.id}).indexOf(this.user.id) !== -1
    }
  },
  methods: {
    toggle() {
      const id = this.group.id
      const userId = this.user.id

      if (this.isSelected) {
        this.$store.dispatch("group/removeMember", {id, userId})
      } else {
        this.$store.dispatch("group/addMember", {id, userId})
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user {
  display: inline;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin: 10px;

  &.selected {
    background-color: green;
    color: white;
  }
}
</style>