<template>
  <div class="patient-number">
    <div class="field-container">
      <v-select :options="numberTypes" v-model="numberType" :filterable="false" :searchable="false" :clearable="false" />
      <input type="text" id="patient_last_name" v-model="localValue" />
    </div>
    <div class="status" :class="{'is-valid': isValid, 'error': !isValid}">{{validationMessage}}</div>
  </div>
</template>

<script>
export default {
  name: "patient-number",
  props: {
    value: {
      type: String
    },
    type: {
      type: String,
      default: "CHI Number"
    }
  },
  data() {
    return {
      localValue: this.value,
      numberTypes: ["CHI Number", "Other"],
      numberType: this.type,
      validationMessage: "",
      isValid: false
    };
  },
  methods: {
    checkNumber() {
      this.validationMessage = "";
      if (this.numberType == "CHI Number") {
        if ((this.localValue || "").length == 10) {
          const date_digits = this.localValue.slice(0, 6);
          const gender_digit = parseInt(this.localValue.slice(8, 9));
          const check_digit = parseInt(this.localValue.slice(9, 10));

          console.log(
            this.localValue,
            check_digit,
            this.modulus11Valid(this.localValue)
          );

          if (this.modulus11Valid(this.localValue)) {
            this.isValid = true;
            this.validationMessage = "Valid CHI Number";
            const day = date_digits.slice(0, 2);
            const month = date_digits.slice(2, 4);
            const year = date_digits.slice(4, 6);

            const date = this.$moment(`${year}-${month}-${day}`);
            this.$emit("dob", date);
            this.$emit("gender", gender_digit % 2 == 0 ? "Female" : "Male");
          } else {
            this.isValid = false;
            this.validationMessage = "Invalid CHI Number - failed checksum";
          }
        } else {
          this.isValid = false;
        }
      } else {
        this.isValid = true;
      }
    },
    modulus11Valid(number) {
      let total = 0;
      for (let i = number.toString().length - 1; i >= 0; i--) {
        total = total + number.toString().charAt(i) * (10 - i);
      }
      return total % 11.0 == 0;
    }
  },
  watch: {
    value() {
      this.localValue = this.value
    },
    type() {
      this.numberType = this.type
    },
    localValue() {
      this.checkNumber();
      this.$emit("input", this.localValue);
    },
    numberType() {
      this.checkNumber();
    }
  }
};
</script>

<style lang="scss" scoped>
.patient-number {
  .field-container {
    flex-direction: row;
    align-items: center;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: inline-block;
    background: white;

    .v-select,
    input {
      display: inline-block;
      border: 0;
    }
    .v-select {
      /deep/ .vs__dropdown-toggle {
        border: 0;
      }
    }
  }
  .status {
    margin-left: 9px;
    &.is-valid {
      color: #186f1c;
    }
    &.error {
      color: #d20205;
    }
  }
}
</style>